import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../../settings/must-match.validator';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { timer } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { sha256 } from 'js-sha256';

@Component({
  selector: 'app-edit-password-patient',
  templateUrl: './edit-password-patient.component.html',
  styleUrls: ['./edit-password-patient.component.css']
})
export class EditPasswordPatientComponent implements OnInit {

  angForm: FormGroup;
  submitted = false;
  public source = timer(1000, 2000);
  timer: any;
  userId: any;
 loading = false;

  constructor( private c: ConfirmationDialogService,private fb: FormBuilder, private ressourceService: ResourceService, private router: Router, private toastr: ToastrService)
  {}

  ngOnInit() {
    this.createForm();
    this.userId = sessionStorage.getItem('userId');
  }

  createForm() {
    this.angForm = this.fb.group({
      old_password: ['',[Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)] ]

    },
      {
        validator: MustMatch('new_password', 'confirm_password')
      });
  }
  //  Validators.pattern('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Z\\d!$%@#£€*?&]{6,}$')

  get f() {
    console.log(this.angForm.controls);
     return this.angForm.controls;
  }

  onSubmit(){

    this.submitted=true;

    if (this.angForm.invalid) {
      return;
    }else{
       // fonction qui permet de verifier si le mot actuel est correcte
      this.loading = true;
      let datas = {
        password: sha256(this.angForm.value['old_password']),
        id: this.userId
      }

      this.ressourceService.saveResource('patient/checkpassword',datas).subscribe((data:any)=>{
         // fonction qui  modifie le mot de l'utilisateur
          if(data=="ok"){
            let datanew = {
              password: sha256(this.angForm.value['new_password']),
              id: this.userId
            }

            this.ressourceService.updateResource('patient/update', datanew).subscribe(data => {
              this.loading = false;
              this.toastr.success('Votre mot de passse a été modifié!', 'Sucess');
            },
              err => {
                this.loading = false;
                this.toastr.error(Errors(err), 'Major Error');
                console.log('erreur', err);
              });

        } else {
            this.loading = false;
          this.toastr.error('Mot de passe incorrect!', 'Major Error');
        }
      },err=>{
          this.loading = false;
          this.toastr.error(Errors(err), 'Major Error');
          console.log("erreur", err);
      })
    }
  }
}
