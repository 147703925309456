import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-mentions',
  templateUrl: './legal-mentions.component.html',
  styleUrls: ['./legal-mentions.component.css']
})
export class LegalMentionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
