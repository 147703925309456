import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/user';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
import {Shedule} from '../../../models/schedule';
declare var swal: any;

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnInit, OnDestroy, AfterViewInit {

  newUser: User;
  userFormEdit: FormGroup;
  users: any = [];
  roles: any = [];
  // formData = new FormData();
  fileData: File = null;
  baseUrl: string;
  urls: any;
  url: string = '/user';
  public error;
  public success;
  dtTrigger: Subject <any> = new Subject();
  pays: any;
  date: any;

  public detailsData =
    {
      id: null,
      name: '',
      surname: '',
      birthdate: '',
      postalcode: '',
      tel: '',
      adress: '',
      email: '',
      town: '',
      country: '',
      type: '',
      status: '',
      last_connexion: '',
      avatar: ''
    };

  baseurl: string;

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newUser = {} as User; }

  ngOnInit() {
    this.checkSession();
    this.initFormEdit();
    this.getRoles();
    this.baseurl = environment.baseUrl + 'public/';
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + 'public/files/avatars/avatar.png';
    this.pays = paysdata;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  initFormEdit() {
    this.userFormEdit = this.formBuilder.group({
      checkbox: ['', Validators.required],
      id: '',
      name: ['', Validators.required],
      surname: [''],
      birthdate: ['', Validators.required],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: ['', Validators.required],
      type: ['', Validators.required],
    });
  }

  get f1() { return this.userFormEdit.controls; }

  public liste() {

    this.resourceService.getResources('alluser').subscribe(data => {
      this.users = data;
      this.dtTrigger.next();
    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(user) {

    this.detailsData =
      {
        id: user.id,
        name: user.username,
        surname: user.lastname,
        birthdate: user.birthdate,
        tel: user.tel,
        email: user.email,
        adress: user.adress,
        postalcode: user.postalcode,
        town: user.town,
        country: user.country,
        type: user.type,
        status: user.status,
        last_connexion: user.last_connexion,
        avatar: this.baseUrl + 'public' + user.avatar
        // avatar: user.avatar
      };
  }

  public onEdit(user) {
    this.userFormEdit.patchValue({
      checkbox: user.checkbox,
      id: user.id,
      name: user.username,
      surname: user.lastname,
      birthdate: user.birthdate,
      tel: user.tel,
      email: user.email,
      adress: user.adress,
      postalcode: user.postalcode,
      town: user.town,
      country: user.country,
      type: user.type,
	    avatar: this.baseUrl + 'public' + user.avatar
      // avatar: 'public' + user.avatar
      // avatar: this.baseUrl + 'public' + user.avatar
    });
  }


  public updateUser(){
    if (this.userFormEdit.invalid) {
      return;
    }

    this.date = new Date();
    let formData = new FormData();
    const formValue = this.userFormEdit.value;
    formData.append('id', formValue['id']);
    formData.append('username', formValue['name']);
    formData.append('lastname', formValue['surname']);
    formData.append('birthdate', formValue['birthdate']);
    formData.append('email', formValue['email']);
    formData.append('tel', formValue['tel']);
    formData.append('type', formValue['type']);
    formData.append('adress', formValue['adress']);
    formData.append('postalcode', formValue['postalcode']);
    formData.append('town', formValue['town']);
    formData.append('country', formValue['country']);
    formData.append('date_updated', this.date);
    // formData.append('status', '0');
    // formData.append('connected', '0');
    // formData.append('date_created', this.date);
    // formData.append('last_connexion', this.date);

    if(this.fileData){
      formData.append('avatar', this.fileData );
    }

    this.resourceService.updateResource('updateuser', formData).subscribe(data => {

      console.log('data', data);
      this.liste();
      this.resetForm(this.userFormEdit);
      this.toastr.success('Les données de l\'utilisateur ont été mis à jour!', 'Sucess');
      this.router.navigate(['/admin/list/user']);

    }, error => {
      if(error.status == 500 || error.status == 503){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
      if(error.status == 400){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
    });
  }

  public onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData );
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {
    }
  }


  getRoles(){

    this.resourceService.getResources('role/all').subscribe(data=>{
      this.roles = data;
    } ,err=>{
      console.log('erreur',err);
    })
  }


  public resetForm(form){
    form.reset();
  }


  public  deleteUser(event: MouseEvent, user) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('deluser', user.id).subscribe(data => {
          this.users = data;
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/user/deleteall').subscribe(data => {
          this.users = data;
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

  // public showCancelMessage(event: MouseEvent, user) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deleteUser(event,user);
  //       swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "Your data is safe.", "error");
  //     }
  //   });
  // }
  //
  // public showDeleteAllMessage(event: MouseEvent) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to delete all datas? This action is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       //insert the method to delete all datas
  //       //this.deleteUser(event,user);
  //
  //       swal("Deleted!", "The datas has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "The datas are safe.", "error");
  //     }
  //   });
  // }

}
