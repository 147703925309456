import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit,OnDestroy {
  
  baseurl: string;
  userId: any;
  patients: any;
  dtTrigge: Subject<any>  = new Subject();

  constructor(private router: Router, private resourceService: ResourceService, private toastr: ToastrService) { }

  ngOnInit() {
  //  this.checkSession();
    this.baseurl = environment.baseUrlImage;
    this.getconsultations();
  }

  ngOnDestroy(){
    this.dtTrigge.unsubscribe();
  }
 
  getconsultations() {
    this.resourceService.getResource('rdv/alldoc',this.userId).subscribe(data=>{
      //here we use filter to check unique patient from list
      let obj:any=data;
      console.log(obj);
      var flags = {};
      var patients = obj.filter(function(entry) {
          if (flags[entry.patient_id]) {
              return false;
          }
          flags[entry.patient_id] = true;
          return true;
      });
      this.patients=patients;
      console.log(this.patients);
      this.dtTrigge.next();
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  } 

}
