import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MustMatch } from 'src/app/settings/must-match.validator';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import paysdata from 'src/app/pays.json';
import monthdata from 'src/app/month.json';
import dayhdata from 'src/app/day.json';
import yearhdata from 'src/app/year.json';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { sha256 } from 'js-sha256';
import { Title } from '@angular/platform-browser';
import { NgSelectConfig } from '@ng-select/ng-select';
import { parse } from 'querystring';
// import { Ng2ImgMaxService } from 'ng2-img-max';


@Component({
  selector: 'app-sign-up-medecin',
  templateUrl: './sign-up-medecin.component.html',
  styleUrls: ['./sign-up-medecin.component.css']
})
export class SignUpMedecinComponent implements OnInit {
  urls: any ;
  fileData: File = null;
  fileAvatar: File = null;
  nameFile:any;
  nameFileLV:any;
  loading = false;
  step2Form: FormGroup;
  submitted = false;
  step1Form: FormGroup;
  step1 = false;
  specialities:any;
  mailExist=false;
 formData = new FormData();
 pays: any;
 baseUrl: string;
  items: any=[];
  month:any;
  day:any;
  year:any;

  constructor(private config: NgSelectConfig,
              private title:Title, 
              private fb: FormBuilder, 
              private resourceService:ResourceService,
              private router:Router, 
              private toastr: ToastrService,
            ) {
    this.title.setTitle('sign up doctor');
    this.config.notFoundText = 'Country not found';
    this.config.appendTo = 'body';
  }

  ngOnInit() {
    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
    this.month=monthdata;
    this.day=dayhdata;
    this.year=yearhdata;
this.urls = "assets/images/xs/user.png";;
    this.createForm();
    this.getSpecialities();
  }

  createForm() {
    this.step1Form = this.fb.group({
      name: ['', Validators.required],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
     birthdate: ['', Validators.required],
      tel: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', Validators.required],
     
    }, {
      validator: MustMatch('password', 'confirmpassword'),
    });

    this.step2Form = this.fb.group({
      checkbox: ['', Validators.required],
      country: [null, Validators.required],
      town: ['', Validators.required],
      registered: ['', Validators.required],
      num_agreement: ['', Validators.required],
      faculty: ['', Validators.required],
      diploma: ['', Validators.required],
    //  id_speciality: this.fb.array([],Validators.required),
      id_speciality: [null, Validators.required],
      postalcode: [''],
      adress: ['', Validators.required],
      country_faculty: [null, Validators.required],
      town_faculty: ['', Validators.required],
      cv: ['', Validators.required],
      cover_letter: ['', Validators.required],
    });
  }

  checkemail(value){
    this.resourceService.getResources_unsafe('doc/checkemail/'+value).subscribe((data) => {
      if(data=="Done"){
        this.mailExist=true;
        this.toastr.error('Cette adresse e-mail existe deja', 'Error');
      }else{
        this.mailExist=false;
      }

    });
  }

  // Validators.pattern('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Z\\d!$%@#£€*?&]{6,}$')],
  onSubmitStep1() {
    this.step1 = true;

    if (this.step1Form.invalid ) {
      return;
    }


  }

  get f1() {
    // console.log(this.step1Form.controls);
    return this.step1Form.controls;
  }

  get f2() {
    return this.step2Form.controls;
  }

  onSubmitStep2() {

    this.submitted = true;
    if (this.step2Form.invalid || !this.step2Form.value['checkbox'] ) {
      
      return;


    }else{console.log('err valided', this.step2Form.controls);
      this.loading = true;
      let formValue2 = this.step2Form.value;
      console.log(formValue2['id_speciality']);

      let formValue = this.step1Form.value;
      this.formData.append('name', formValue['name']);
      this.formData.append('surname', formValue['surname']);
      this.formData.append('password', sha256(formValue['password']));
      this.formData.append('email', formValue['email']);
      this.formData.append('tel', formValue['tel']);
      this.formData.append('birthdate', formValue['birthdate'] );

      this.formData.append('adress', formValue2['adress']);
      this.formData.append('country', formValue2['country']);
      this.formData.append('town', formValue2['town']);
      this.formData.append('registered', formValue2['registered']);
      this.formData.append('num_agreement', formValue2['num_agreement']);
      this.formData.append('faculty', formValue2['faculty']);
      this.formData.append('diploma', formValue2['diploma']);
      this.formData.append('postalcode', formValue2['postalcode']);
      this.formData.append('country_faculty', formValue2['country_faculty']);
      this.formData.append('town_faculty', formValue2['town_faculty']);
      this.formData.append('specialities',JSON.stringify(formValue2['id_speciality']));
      if( this.fileAvatar){
        let newName = sha256( this.fileAvatar.name)+'.'+ this.fileAvatar.name.split('.').pop()
        this.formData.append('avatar',  this.fileAvatar,newName );

      }
   

      this.resourceService.createaccountResource('doc/create', this.formData).subscribe(data=>{
        let res:any =data;
          this.step1Form.reset();
          this.step2Form.reset();
          this.formData= new FormData();
          this.toastr.success('Votre compte a ete crée!', 'Success');
          this.router.navigate(['/confirm']);

      }, err => {
          this.loading = false;
        this.toastr.error(Errors(err), 'Major Error');
        console.log("erreur",err);
        console.log(Errors(err));
      }, () => {
        this.loading = false;
      });
    }

  }


  onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileAvatar = <File>events.target.files[0];
    
      // this.ng2ImgMax.resizeImage( this.fileData, 400, 300).subscribe(
      //   result => {
      //     this.fileData = new File([result], result.name);
      //   },
      //   error => {
      //     console.log('😢 Oh no!', error);
      //   }
      // );

      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    }
  }


  onSelectCV(events, f: NgForm) {
    if (events.target.files && events.target.files[0]) {

      this.nameFile = events.target.files[0].name;
  
      this.formData.append('cv',<File>events.target.files[0]);

      var reader = new FileReader();
      reader.onload = (event: any) => {
        // this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {

    }
  }

  onSelectLV(events, f: NgForm) {
    if (events.target.files && events.target.files[0]) {

      this.nameFileLV = events.target.files[0].name;
      this.formData.append('cover_letter', <File>events.target.files[0]);
      var reader = new FileReader();

      reader.readAsDataURL(events.target.files[0]);
    }
  }

  getSpecialities(){

    this.resourceService.getResources_unsafe('speciality/listall').subscribe(data=>{
       this.specialities=data;
      console.log(this.specialities);

    } ,err=>{
     console.log('erreur',err);
    })
  }


 

}
