import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

@Component({
  selector: 'app-view-payment-mode',
  templateUrl: './view-payment-mode.component.html',
  styleUrls: ['./view-payment-mode.component.css']
})
export class ViewPaymentModeComponent implements OnInit {
  timer: any;

  constructor(private c: ConfirmationDialogService, private router: Router) { }
  public source = timer(5000, 8000);
  ngOnInit() {
    this.timer = this.source.subscribe(val => {
      if (this.getAppel() == 'appel') {
        this.timer.unsubscribe();
        this.c.confirm('Appel entrant', 'Téléconsutaton').then(confirmed => {

          console.log('appel accepter', confirmed);
          if (confirmed == true) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          } else if (confirmed == false) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          }
        });
      }
    });
  }


  getAppel() {
    return 'appel';
  }
}
