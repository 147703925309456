import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ResourceService} from "../../../services/resource.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.css']
})
export class ConfirmActionComponent implements OnInit {

  constructor(private resourceService: ResourceService, private router:Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.checkSession();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

}
