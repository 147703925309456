import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
// import { SocketService } from 'src/app/services/socket.service';
import { SocketserviceService } from 'src/app/services/socketservice.service';

@Component({
  selector: 'app-sign-in-medecin',
  templateUrl: './sign-in-medecin.component.html',
  styleUrls: ['./sign-in-medecin.component.css']
})
export class SignInMedecinComponent implements OnInit {

  angForm: FormGroup;
  submitted = false;
  baseurl: string;
  loading = false;
  private userdata: any = '';
  
  constructor(private title: Title, 
    private fb: FormBuilder, 
    private resourceService: ResourceService, 
    private router: Router, 
    private toastr: ToastrService,
	private socketservice: SocketserviceService
    /* private socketService:SocketService */
   ) { this.title.setTitle('login'); }

  ngOnInit() {
    this.createForm();
    this.baseurl = environment.baseUrl;
  }

  createForm() {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.angForm.controls;
  }
  
  onSubmit() {
    this.submitted = true;

    if (this.angForm.invalid) {
      return;
    }
	
    let datas = {
      password: sha256(this.angForm.value['password']),
      username: this.angForm.value['email']
    }
	
    // Trying to connect doctor
    this.loading = true;
    
    // check status compte doctor
    this.resourceService.getResources_unsafe('doc/checkstatuts/' + datas.username).subscribe((data) => {
        let response: any = data;
		if (response == 'Done') {
            this.resourceService.getsaveResource('doctor/connect', datas).subscribe((data: any) => {
			  let user: any = [];
              user = data;
			  this.loading = false;
			  if (user == 'not connected'){
				this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
			  }else if(!user){
				this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
			  }else{
                // Setting token access and session variables doctor
                localStorage.setItem('token', user.access_token);
                sessionStorage.setItem('_epocrate_type_', "_epocrate_type_");
                user = user.user;
                this.resourceService.sendData(user);
				this.socketservice.sendDocId(user.id).subscribe((userdata: any) => {
					this.userdata = userdata;
					sessionStorage.setItem('usdocws', JSON.stringify(this.userdata));
					sessionStorage.setItem('isConnect', 'true');
					sessionStorage.setItem('userId', user.id);
					sessionStorage.setItem('username', user.surname + ' ' + user.name);
					sessionStorage.setItem('avatar', user.avatar);
					sessionStorage.setItem('email', user.email);
					sessionStorage.setItem('phone', user.tel);
					sessionStorage.setItem('adress', user.adress);
					sessionStorage.setItem('town', user.town);
					sessionStorage.setItem('country', user.country);
					this.toastr.success('Vous êtes Connecté !', 'Success doctor');
					this.router.navigate(['/doctor/dashboard']);
					// let users={
					//   id:user.id,
					//   type:"doctor"
					// }
					// console.log(users);
					// this.socketService.connectSocket(users);
				});
				
              }

            }, err => {
              this.loading = false;
              this.toastr.error(Errors(err), "Connection Fail! : Impossible d'etablir une connexion. Veuillez réessayer après quelques secondes ou contacter le service support.");
			  console.log('err', err);
            });
          } else {
            this.loading = false;
            this.toastr.info('Votre compte n\'est pas encore activé!', 'Info Message');
          }

    },err => {
        this.loading = false;
        this.toastr.error(Errors(err), "Connection Fail! : Echec d'obtention des données. Veuillez réessayer après quelques secondes ou contacter le service support.");
        console.log('err', err); 
    });
 
  }

}