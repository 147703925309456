import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnHide: boolean;
  constructor(private router: Router,private activeModal: NgbActiveModal, private config: NgbModalConfig, private toastr: ToastrService,private r:ResourceService) { 

  }

  ngOnInit() {

    this.config.backdrop = 'static';
    this.config.keyboard = false;

  }

  public decline() {
  
    this.toastr.error('Requete Rejetee', 'Message Err');
    

    this.activeModal.close(false);
  }

  public accept() {

    this.activeModal.close(true);
  
  }

  public dismiss() {

    this.activeModal.dismiss();
  }

}
