export const Errors = (error: any) => {
  

    if (error.status == 500 || error.status == 503) {
        return  'erreur de chargement \n une erreur est survenue au niveau du serveur';
        
    }

    if (error.status == 400) {
        return  'impossible d\'enregistrer ce contenu, \n';
        
    }


    if (error.status == 404) {
        return  ' erreur  \n';
       
    }

    if (error.status == 0) {
        return ' erreur de connexion  \n';
    }

   
}