import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Subject } from 'rxjs';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Invoice } from 'src/app/models/invoice';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-patient',
  templateUrl: './invoice-patient.component.html',
  styleUrls: ['./invoice-patient.component.css']
})
export class InvoicePatientComponent implements OnInit,OnDestroy {
  dtTrigge: Subject<any> = new Subject();
  invoice:any;
  userId:any;
  payments:any;

  constructor(private resourceService:ResourceService,
              private invoiceService:InvoiceService,
              public datepipe:DatePipe,
              private translate:TranslateService) {
		this.invoice = {} as Invoice;
   }


  ngOnInit() {
	this.userId = sessionStorage.getItem('userId');
    this.getPayment(this.userId);
  }

  ngOnDestroy(){
    this.dtTrigge.unsubscribe();
  }

  getPayment(id){
    this.resourceService.getResources('invoice/all/'+id).subscribe((data:any)=>{
      console.log('patient',data);
      this.payments=data;
      this.dtTrigge.next(); 
    },err=>{
        console.log('err',err);
    })
  }

    /* downloadBill(inv){
      //this.invoice.amount=20000;
      this.invoice.amount=inv.total;
      this.invoice.date_consultation= this.datepipe.transform(inv.rdv.date_hours,'dd/MM/yyyy h:mm') ;
      this.invoice.date_created= this.datepipe.transform(inv.rdv.date_created,'dd/MM/yyyy') ;
      this.invoice.doctor= "Dr "+inv.rdv.doctor.name;
      this.invoice.email=inv.rdv.patient.email;
      this.invoice.id=inv.id;
      this.invoice.motif=inv.rdv.description?inv.rdv.description:this.translate.instant('not_motif');
      this.invoice.name=inv.rdv.patient.lastname;
      this.invoice.surname=inv.rdv.patient.username;
      this.invoice.tel=inv.rdv.patient.phone;
      this.invoice.type_consultation=inv.rdv.type;
      this.invoice.type_payement="Stripe";
      this.invoice.adress=inv.rdv.patient.adresse
      this.invoiceService.downloadBill(this.invoice);
  } */
  
  
  public downloadBill(inv) {
      this.invoice.amount=inv.total;
      this.invoice.date_consultation= this.datepipe.transform(inv.rdv.date_hours,'dd/MM/yyyy h:mm') ;
      this.invoice.date_created= this.datepipe.transform(inv.date_created,'dd/MM/yyyy') ;
      this.invoice.doctor= 'Dr '+ inv.rdv.disponibility.doctor.name + ' ' + inv.rdv.disponibility.doctor.surname;
      this.invoice.email=inv.patient.email;
      this.invoice.id=inv.id + '/' + inv.transaction_number;
      this.invoice.motif=inv.description?inv.description:this.translate.instant('not_motif');
      this.invoice.name=inv.patient.lastname;
      this.invoice.surname=inv.patient.username;
      this.invoice.tel=inv.patient.phone;
      this.invoice.type_consultation=inv.rdv.type;
      this.invoice.type_payement='Stripe';
      this.invoice.adress=inv.patient.adresse
      this.invoiceService.downloadBill(this.invoice);
  }

}
