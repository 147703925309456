import { Component, OnInit, ElementRef, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { Router } from '@angular/router';
import paysdata from 'src/app/pays.json';
import { timer } from 'rxjs';
declare  var $:any;
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-search-doctor',
  templateUrl: './search-doctor.component.html',
  styleUrls: ['./search-doctor.component.css']
})
export class SearchDoctorComponent implements OnInit, OnDestroy {
  
  specialities: any;
  pays: any;
  doctors: any;
  baseurl:string;
  p: number = 1;
  total: number;
  source = timer(1000, 2000);
  timer: any;
  doctor: any;
  speciality: any;
  userId: any;
 

  constructor(private title:Title, private resourceService:ResourceService, private c: ConfirmationDialogService, private toastr: ToastrService, private router:Router) 
  {
    this.title.setTitle('rechercher un docteur');
   }
  ngOnInit() {
    this.pays = paysdata; 

  //  this.checkSession();
    this.baseurl = environment.baseUrl+'public/';
    this.getTimer();
    this.userId = sessionStorage.getItem('userId');
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  } 



  getTimer(){

    this.timer = this.source.subscribe(val => {
      if (this.getAppel() != 'None') {
        this.timer.unsubscribe();
        this.c.confirm('Appel entrant', 'Téléconsutaton').then(confirmed => {

          console.log('appel accepter', confirmed);
          if (confirmed == true) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          } else if (confirmed == false) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          }
        });
      }
    });
  }

  getAppel() { 
    let tele:any
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    
    if(tele && tele.id){
      sessionStorage.setItem('roomname',tele.roomname);
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    }else
      return 'None';
  }

 
 

 





}
