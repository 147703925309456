import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import paysdata from 'src/app/pays.json';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css']
})

export class AddPatientComponent implements OnInit {

  patientForm: FormGroup;
  submitted = false;
  urls: any = "assets/images/xs/user.png";
  fileData: File = null;
  pays: any;
  baseUrl: string;
  date: any;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.checkSession();
    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + 'public/files/avatars/avatar.png';
    this.createForm();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"]);
    }
  }

  createForm() {

    this.patientForm = this.fb.group({
      checkbox: ['', Validators.required],
      username: ['', Validators.required],
      lastname: [''],
      date_of_birth: [''],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      adresse: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: [null, Validators.required]
    });
  }

  checkemail(value){
    this.resourceService.getResources_unsafe('patient/checkemail/'+value).subscribe((data) => {
      if(data == "Done")
        this.toastr.error('Cette adresse e-mail existe deja', 'Error');
    });
  }

  get f1() {
    return this.patientForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.patientForm.invalid  || !this.patientForm.value['checkbox']) {
      return;
    }else{
      this.date = new Date();
      let formData = new FormData();
      let formValue = this.patientForm.value;
      formData.append('username', formValue['username']);
      formData.append('lastname', formValue['lastname']);
      formData.append('date_of_birth', formValue['date_of_birth']);
      formData.append('email', formValue['email']);
      formData.append('phone', formValue['phone']);
      formData.append('adresse', formValue['adresse']);
      formData.append('postalcode', formValue['postalcode']);
      formData.append('town', formValue['town']);
      formData.append('country', formValue['country']);
      formData.append('status', '0');
      formData.append('connected', '0');
      formData.append('date_created', this.date);
      // formData.append('date_updated', this.date);
      // formData.append('last_connexion', this.date);

      if(this.fileData){
        formData.append('avatar', this.fileData );
      }

      // Save datas create user in database
      this.resourceService.saveResource('pat/create', formData).subscribe((data) => {

        let res:any = data;
        if (res == "Erreur") {
          this.toastr.error('Votre compte ne peut etre crée! E-mail deja existant!', 'Error');
        }else{
          this.patientForm.reset();
          this.toastr.success('Votre compte a été crée!', 'Sucess');
          this.router.navigate(['/admin/confirm/action']);
        }
      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
        console.log(Errors(err));
      });
    }
  }


  onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {

    }
  }

}
