import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {ConfirmationDialogService} from '../../../ui/notifications/notification.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {timer, Subject} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-teleconsultation-patient',
  templateUrl: './teleconsultation-patient.component.html',
  styleUrls: ['./teleconsultation-patient.component.css']
})
export class TeleconsultationPatientComponent implements OnInit, AfterViewInit, OnDestroy {
 
  public source = timer(1000, 2000);
  subscribe:any;
  timer: any;
  userId: any;
  doctor: string;
  speciality: any;
  teleconsultations: any;
  baseurl: any;
  currentRate:any;
  tcTrigger: Subject<any> = new Subject();
  doctors: any = [];
  telec: any = '';
  
  constructor(private config1: NgbRatingConfig,private router: Router,private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService,private resourceService:ResourceService) {

    this.config.backdrop = 'static';
    this.config.keyboard = false;
     config1.max = 5;
     config1.readonly = true;
  }

  ngOnInit() {
   // this.checkSession();
   // this.getTimer();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
  }

  ngAfterViewInit(){
    this.getTeleconsultations();
  }

  ngOnDestroy() {
  //  this.timer.unsubscribe();
    this.tcTrigger.unsubscribe();
  }
  
  viewteleconsultation(item) {
	let curdate:any = new Date();
	let posdate = new Date(item.date_hours);
	var duration = curdate.valueOf() - posdate.valueOf();
		
	this.resourceService.getResources('patient/findTelec/' + this.userId + '/' + item.disponibility.doctor.id).subscribe(data => {
		this.telec = data;
		
		if (this.telec != null)
		{
			if(duration<1)
			{
				// set myIceServers in localStorage 
				localStorage.setItem('ics', this.telec.iceserver);
				
				this.doctors = {
					id: item.disponibility.doctor.id,
					name: item.disponibility.doctor.surname + ' ' + item.disponibility.doctor.name,
					avatar: item.disponibility.doctor.avatar
				}
				sessionStorage.setItem('doctor', JSON.stringify(this.doctors));
				
				if (item.disponibility.doctor.connected == '1')
				{	
					this.toastr.success("Votre Medecin Dr. " + item.disponibility.doctor.surname + " " + item.disponibility.doctor.name + " est déjà disponible. Veuillez attendre son appel.", "Disponible");
					this.router.navigate(["/patient/view-teleconsultation-patient"]);
				}
				else {
					this.toastr.warning("Votre Medecin Dr. " + item.disponibility.doctor.surname + " " + item.disponibility.doctor.name + " n'est pas connecté à l'instant. Veuillez patienter.", "Indisponible");
				} 
			}
			else {
				this.toastr.warning("L'échéance de votre téléconsultation est depassée. Veuillez contacter le service support.", 'Expiration');
			} 
		} 
		else {
			this.toastr.warning("Votre Medecin Dr. " + item.disponibility.doctor.surname + " " + item.disponibility.doctor.name + " n'est pas connecté à l'instant. Veuillez patienter.", "Indisponible");	
		}
	}, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });  
  }

  /* getTimer(){
    this.timer = this.source.subscribe(val => {
      if (this.getAppel() != 'None') {
        this.timer.unsubscribe();
        this.c.confirm('Appel entrant', 'Téléconsutaton').then(confirmed => {

          console.log('appel accepter', confirmed);
          if (confirmed == true) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          } else if (confirmed == false) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          }
        });
      }
    });
  } */

  getAppel() { 
    let tele:any
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    if(tele && tele.id){
      sessionStorage.setItem('iceserver',tele.iceserver);
      //sessionStorage.setItem('iceserver', 'Teleconsultation');
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    }else
      return 'None';
    
  }

  getTeleconsultations() { 
    this.resourceService.getResource('rdv/all/tele',this.userId).subscribe(data=>{
      this.teleconsultations = data;
      this.tcTrigger.next();
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  }

  deleteitem(item) {
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer cette ligne... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('rdv/delete',item.id).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }
}
