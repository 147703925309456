import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { timer } from 'rxjs';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-account-doctor',
  templateUrl: './my-account-doctor.component.html',
  styleUrls: ['./my-account-doctor.component.css']
})
export class MyAccountDoctorComponent implements OnInit {

  userId: any;
  speciality: any;
  userDisplayName: string;
  avatar: string;
  baseurl: any;
  doctor: any;
  rdvs: any;

 constructor(private router: Router, private resourceService: ResourceService, private toastr: ToastrService, private c: ConfirmationDialogService) { }


  ngOnInit() {
   
    this.getDoctor();
    this.getlistrdv();
    this.userId = sessionStorage.getItem('userId');
    this.userDisplayName = sessionStorage.getItem('username');
    this.avatar = sessionStorage.getItem('avatar');
    this.baseurl = environment.baseUrl+'public/';
  }

  getDoctor(){
    this.resourceService.getResource('doc/findhimself', +this.userId).subscribe(data=>{
      this.doctor=data;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  } 
  
  getlistrdv(){
    this.resourceService.getResource('rdv/alltogo', +this.userId).subscribe(data=>{
      this.rdvs=data;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  }

}
