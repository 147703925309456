import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-launcher-medecin',
  templateUrl: './chat-launcher-medecin.component.html',
  styleUrls: ['./chat-launcher-medecin.component.css']
})
export class ChatLauncherMedecinComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
