import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from '../app.component';
import { LayoutPatientComponent } from '../layout/patient/layout-patient/layout-patient.component';
import { LayoutMedecinComponent } from '../layout/medecin/layout-medecin/layout-medecin.component';
import { LayoutAdminComponent } from '../layout/admin/layout-admin/layout-admin.component';
import { LayoutSiteComponent } from '../layout/site/layout-site/layout-site.component';

/* Begin Import Site Pages */
import { HomeComponent } from '../pages/site/home/home.component';
// import { LoginComponent } from '../pages/site/login/login.component';
import { ForgetPasswordComponent } from '../pages/site/forget-password/forget-password.component';
import { SignUpPatientComponent } from '../pages/site/sign-up-patient/sign-up-patient.component';
import { SignUpMedecinComponent } from '../pages/site/sign-up-medecin/sign-up-medecin.component';
import { SignInPatientComponent } from '../pages/site/sign-in-patient/sign-in-patient.component';
import { SignInMedecinComponent } from '../pages/site/sign-in-medecin/sign-in-medecin.component';
/* End Import Site Pages */

/* Begin Import General Pages */

/* Begin Import General Pages */

/* Begin Import Doctor Pages */
import { DoctorDashboardComponent } from '../pages/medecin/doctor-dashboard/doctor-dashboard.component';
import { ScheduleComponent } from '../pages/medecin/schedule/schedule.component';
import { PatientsComponent } from '../pages/medecin/patients/patients.component';
import { ViewPatientComponent } from '../pages/medecin/view-patient/view-patient.component';
import { ListConsultationComponent } from '../pages/medecin/list-consultation/list-consultation.component';
import { DoctorMessageComponent } from '../pages/medecin/doctor-message/doctor-message.component';
import { DoctorNewMessageComponent } from '../pages/medecin/doctor-message/doctor-new-message/doctor-new-message.component';
import { DoctorShowMessageComponent } from '../pages/medecin/doctor-message/doctor-show-message/doctor-show-message.component';
import { DoctorMessageViewComponent } from '../pages/medecin/doctor-message-view/doctor-message-view.component';
// import { ViewConsultationComponent } from '../pages/medecin/view-consultation/view-consultation.component';
import { EditProfileDoctorComponent } from '../pages/medecin/edit-profile-doctor/edit-profile-doctor.component';
import { EditPasswordDoctorComponent } from '../pages/medecin/edit-password-doctor/edit-password-doctor.component';
import { MyAccountDoctorComponent } from '../pages/medecin/my-account-doctor/my-account-doctor.component';
import { TeleconsultationDoctorComponent } from '../pages/medecin/teleconsultation-doctor/teleconsultation-doctor.component';
import { DoctorpaymentmodeComponent } from '../pages/medecin/doctorpaymentmode/doctorpaymentmode.component';
import { ViewTeleconsultationDoctorComponent } from '../pages/medecin/view-teleconsultation-doctor/view-teleconsultation-doctor.component';
/* End Import Doctor Pages */

/* Begin Import Patient Pages */
import { PatientDashboardComponent } from '../pages/patient/patient-dashboard/patient-dashboard.component';
import { TeleconsultationPatientComponent } from '../pages/patient/teleconsultation-patient/teleconsultation-patient.component';
import { MyAccountPatientComponent } from '../pages/patient/my-account-patient/my-account-patient.component';
import { EditPasswordPatientComponent } from '../pages/patient/edit-password-patient/edit-password-patient.component';
import { EditProfilePatientComponent } from '../pages/patient/edit-profile-patient/edit-profile-patient.component';
import { MedicalFileComponent } from '../pages/patient/medical-file/medical-file.component';
import { MyAppointmentsComponent } from '../pages/patient/my-appointments/my-appointments.component';
import { SearchDoctorComponent } from '../pages/patient/search-doctor/search-doctor.component';
import { ViewDoctorComponent } from '../pages/patient/view-doctor/view-doctor.component';
import { AddPaymentModeComponent } from '../pages/patient/add-payment-mode/add-payment-mode.component';
import { EditPaymentModeComponent } from '../pages/patient/edit-payment-mode/edit-payment-mode.component';
import { ViewPaymentModeComponent } from '../pages/patient/view-payment-mode/view-payment-mode.component';
import { ViewTeleconsultationPatientComponent } from '../pages/patient/view-teleconsultation-patient/view-teleconsultation-patient.component';
/* End Import Patient Pages */

/* Begin Import Admin Pages */
import { AdminDashboardComponent } from '../pages/admin/admin-dashboard/admin-dashboard.component';
import { EditProfileAdminComponent } from '../pages/admin/edit-profile-admin/edit-profile-admin.component';
import { EditPasswordAdminComponent } from '../pages/admin/edit-password-admin/edit-password-admin.component';
import { MyAccountAdminComponent } from '../pages/admin/my-account-admin/my-account-admin.component';

import { AddDoctorComponent } from '../pages/admin/add-doctor/add-doctor.component';
import { ListDoctorComponent } from '../pages/admin/list-doctor/list-doctor.component';

import { AddPatientComponent } from '../pages/admin/add-patient/add-patient.component';
import { ListPatientComponent } from '../pages/admin/list-patient/list-patient.component';

import { AddDepartmentComponent } from '../pages/admin/add-department/add-department.component';
import { ListDepartmentComponent } from '../pages/admin/list-department/list-department.component';

import { AddRoleComponent } from '../pages/admin/add-role/add-role.component';
import { ListRoleComponent } from '../pages/admin/list-role/list-role.component';

import { AddPharmacyComponent } from '../pages/admin/add-pharmacy/add-pharmacy.component';
import { ListPharmacyComponent } from '../pages/admin/list-pharmacy/list-pharmacy.component';

import { AddUserComponent } from '../pages/admin/add-user/add-user.component';
import { ListUserComponent } from '../pages/admin/list-user/list-user.component';
import { ConfirmComponent } from '../pages/site/confirm/confirm.component';
import { ContactComponent } from '../pages/site/contact/contact.component';
import { ValidateEmailComponent } from '../pages/site/validate-email/validate-email.component';
import { VideoPatientComponent } from '../pages/patient/video-patient/video-patient.component';
import { PatientMessagesComponent } from '../pages/patient/patient-messages/patient-messages.component';
import { PatientMessageViewComponent } from '../pages/patient/patient-message-view/patient-message-view.component';
import { ReservationDoctorComponent } from '../pages/patient/reservation-doctor/reservation-doctor.component';
import { VideoDoctorComponent } from '../pages/medecin/video-doctor/video-doctor.component';
import { ShowMessageComponent } from '../pages/patient/patient-messages/show-message/show-message.component';
import { NewMessageComponent } from '../pages/patient/patient-messages/new-message/new-message.component';
import { InvoicePatientComponent } from '../pages/patient/invoice-patient/invoice-patient.component';
import {EProUserComponent} from '../pages/site/e-pro-user/e-pro-user.component';
import {ListTransactionComponent} from '../pages/admin/list-transaction/list-transaction.component';
import {ListRdvComponent} from '../pages/admin/list-rdv/list-rdv.component';
import {AddMenuComponent} from '../pages/admin/add-menu/add-menu.component';
import {ListMenuComponent} from '../pages/admin/list-menu/list-menu.component';
import { PatientGuardService } from '../services/patient-guard.service';
import { DoctorGuardService } from '../services/doctor-guard.service';
import { SupportMessageComponent } from '../pages/medecin/doctor-message/support-message/support-message.component';
import { SupportMessgePatientComponent } from '../pages/patient/patient-messages/support-messge-patient/support-messge-patient.component';
import {ConfirmActionComponent} from "../pages/admin/confirm-action/confirm-action.component";
import { ConfirmPaymentComponent } from '../pages/patient/confirm-payment/confirm-payment.component';
import { DetailAppointmentComponent } from '../pages/patient/detail-appointment/detail-appointment.component';
/* End Import Admin Pages */


const MEDECIN_ROUTES: Routes = [

  {
    path: 'dashboard',
    component: DoctorDashboardComponent,
  },

  {
    path: 'list/patient',
    component: PatientsComponent,
  },

  {
    path: 'messages',
    component: DoctorMessageComponent,
  },

  {
    path: 'new-message',
    component: DoctorNewMessageComponent,
  },
  {
    path: 'view-message',
    component: DoctorMessageViewComponent,
  },
  {
    path: 'show-message/:id_message/:idpat/:read',
    component: DoctorShowMessageComponent,
  },
  {
    path: 'edit/profile',
    component: EditProfileDoctorComponent,
  },

  {
    path: 'edit/password',
    component: EditPasswordDoctorComponent,
  },

  {
    path: 'schedule',
    component: ScheduleComponent,
  },

  {
    path: 'view/patient/:id',
    component: ViewPatientComponent,
  },

  {
    path: 'list/consultation',
    component: ListConsultationComponent,
  },

  {
    path: 'my-account',
    component: MyAccountDoctorComponent,
  },

  {
    path: 'teleconsultation',
    component: TeleconsultationDoctorComponent,
  },
  {
    path: 'video-doctor',
    component: VideoDoctorComponent,
  },
  {
    path: 'payment/mode',
    component: DoctorpaymentmodeComponent,
  },
  {
    path: 'support/send-message',
    component: SupportMessageComponent,
  },
  {
    path: 'view-teleconsultation-doctor',
    component: ViewTeleconsultationDoctorComponent,
  }

];


const SITES_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  /* {
    path: 'login',
    component: LoginComponent,
  }, */
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'patient/sign-up',
    component: SignUpPatientComponent,
  },
  {
    path: 'doctor/sign-up',
    component: SignUpMedecinComponent,
  },
  {
    path: 'patient/sign-in',
    component: SignInPatientComponent,
  },
  {
    path: 'doctor/sign-in',
    component: SignInMedecinComponent,
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'validate_email/:email',
    component: ValidateEmailComponent,
  },
  {
    path: 'admpro/user',
    component: EProUserComponent,
  },
];


const PATIENT_ROUTES: Routes = [
  {
    path: 'support-message',
    component: SupportMessgePatientComponent,
  },
  {
    path: 'invoice',
    component: InvoicePatientComponent,
  },
  {
    path: 'show-message/:id_message/:id_doc/:read',
    component: ShowMessageComponent,
  },
  {
    path: 'new-message',
    component: NewMessageComponent,
  },
  {
    path: 'reservation/doctor/:iddoc/:iddispo',
    component: ReservationDoctorComponent,
  },
  {
    path: 'dashboard',
    component: PatientDashboardComponent,
  },
  {
    path: 'medical-file',
    component: MedicalFileComponent,
  },
  {
    path: 'teleconsultation',
    component: TeleconsultationPatientComponent,
  },
  {
    path: 'my-account',
    component: MyAccountPatientComponent,
  },
  {
    path: 'edit/password',
    component: EditPasswordPatientComponent,
  },
  {
    path: 'edit/profile',
    component: EditProfilePatientComponent,
  },
  {
    path: 'my-appointments',
    component: MyAppointmentsComponent,
  },
  {
    path: 'messages',
    component: PatientMessagesComponent,
  },
  {
    path: 'reservation',
    component: ReservationDoctorComponent,
  },
  {
    path: 'view/message',
    component: PatientMessageViewComponent,
  },
  {
    path: 'search/doctor',
    component: SearchDoctorComponent,
  },
  {
    path: 'view/doctor/:id',
    component: ViewDoctorComponent,
  },
  {
    path: 'add/payment-mode',
    component: AddPaymentModeComponent,
  },
  {
    path: 'edit/payment-mode/:id',
    component: EditPaymentModeComponent,
  },
  {
    path: 'view/payment-mode/:id',
    component: ViewPaymentModeComponent,
  },
  {
    path: 'video-patient',
    component: VideoPatientComponent,
  },
  {
    path: 'view-teleconsultation-patient',
    component: ViewTeleconsultationPatientComponent,
  },

  {
    path: 'confirm/payment/:id',
    component: ConfirmPaymentComponent,
  },
  
  {
    path: 'view/appointment/:id',
    component: DetailAppointmentComponent,
  },



];


const ADMIN_ROUTES: Routes = [
  {
    path: 'dashboard',
    component: AdminDashboardComponent,
  },
  {
    path: 'edit/profile',
    component: EditProfileAdminComponent,
  },
  {
    path: 'edit/password',
    component: EditPasswordAdminComponent,
  },
  {
    path: 'my-account',
    component: MyAccountAdminComponent,
  },
  {
    path: 'add/doctor',
    component: AddDoctorComponent,
  },
  {
    path: 'list/doctor',
    component: ListDoctorComponent,
  },
  {
    path: 'add/patient',
    component: AddPatientComponent,
  },
  {
    path: 'list/patient',
    component: ListPatientComponent,
  },
  {
    path: 'add/department',
    component: AddDepartmentComponent,
  },
  {
    path: 'list/department',
    component: ListDepartmentComponent,
  },
  {
    path: 'add/role',
    component: AddRoleComponent,
  },
  {
    path: 'list/role',
    component: ListRoleComponent,
  },
  {
    path: 'add/pharmacy',
    component: AddPharmacyComponent,
  },
  {
    path: 'list/pharmacy',
    component: ListPharmacyComponent,
  },
  {
    path: 'add/user',
    component: AddUserComponent,
  },
  {
    path: 'list/user',
    component: ListUserComponent,
  },
  {
    path: 'list/transaction',
    component: ListTransactionComponent,
  },
  {
    path: 'list/appointment',
    component: ListRdvComponent,
  },
  {
    path: 'add/menu',
    component: AddMenuComponent,
  },
  {
    path: 'list/menu',
    component: ListMenuComponent,
  },
  {
    path: 'confirm/action',
    component: ConfirmActionComponent,
  }
];


export const ROUTES: Routes = [
  {
    path: '',
    component: LayoutSiteComponent,
    children: SITES_ROUTES
  },
  {
    path: 'patient',
    component: LayoutPatientComponent,
    canActivate : [PatientGuardService],
    children: PATIENT_ROUTES
  },
  {
    path: 'admin',
    component: LayoutAdminComponent,
    children: ADMIN_ROUTES
  },
  {
    path: 'doctor',
    component: LayoutMedecinComponent,
   // canActivate : [DoctorGuardService],
    children: MEDECIN_ROUTES
  },
 { path: '', redirectTo: '', pathMatch: 'full' },

  { path: '**', redirectTo: '' },
];


@NgModule({
  imports: [RouterModule],
  exports: [RouterModule]
})

/*
@NgModule({
  imports: [RouterModule.forRoot(Routes)],
  exports: [RouterModule],
  declarations: []
})
*/
export class AppRoutingModule { }
