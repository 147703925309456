import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResourceService } from 'src/app/services/resource.service';
import { Patient } from 'src/app/models/patient';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sidebar-patient',
  templateUrl: './sidebar-patient.component.html',
  styleUrls: ['./sidebar-patient.component.css']
})
export class SidebarPatientComponent implements OnInit,OnDestroy {

  avatar: string;
  userDisplayName = '';
  public userId :any;
  email = '';
  phone = '';
  adress = '';
  town = '';
  country = '';
  baseurl: string;
  patient: any;
  subs: Subscription;
  public baseUrl = environment.baseUrlImage;
  urls: string;

  constructor(private resourceService: ResourceService,private translateService: TranslateService) {
    this.patient = {} as Patient;
    this.subs = this.resourceService.getData().subscribe(data => {
      this.patient = data;

      this.urls = this.baseUrl + this.patient.avatar;

      console.log('subject', this.patient);
    });
  }

  ngOnInit() {
    this.userDisplayName = sessionStorage.getItem('username');
    this.userId = sessionStorage.getItem('userId');
    this.email = sessionStorage.getItem('email');
    this.phone = sessionStorage.getItem('phone');
    this.adress = sessionStorage.getItem('adress');
    this.town = sessionStorage.getItem('town');
    this.country = sessionStorage.getItem('country');
    this.avatar = sessionStorage.getItem('avatar');
    this.baseurl = environment.baseUrl + 'public/';

    this.getPatient();
  }

  getPatient() {
    this.resourceService.getResource('patient', this.userId).subscribe(data => {
      this.patient = data;
      // console.log('patient sideBare', this.patient);

      this.urls = this.baseUrl + 'public' + this.patient.avatar;
    }, err => {
      console.log('erreur sidebar patient', err);
    });
  }

ngOnDestroy(){
  this.subs.unsubscribe();
}

switchLanguage(language: string) {
  this.translateService.use(language);
}
}
