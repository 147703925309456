import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doctor-dashboard',
  templateUrl: './doctor-dashboard.component.html',
  styleUrls: ['./doctor-dashboard.component.css']
})
export class DoctorDashboardComponent implements OnInit {

  userId: any;
  nbrePatient: number;
  nbreRdv: any;
  nbreTele: any;
  today = new Date();
  rdvtogo: any;
  baseUrl: string;
  baseurl: string;
  rdvValidated: any;

  constructor(private resourceService:ResourceService, private router: Router, private toastr: ToastrService) { 
    
  }
  
  ngOnInit() {
  //  this.checkSession();
    this.baseUrl = environment.baseUrl;
    this.baseurl = this.baseUrl+'public/';
    this.userId = sessionStorage.getItem('userId'); 
   // this.getDoctor();
  }

  ngAfterViewInit(){
    this.getNumberPatient();
    this.getNumberTele();
    this.getrdvTogo();
    this.getrdvValidated();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }
  getNumberPatient(){
    this.resourceService.getResource('rdv/alldoc', this.userId).subscribe(data=>{
      
       //here we use filter to check unique pat from list
       let obj:any=data; this.nbreRdv = obj.length;
       var flags = {};
       var patients = obj.filter(function(entry) {
           if (flags[entry.patient_id]) {
               return false;
           }
           flags[entry.patient_id] = true;
           return true;
       });
       this.nbrePatient=patients.length;
      
    },err=>{
      console.log('erreur',err);
    });
   
  }

  getNumberTele(){
    this.resourceService.getResource('rdv/alldoc/tele', this.userId).subscribe(data=>{
      
       let obj:any=data; this.nbreTele = obj.length;
      
    },err=>{
      console.log('erreur',err);
    });
   
  }

  getrdvTogo(){
    this.resourceService.getResource('rdv/alldoctogotoday', this.userId).subscribe(data=>{
      
       let obj:any=data; this.rdvtogo = obj;
      
    },err=>{
      console.log('erreur',err);
    });
   
  }

  getrdvValidated(){
    this.resourceService.getResource('rdv/alldoc/validated', this.userId).subscribe(data=>{
      
       let obj:any=data; this.rdvValidated = obj; console.log(this.rdvValidated);
      
    },err=>{
      console.log('erreur',err);
    });
   
  }

  getDoctor() {
    this.resourceService.getResource('doc/findhimself', this.userId).subscribe(data => {
   this.resourceService.sendData(data);
    }, err => {
      console.log('erreur', err);
    });
  }
}
