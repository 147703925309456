import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.css']
})
export class HeaderAdminComponent implements OnInit {
  userId: any;
  public currentlang:any = '';

  constructor(private translateService: TranslateService, private resourceService: ResourceService, private router: Router) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
	
	let lang:any = navigator.language;
    if(lang){
      if (lang != "fr" && lang != "en") {
        this.translateService.setDefaultLang('en');
		this.currentlang = 'en';
      }else {
        this.translateService.setDefaultLang(lang);
		this.currentlang = lang;
      }   
    }else{
      this.translateService.setDefaultLang('en');
	  this.currentlang = 'en';
    }
  }
  
  switchLanguage(language: string) {
    this.translateService.use(language);
	this.currentlang = language;
  }

  logout(){
    this.resourceService.getResource('user/logout', this.userId).subscribe(data => {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/admpro/user']);
    } );
  }

}
