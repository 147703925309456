import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { Message } from 'src/app/models/message';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.css']
})
export class ShowMessageComponent implements OnInit {
  
  message_id: any;
  message:any;
  userId: string;
  baseurl: string;
  angForm: FormGroup;
  doctor_id: any;
  messages: any;
  read: any;
  submitted: boolean;
  
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private resourceService:ResourceService, private toastr: ToastrService) { 
    this.message={};
  }

  ngOnInit() {
  //  this.checkSession();
    this.createForm();
    this.message_id = this.route.snapshot.params.id_message;
    this.doctor_id = this.route.snapshot.params.id_doc;
    this.read = this.route.snapshot.params.read;
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.getMessage();
  }

  createForm() {
    this.angForm = this.fb.group({
      doctor: ['', Validators.required],
      subject: ['', Validators.required ],
      message: ['', Validators.required ]
    });
  }

  get f() {
    return this.angForm.controls;
  }

  onSubmit() {
    
      let data={
        doctor_id: this.doctor_id,
        patient_id: this.userId,
        message: this.angForm.value['message'],
        subject: this.angForm.value['subject']
      } 
     
    this.resourceService.saveResource('message/create_pat',data).subscribe(data=>{ 
      let res:any = data;
      if(res=='Done')
        this.toastr.success('Message envoye', 'Success');
        this.router.navigate(['/patient/messages']);    
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('err', err);
    })

  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getMessage(){
    this.resourceService.getResources('message/pat/'+this.message_id+'/'+this.userId+'/'+this.read).subscribe(data=>{
      this.messages = data;  this.doctor_id = this.messages.doctor.id;
    },err=>{
      this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  }
}
