import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { MustMatch } from '../../../settings/must-match.validator';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import paysdata from 'src/app/pays.json';
import { sha256 } from 'js-sha256';
import { Patient } from 'src/app/models/patient';


@Component({
  selector: 'app-sign-up-patient',
  templateUrl: './sign-up-patient.component.html',
  styleUrls: ['./sign-up-patient.component.css']
})
export class SignUpPatientComponent implements OnInit {

  step2Form: FormGroup;
  submitted = false;
  step1Form: FormGroup;
  step1 = false;

  urls: any = "assets/images/xs/user.png";
  fileData: File = null;
  baseUrl: string;
  pays: any;
  patient: Patient;
  loading = false;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
    this.patient = {} as Patient
  }

  ngOnInit() {
    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
     this.urls = "assets/images/xs/user.png";
    this.createForm();
  }

  createForm() {
    this.step2Form = this.fb.group({
      checkbox: ['', Validators.required],
      country: [null, Validators.required],
      town: ['', Validators.required],
      postalcode: [''],
      adress: ['', Validators.required]
    });

    this.step1Form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      lastname: [''],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
      date_of_birth: ['', Validators.required]

    }, {
      validator: MustMatch('password', 'confirmpassword')
    });
  }

  checkemail(value) {
    this.resourceService.getResources_unsafe('patient/checkemail/' + value).subscribe((data) => {
      if (data == "Done")
        this.toastr.error('Cette adresse e-mail existe deja', 'Error');
    });
  }

  // Validators.pattern('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Z\\d!$%@#£€*?&]{6,}$')],
  onSubmitStep1() {
    this.step1 = true;
    if (this.step1Form.invalid) {
      return;
    }


  }

  get f1() {
    return this.step1Form.controls;
  }

  get f() {
    return this.step2Form.controls;
  }

  onSubmitStep2() {

    this.submitted = true;
    if (this.step2Form.invalid || !this.step2Form.value['checkbox']) {
      return;

    } else {
      this.loading = true;
      let formValue2 = this.step2Form.value;
      let formData = new FormData();
      let formValue = this.step1Form.value;

      formData.append('username', formValue['username']);
      formData.append('lastname', formValue['lastname']);
      formData.append('phone', formValue['phone']);
      formData.append('email', formValue['email']);
      formData.append('password', sha256(formValue['password']));
      formData.append('date_of_birth', formValue['date_of_birth']);
      formData.append('postalcode', formValue2['postalcode']);
      formData.append('town', formValue2['town']);
      formData.append('country', formValue2['country']);
      formData.append('adresse', formValue2['adress']);
      formData.append('url', environment.validateemail);
      formData.append('code_verify',this.codeVerifiedEmail());
      if (this.fileData) {
        let newName = sha256(this.fileData.name) +'.'+this.fileData.name.split('.').pop();
        formData.append('avatar', this.fileData,newName);
      }
      this.resourceService.createaccountResource('patient/createe', formData).subscribe((data) => {
        let res: any = data;
        if (res == "Erreur") {
          this.toastr.error('Votre compte ne peut etre crée! E-mail deja existant!', 'Error');
          this.loading = false;
        } else {
          this.toastr.success('Votre compte a été crée!', 'Sucess');
          this.router.navigate(['/confirm']);
        }

      }, err => {
        this.loading = false;
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur ICI', err);

      });
    }

  }

  private codeVerifiedEmail() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  onSelectFile(events) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.newPatient.avatar=this.fileData;

      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {

    }
  }

}
