import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'src/app/models/message';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { SocketService } from 'src/app/services/socket.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-doctor-new-message',
  templateUrl: './doctor-new-message.component.html',
  styleUrls: ['./doctor-new-message.component.css']
})
export class DoctorNewMessageComponent implements OnInit, OnDestroy {

  public msg: any;
  angForm: FormGroup;
  submitted = false;
  patient: any;
  userId: any;
  id: any;
  patients: any;

  baseUrlImage: string;
  name: string;
  reponse_message: any;
  messages: any;
  notif = false;
  nbre_message = [];
  patient_select: any;
  user_id: number;
  subs: any;
  nbr = 0;
  notif_s: any;

  constructor(private socketService: SocketService,
    private serviceResource: ResourceService,
    private datepipe: DatePipe,
    private title: Title) {

    this.title.setTitle('Chat');
    this.subs = this.socketService.getMessage().subscribe((data: any) => {

      this.reponse_message = data.message;
      this.nbre_message = data.nbre_message;
      console.log('ts', data);

      if (this.user_id && parseInt(this.reponse_message.patient_id) == this.user_id) {
        $('#message').append($('<li><div class="message-data"> <span class="message-data-name"><i class="zmdi zmdi-circle online"></i>' + this.patient_select.username + '</span> <span class="message-data-time">' + this.datepipe.transform(this.reponse_message.date_created, "short", "UTC") + '</span> </div><div class="message my-message"> <p>' + this.reponse_message.message + '</span> </div><div class="message my-message"> <p>' + this.reponse_message.message + '</p></div></li>'));

      }
    });

    this.notif_s = this.socketService.getDoctorResponseNotif().subscribe((notif: any) => {
      this.notif = notif.write;
      // console.log('notif', this.notif);

    })
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
    this.notif_s.unsubscribe();
  }

  ngOnInit() {

    this.baseUrlImage = environment.baseUrlImage;
    $(".list_btn").on('click', function () {
      $("#plist").toggleClass("open");
    });
    this.userId = sessionStorage.getItem('userId');
    this.name = sessionStorage.getItem('username');
    this.getPatients();

  }


  getPatients() {
    this.serviceResource.getResources("patient/list_patient/" + this.userId).subscribe((data: any) => {
      console.log('liste des doctors', data);
      this.patients = data;
    }, err => {

    });
  }

  getMessagePatient(id) {
    this.serviceResource.getResources("message/list_pat/" + id).subscribe((data: any) => {
      console.log('liste des messages du patients', data);
      this.messages = data;
    }, err => {
      console.log('errr', err);
    });
  }

  selectedPatient(p) {

    this.user_id = parseInt(p.id);
    if (this.nbre_message[p.id]) {
      this.nbre_message[p.id].nbre = 0;
    }

    this.getMessagePatient(p.id);
    this.id = p.id;
    this.patient_select = p;
    console.log(p);


  }
  send() {
    if (this.msg) {

      let patient = {
        type: "doctor",
        id: this.patient_select.id,
        write: false
      }
      this.socketService.notif(patient);

      let data = {
        patient_id: this.patient_select.id,
        message: this.msg,
        type: "doctor",
        doc_id: this.userId,
      }
      this.socketService.sendMessageDoctor(data);

      let t = ' <li class="clearfix"><div class="message-data text-right"> <span class="message-data-time">' + this.datepipe.transform(new Date(), "short", "UTC") + '</span> &nbsp;  <span class="message-data-name">' + this.name + '</span><i class="zmdi zmdi-circle me"></i> </div><div class="message other-message float-right">' + this.msg + '</div></li>';
      $('#message').append($(t));
      this.msg = "";
    }

  }

  write(e) {
    if (e.target.value.length == 1) {
      // console.log('whrite');
      let patient = {
        type: "doctor",
        id: this.patient_select.id,
        write: true
      }
      this.socketService.notif(patient);
    }
    if (e.target.value.length == 0) {
      // console.log('whrite');
      let patient = {
        type: "doctor",
        id: this.patient_select.id,
        write: false
      }
      this.socketService.notif(patient);
    }

    if (e.keyCode == 13) {
      if (this.msg !== "") {
        this.send();
      }

    }


  }
}
