import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Files } from 'src/app/models/file';
import { Errors } from 'src/app/settings/function_errors';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';

import { Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-medical-file',
  templateUrl: './medical-file.component.html',
  styleUrls: ['./medical-file.component.css']
})
export class MedicalFileComponent implements OnInit, OnDestroy {
  
  files:any;
  appel: boolean;
  timer: any;

  speciality: any;
  doctor: string;
  userId: any;
  p: number = 1;
  total: number;
  submitted = false;
  form: FormGroup;
 
  ordonnance=[
    {
      id:1,
      ordo:'ordonnance 1'
    },
    {
      id: 2,
      ordo: 'ordonnance 2'
    },
    {
      id: 3,
      ordo: 'ordonnance 3'
    },
    {
      id: 4,
      ordo: 'ordonnance 4'
    }

  ];

  idOrdonnance:any;
  idParmacies: any;
  modalRef: any;
  pharmacies: Object;

  constructor(private fb: FormBuilder,
              private modalService: NgbModal,
              private router: Router,
              private c: ConfirmationDialogService,
              private resourceService: ResourceService,
              private toastr: ToastrService,
               )  {

                

    this.form = this.fb.group({
      checkArray: this.fb.array([]),
      subject: [''],
      message: ['', Validators.required],
    })
   }

  get f() {

    return this.form.controls;
  }

  ngOnInit() {
   // this.checkSession();
 //   this.getTimer();
    this.userId = sessionStorage.getItem('userId');
    this.getFilePatient(1);
    this.getAllPharmacies();
  }

  ngOnDestroy() {
   // this.timer.unsubscribe();
  }

  


  getAppel() { 
    let tele:any
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    if(tele && tele.id){
      sessionStorage.setItem('roomname',tele.roomname);
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    }else
      return 'None';
    
  }

// appeler cette methode dans le ngOnInit
  getFilePatient(page:number){
    this.resourceService.getResources('patient/files/'+this.userId+'/'+page).subscribe(data=>{
      let datas:any = data;
      this.files=datas.rows;
      this.total = datas.count;
      this.p=page;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');

    })
  }

  deleteitem(id) {
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer cette ligne... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('patient/delfile',id).subscribe(data=>{
          this.toastr.success('le fichier a été suprimé!', 'Sucess');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur', err);
        });
      }
    });
  }

  openModal(content,id:any){

    this.modalRef =this.modalService.open(content, { size: 'lg' });
    console.log('send ordonnance',id);
    this.idOrdonnance=id;
    
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
      console.log(e.target.value);
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.idParmacies = checkArray.value;
  
  }

 


  getAllPharmacies(){

    this.resourceService.getResources('pharmacie/all').subscribe(data=>{

   this.pharmacies=data;
   console.log('a',this.pharmacies);
          
    },err=>{

        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);

    });

  }


  sendEmail(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let ordo={
      idfile: this.idOrdonnance,
      subject: this.form.value['subject'],
      message: this.form.value['message'],
      tomail: JSON.stringify(this.idParmacies),
      attach:''
    }

    console.log('ordo',ordo);

    // this.resourceService.saveResource_unsafe('/patient/sendmailpharmacies', ordo).subscribe(data => {
    //   let res: any = data;
    //   if (res == "Done"){
    //     this.toastr.success('Envoye!', 'Succes');
    //     this.modalRef.close();
    //   }
    
    //   else
    //     this.toastr.error('Echec envoi!', 'Major Error');
    //     this.modalRef.close();
    // }, err => {
    //   this.toastr.error(Errors(err), 'Major Error');
    //   console.log('erreur', err);

    //     this.modalRef.close();
    // });
  }
}
