import { Component, OnInit, OnDestroy } from '@angular/core';
import { timer } from 'rxjs';
import { Router,  NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Message } from 'src/app/models/message';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';


@Component({
  selector: 'app-patient-messages',
  templateUrl: './patient-messages.component.html',
  styleUrls: ['./patient-messages.component.css']
})
export class PatientMessagesComponent implements OnInit, OnDestroy {
  
  timer: any;
  public source = timer(1000, 2000);
  doctor: string;
  speciality: any;
  userId: any;
  messages: any;
  baseurl: string;
  message:any;
  msg:any;
  angForm: FormGroup;
  checkArray:FormArray;
  p: number = 1;
  total: number;
  selectedAll: any;
  messages_context: string;

  constructor( private router: Router, private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService, private resourceService:ResourceService, private fb: FormBuilder) {

    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.message={} as Message;
    this.angForm = this.fb.group({
      checkArray: this.fb.array([])
    })

  }

  ngOnInit() {
  //  this.checkSession();
    this.getTimer();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.getMessages();
    this.getmessagesPatient(1,"doc");
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getTimer(){
    this.timer = this.source.subscribe(val => {
      if (this.getAppel() != 'None') {
        this.timer.unsubscribe();
        this.c.confirm('Appel entrant', 'Téléconsutaton').then(confirmed => {

          console.log('appel accepter', confirmed);
          if (confirmed == true) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          } else if (confirmed == false) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          }
        });
      }
    });
  }

  getAppel() { 
    let tele:any
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    if(tele && tele.id){
      sessionStorage.setItem('roomname',tele.roomname);
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    }else
      return 'None';
  }

  getMessages() { 
    this.resourceService.getResource('message/list_pat',this.userId).subscribe(data=>{
      this.msg = data;
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    }); 
  }

  // appeler cette methode dans le ngOnInit
  getmessagesPatient(page:number,type){
    this.messages_context = 'Messages Recus';
    this.resourceService.getResources('message/'+this.userId+'/'+page+'/'+type).subscribe(data=>{
      let datas:any = data;
      this.msg=datas.rows;
      this.total = datas.count;
      this.p=page;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');

    })
  }

  checked(key){
    this.resourceService.getResources('message/search/'+this.userId+'/'+key).subscribe(data=>{
      let datas:any = data;
      this.msg=datas;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  }
  
  selectAll() {
    for (var i = 0; i < this.msg.length; i++) {
      this.msg[i].selected = this.selectedAll;
    }
  }
  
  checkIfAllSelected() {
    this.selectedAll = this.msg.every(function(item:any) {
        return item.selected == true;
      })
  }

  deleteMessage(){
    let msgs=[];
    for (var i = 0; i < this.msg.length; i++) {
      if(this.msg[i].selected){
        msgs = [this.msg[i].id,...msgs];
      }
    }
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer ces lignes... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.getsaveResource('message/delete',msgs).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }
 
}
