import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../../settings/must-match.validator';

@Component({
  selector: 'app-add-payment-mode',
  templateUrl: './add-payment-mode.component.html',
  styleUrls: ['./add-payment-mode.component.css']
})
export class AddPaymentModeComponent implements OnInit {
  angForm: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.angForm = this.fb.group({
      Payment_Number: ['',[Validators.required,Validators.minLength(6)]]
    });
  }

  get f() {
    console.log(this.angForm.controls);
     return this.angForm.controls; 
    }


  onSubmit(){
    this.submitted=true;

    if (this.angForm.invalid) {
      return;
    }
    console.log('ok');
  }
}
