import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';


import paysdata from 'src/app/pays.json';
import { NgSelectConfig } from '@ng-select/ng-select';
// import { Ng2ImgMaxService } from 'ng2-img-max';


@Component({
  selector: 'app-edit-profile-doctor',
  templateUrl: './edit-profile-doctor.component.html',
  styleUrls: ['./edit-profile-doctor.component.css']
})
export class EditProfileDoctorComponent implements OnInit {
  Form: any;
  urls: any;
  fileData: File;
  submitted: any;
  doctor: any;
  baseUrl: string;
  userId: any;
  specialities: Object;
  loading = false;
  checkArray: FormArray;
  speciality: any;
  Form1:any;
  pays: any;
  submitted1 =false;
 


  constructor(private config: NgSelectConfig,
    private router: Router, 
    private fb: FormBuilder, 
    private resourceService: ResourceService, 
    private toastr: ToastrService,
  ) 
  { 
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
  }

  ngOnInit() {
    this.pays = paysdata;
    this.userId = sessionStorage.getItem("userId");
    this.baseUrl = environment.baseUrlImage;
 
    this.createForm();
    this.createForm1();
    this.getDoctor();
    this.getSpecialities();
    this.checkArray = this.Form.get('checkArray') as FormArray;
  }



  createForm() {
    this.Form = this.fb.group({
      name: ['', Validators.required],
      surname: [''],
      email: new FormControl({ value: '', disabled: true }),
      birthdate: ['', Validators.required],
      tel: ['', Validators.required],
      country: [null, Validators.required],
      town: ['', Validators.required],
      postalcode: [''],
      adress: ['', Validators.required],
      checkArray: this.fb.array([])
    });
  }
  
  createForm1(){
    this.Form1=this.fb.group({
      num_agreement: ['', Validators.required],
      registered: ['', Validators.required],
      country_faculty: [null, Validators.required],
      town_faculty: ['', Validators.required],
      faculty: ['', Validators.required],
      diploma: ['', Validators.required],

    });
  }

  get f() {
    return this.Form.controls;
  }

  get f1() {
    return this.Form1.controls;
  }

  onSelectFile(events) {
    if (events.target.files && events.target.files[0]) {
    let image=  this.fileData = events.target.files[0];
      // this.ng2ImgMax.resize([image], 400, 300).subscribe(
      //   result => {
      //    console.log(new File([result], result.name)) ;
      //   },
      //   error => {
      //     console.log(' Oh no!', error);
      //   }
      // );
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    }
  }

  Onsubmit() {
    this.submitted = true;
    if (this.Form.invalid) {
      console.log(this.Form.controls);
      return;
    }

    let formData = new FormData();
    this.loading = true;
    let formValue = this.Form.value;
    formData.append('name', formValue['name']);
    formData.append('surname', formValue['surname']);
    formData.append('tel', formValue['tel']);
    formData.append('birthdate', formValue['birthdate']);
    formData.append('country', formValue['country']);
    formData.append('town', formValue['town']);
    formData.append('postalcode', formValue['postalcode']);
    formData.append('adress', formValue['adress']);
   
    //sha256
   

    if (this.fileData){
      let newName = sha256(this.fileData.name)+'.'+this.fileData.name.split('.').pop();
      formData.append('avatar', this.fileData,newName);
    }
    
    this.resourceService.saveResource('doc/update/' + this.userId, formData).subscribe(data => {
      this.getDoctor();
      this.toastr.success('Modification effectuée', 'Sucess');
    }, err => {
      this.loading = false;
      this.toastr.error(Errors(err), 'Major Error');
      console.log('erreur', err);
    },()=>{
        this.loading = false;
    })
  }


  getDoctor() {
    this.resourceService.getResource('doc/findhimself', this.userId).subscribe(data => {
      this.doctor = data;
      this.urls = this.baseUrl + this.doctor.avatar;
        this.resourceService.sendData(this.doctor);
      console.log('docteur', this.doctor);
      this.doctor.specialities.forEach(element => {
        // console.log('option passy',element.id);
        this.checkArray.push(new FormControl(parseInt(element.id)));
      });

    }, err => {
      console.log('erreur', err);
    });
  }

  getSpecialities() {

    this.resourceService.getResources_unsafe('speciality/listall').subscribe(data => {
      this.specialities = data;

    }, err => {
      console.log('erreur', err);
    })
  }


  onCheckboxChange(e) {
    console.log('value', e.target.value);
    if (e.target.checked) {
      this.checkArray.push(new FormControl(parseInt(e.target.value)));
    } else {
      let i: number = 0;
      this.checkArray.controls.forEach((item: FormControl) => {
        if (parseInt(item.value) == parseInt(e.target.value)) {
          this.checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }


  compare(id): Boolean {
    for (let index = 0; index < this.doctor.specialities.length; index++) {
      if (this.doctor.specialities[index].id == id) {
        return true;
      }

    }
  }

  updateSpecialities(){

    this.loading=true;
    let data={
      specialities: JSON.stringify(this.checkArray.value)
    } 

    this.resourceService.updateResource_safely('doc/specialities/update/' + this.userId, data ).subscribe(data=>{
      this.toastr.success('Modification effectuée', 'Sucess');
      this.loading = false;
    },err=>{
        this.loading = false;
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
    });
  }

  OnsubmitInfo(){

  

    this.submitted1 = true;
    if (this.Form1.invalid) {
      console.log(this.Form1.controls);
      return;
    }

    let formData = new FormData();
    this.loading = true;
    let formValue = this.Form1.value;
    formData.append('num_agreement', formValue['num_agreement']);
    formData.append('registered', formValue['registered']);
    formData.append('faculty', formValue['faculty']);
    formData.append('town_faculty', formValue['town_faculty']);
    formData.append('country_faculty', formValue['country_faculty']);
    formData.append('diploma', formValue['diploma']);
    
    this.resourceService.saveResource('doc/update/' + this.userId, formData).subscribe(data => {
      this.getDoctor();
      this.toastr.success('Modification effectuée', 'Sucess');
    }, err => {
      this.loading = false;
      this.toastr.error(Errors(err), 'Major Error');
      console.log('erreur', err);
    }, () => {
      this.loading = false;
    })
  }
}
