import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Reservation } from 'src/app/models/reservation';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';
import { Doctor } from 'src/app/models/doctor';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { timer } from 'rxjs';
import paysdata from 'src/app/pays.json';
import { Payments } from 'src/app/models/payments';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-reservation-doctor',
  templateUrl: './reservation-doctor.component.html',
  styleUrls: ['./reservation-doctor.component.css']
})
export class ReservationDoctorComponent implements OnInit, OnDestroy{
  formProcess: any;
  message: any;
  customStripeForm: FormGroup;
  angForm: FormGroup;
  motifForm: FormGroup;
  submitted = false;
  submitted1 = false;
  proche = false;
  reservation: any;
  payment: any;
  doctor_id: any;
  doctor: Object;
  speciality: any;
  timer: any;
  userId: any;
  dispo_id: any;
  pays: any;
  rdv: any;
  rdvId: any;
  // handler: any;
  handler: any = null;
  private stripeData: any = [];


  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private c: ConfirmationDialogService, private toastr: ToastrService, private resourceService: ResourceService) {
    this.reservation ={} as Reservation;
    this.payment = {} as Payments;
    this.rdv = {}
  }

  ngOnInit() {
 
    this.doctor = {} as Doctor;
    this.pays = paysdata;
    this.createForm();
    this.setPatientValidators();
  
    this.loadStripe();
    this.userId = sessionStorage.getItem('userId');
    this.doctor_id = this.route.snapshot.params.iddoc;
    this.dispo_id = this.route.snapshot.params.iddispo;
  }


  ngOnDestroy() {
 
  }


  createForm() {
    this.angForm = this.fb.group({
      patient: ['me'],
      name: [''],
      surname: [''],
      adress: [''],
      email: [''],
      date_of_birth: [''],
      town: [''],
      country: [null]

    });

    this.motifForm=this.fb.group({
      motif: [null],
    });

	this.customStripeForm = this.fb.group({
      cardNumber: ['', Validators.required],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      cvv: ['', Validators.required]
    });
  }

  get f() {
    return this.angForm.controls;
  }

  get f1() {
    return this.motifForm.controls;
  }

  setPatientValidators() {
    const name = this.angForm.get('name');
    const surname = this.angForm.get('surname');
    const email = this.angForm.get('email');
    const date_of_birth = this.angForm.get('date_of_birth');
    const town = this.angForm.get('town');
    const country = this.angForm.get('country');
    const adress = this.angForm.get('adress');

    this.angForm.get('patient').valueChanges.subscribe(patient => {

        if (patient === 'me') {
          this.proche = false;
          name.setValidators(null);
          surname.setValidators(null);
          email.setValidators(null);
          date_of_birth.setValidators(null);
          town.setValidators(null);
          country.setValidators(null);
          adress.setValidators(null);
        }
        if (patient === 'proche') {
          this.proche = true;
          name.setValidators([Validators.required]);
          surname.setValidators([Validators.required]);
          email.setValidators([Validators.required, Validators.email]);
          date_of_birth.setValidators([Validators.required]);
          town.setValidators([Validators.required]);
          country.setValidators([Validators.required]);
          adress.setValidators([Validators.required]);
        }

        name.updateValueAndValidity();
        surname.updateValueAndValidity();
        email.updateValueAndValidity();
        date_of_birth.updateValueAndValidity();
        town.updateValueAndValidity();
        country.updateValueAndValidity();
        adress.updateValueAndValidity();
    });
  }

  getdisponibility(){

    this.resourceService.getResource('dispo',this.dispo_id).subscribe((data:any)=>{
      let dispo:any = data;
      this.reservation.disponibility_id = dispo.id;
      this.reservation.type = dispo.type;
      this.reservation.start_time = dispo.start_time;
      this.reservation.end_time = dispo.end_time;
      this.reservation.status = dispo.status;
      this.reservation.date_created = dispo.date_created;
      this.reservation.doctor = dispo.doctor.surname +', '+dispo.doctor.name;
      this.reservation.doctor_adress = dispo.doctor.town +', '+dispo.doctor.country;
      this.reservation.tariff = dispo.doctor.tariff;

    },err=>{
      this.toastr.error(Errors(err), 'Major Error');
      console.log('err', err);
    });
  }

  onSubmitStep1() {
    this.submitted = true;

    if(this.angForm.value['patient']=="proche"){
      this.reservation.name = this.angForm.value['name'];
      this.reservation.surname = this.angForm.value['surname'];
      this.reservation.adress = this.angForm.value['adress'];
      this.reservation.date_of_birth = this.angForm.value['date_of_birth'];
      this.reservation.email = this.angForm.value['email'];
      this.reservation.country = this.angForm.value['country'];
      this.reservation.town = this.angForm.value['town'];
      this.getdisponibility();
    }else{

      this.resourceService.getResource('patient',this.userId).subscribe((data:any)=>{
        let patient:any = data;
        this.reservation.patient_id = patient.id;
        this.reservation.name = patient.lastname;
        this.reservation.surname = patient.username;
        this.reservation.adress = patient.adresse;
        this.reservation.date_of_birth = patient.date_of_birth;
        this.reservation.email = patient.email;
        this.reservation.country = patient.country;
        this.reservation.postalcode = patient.postalcode;
        this.getdisponibility();
      },err=>{
          this.toastr.error(Errors(err), 'Major Error');
          console.log('err', err);
      });
    }

    if (this.angForm.invalid) {
      return;
    }
  }

  back(){
    this.submitted=false;
  }

  public motif(){

    this.submitted1 = true;
    this.reservation.motif=this.motifForm.value['motif'];

    // write into beneficial table record
    if(this.angForm.value['patient']=="proche"){

      this.reservation.name = this.angForm.value['name'];
      this.reservation.surname = this.angForm.value['surname'];
      this.reservation.adress = this.angForm.value['adress'];
      this.reservation.birth_date = this.angForm.value['date_of_birth'];
      this.reservation.email = this.angForm.value['email'];
      this.reservation.country = this.angForm.value['country'];
      this.reservation.town = this.angForm.value['town'];

      this.resourceService.saveResource('beneficial/create',this.reservation).subscribe((data:any)=>{
        let res:any = data;
        if(res =='Erreur')
          this.toastr.error("Erreur de création du compte beneficiaire", 'Error');
        else{
          this.reservation.beneficial_id = res.id;
          this.toastr.success("Compte beneficiaire crée", 'Success');
        }
      },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('err', err);
      });
    }

  }


  checkout() {

	var handler = (<any>window).StripeCheckout.configure({
		key: environment.spkKey,
		image: 'assets/images/logo.jpg',
		locale: 'auto',
		token: async token => {
			// You can access the token ID with `token.id`.
			// Get the token ID to your server-side code for use.
			console.log(token);
			alert('Token Created!!');
			this.stripeData = {
				'stripeToken': token.id,
				'stripeEmail': this.reservation.email,
				'currency': 'xaf',
				'amount': this.reservation.tariff,
				'description': this.reservation.type +' '+ this.reservation.motif
			};
			console.log(this.stripeData);

			try {
				const data = await this.resourceService.postResource('checkout', this.stripeData);

				if (data['success']){
					alert ('Payment Success!'); console.log(data);
					//save data rdv and payment
					this.saveconfirm(data['customer']);
					this.toastr.success('Payment Success!', 'Successfully purchased items');
				}
				else{
					alert ('Payment Fail!'); console.log(data);
					this.toastr.error(Errors(data['message']), 'Major Error');
				}

			} catch (error) {
				alert ('Payment Fail!'); console.log(error);
				this.toastr.error(Errors(error['message']), 'Major Error');
			}
		},
    });

	try
	{
		handler.open({
			name: 'E-Pocrate Subscription',
			description: this.reservation.type + ' ' + this.reservation.motif,
			amount: this.reservation.tariff,
			currency: 'xaf',
			/* closed: () => {
				this.btnDisabled = false;
			}, */
		});
	} catch (error) {
		alert ('Fail to Open');
		console.log(error);
	}

  }


  loadStripe() {
    this.payment.details="Stripe";

	if(!window.document.getElementById('stripe-script')) {
        var s = window.document.createElement("script");
        s.id = "stripe-script";
        s.type = "text/javascript";
        s.src = "https://checkout.stripe.com/checkout.js";
        s.onload = () => {
			this.handler = (<any>window).StripeCheckout.configure({
				key: environment.spkKey,
				image: 'assets/images/logo.jpg',
				locale: 'auto',
				token: async token => {
					// You can access the token ID with `token.id`.
					// Get the token ID to your server-side code for use.
					console.log(token);
					alert('Token Created!!');
					this.stripeData = {
						'stripeToken': token.id,
						'stripeEmail': this.reservation.email,
						'currency': 'xaf',
						'amount': this.reservation.tariff,
						'description': this.reservation.type +' '+ this.reservation.motif
					};
					console.log(this.stripeData);

					try {
						const data = await this.resourceService.postResource('checkout', this.stripeData);

						if (data['success']){
							alert ('Payment Success!'); console.log(data);
							//save data rdv and payment
							this.saveconfirm(data['customer']);
							this.toastr.success('Payment Success!', 'Successfully purchased items');
						}
						else{
							alert ('Payment Fail!'); console.log(data);
							this.toastr.error(Errors(data['message']), 'Major Error');
						}

					} catch (error) {
						alert ('Payment Fail!'); console.log(error);
						this.toastr.error(Errors(error['message']), 'Major Error');
					}
				},
			});

		}

		window.document.body.appendChild(s);
    }
  }


  getDoctor(){
    this.resourceService.getResource('doc/find', this.doctor_id).subscribe((data:any) => {
      this.doctor = data;
    }, err => {
      this.toastr.error(Errors(err), 'Major Error');
      console.log('err', err);
    });
  }

  public saveconfirm(customerId){
	
    this.rdv.label = "Consultation_"+sessionStorage.getItem('username');
	//this.rdv.description = "Description: "+this.reservation.motif;
    this.rdv.description = this.reservation.motif ? this.reservation.motif:null;
    this.rdv.type = this.reservation.type;
    this.rdv.date_hours = this.reservation.start_time;
    this.rdv.disponibility_id = this.dispo_id;
    this.rdv.patient_id = this.userId;
    // this.rdv.doctor_id = this.doctor_id;
    if(this.reservation.beneficial_id)
      this.rdv.beneficial_id = this.reservation.beneficial_id;

    // create rdv
    this.resourceService.saveResource('rdv/create', this.rdv).subscribe((data:any) => {
        console.log('data', data);
		this.rdvId = data[0].id;
		console.log('rdvId', this.rdvId);
		//set update disponibility
		this.updateDisponibility(this.rdv.disponibility_id, customerId);
    }, err => {
      this.toastr.error(Errors(err), 'Major Error');
      console.log('save rdv err', err);
    });
  }

  public updateDisponibility(id, customerId) {
    // update disponibility status to taken
    this.resourceService.getResource('dispo/update',id).subscribe((data:any) => {
        // save data payment
		this.savePayment(customerId);
    }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('update dispo err', err);
    });
  }

  public savePayment(customerId) {
    this.payment.description = this.reservation.type +' '+ this.reservation.motif;
    this.payment.total = this.reservation.tariff;
    //this.payment.total=this.reservation.tariff?this.reservation.tariff:0;
    this.payment.status = 1;
    this.payment.transaction_number = customerId;
    this.payment.details = this.reservation.type +' '+ this.reservation.motif;
	this.payment.patient_id = this.userId;
	this.payment.rdv_id = this.rdvId;
    this.resourceService.saveResource("payment/create",this.payment).subscribe((data: any)=>{
      let res:any = data;
	  console.log('datas', data);
	  this.router.navigate(['/patient/confirm/payment/'+res[0].id]);
    },err=>{
      this.toastr.error(Errors(err), 'Major Error');
      console.log('save payment err', err);
    });
  }    
}
