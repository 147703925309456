import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Subscription } from 'rxjs';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { SocketserviceService } from 'src/app/services/socketservice.service';
import { MediastreamService } from 'src/app/services/mediastream.service';
import { NgxLoadingModule } from 'ngx-loading';
import adapter from 'webrtc-adapter';
import { saveAs } from 'file-saver';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
    RTCSessionDescription: RTCSessionDescription;
    mozRTCSessionDescription: RTCSessionDescription;
    webkitRTCSessionDescription: RTCSessionDescription;
    RTCIceCandidate: RTCIceCandidate;
    mozRTCIceCandidate: RTCIceCandidate;
    webkitRTCIceCandidate: RTCIceCandidate;
  }
}

@Component({
  selector: 'app-view-teleconsultation-patient',
  templateUrl: './view-teleconsultation-patient.component.html',
  styleUrls: ['./view-teleconsultation-patient.component.css']
})
export class ViewTeleconsultationPatientComponent implements OnInit, AfterViewInit, OnDestroy {

  public browser = <any>navigator;
  public title: string = 'Video Call Teleconsultation';
  public introline: string = '(E-Pocrate Medical Platform)';
  public subscription: Subscription;
  public serverStatus: boolean;
  public userId: any = '';
  public socketId: any = '';
  // public uniqueId: any = '';
  public fromUserId: any;
  public toUserId: any;
  // public toUniqueId: any = '';
  public toSocketId: any = '';
  public users: any = [];
  public doctors: any = [];
  public textEnable: boolean = true;
  public fileEnable: boolean = true;
  public audioEnable: boolean = false;
  public videoEnable: boolean = true;
  public callEnable: boolean = false;
  public btntextEnable: boolean = true;
  public btnfileEnable: boolean = false;
  public btnaudioEnable: boolean = false;
  public btnvideoEnable: boolean = true;
  public btncallEnable: boolean = false;
  public btnendcallEnable: boolean = false;
  // public screenEnable: boolean = false;
  public connected: boolean = false;
  public peerConnection: any;
  public dataChannel: any;
  public offer: any;
  public message: any;
  public messages: string[] = [];
  public audio: any;
  public remoteAudio: any;
  public audioStream: any;
  public audioTrack: AudioTrack;
  public videoTrack: VideoTrack;
  public video: any;
  public remoteVideo: any;
  public videoStream: any;
  public videoWidth: number = 320;
  public videoHeight: number = 180;
  public screen: any;
  public remoteScreen: any;
  public screenStream: any;
  public screenWidth: number = 400;
  public screenHeight: number = 300;
  public file: File;
  public fileReader: FileReader;
  public sendFileName: any = 'Choose file';
  public sendProgressMin: number = 0;
  public sendProgressMax: number = 0;
  public sendProgressValue: any = 0;
  public receivedFileName: any;
  public receivedFileSize: any;
  public receivedFileType: any;
  public receivedProgressMin: number = 0;
  public receivedProgressMax: number = 0;
  public receivedProgressValue: any = 0;
  public receiveBuffer = [];
  public receivedBlob: Blob;
  public enableDownload: boolean = false;
  public userdata: any = '';
  public doctor: any = '';
  public patientName: any = '';
  public patientId: any = '';
  public baseurl: any = '';
  public telenodo: any = [];
  public media: any = '';
  public myIceServers: any = '';
  public vote: any = '';
  voteForm: FormGroup;
  public loading = false;
 
  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild('audioElement', {static: false}) audioElement: ElementRef;
  @ViewChild('remoteAudioElement', {static: false}) remoteAudioElement: ElementRef;
  @ViewChild('videoElement', {static: false}) videoElement: ElementRef;
  @ViewChild('remoteVideoElement', {static: false}) remoteVideoElement: ElementRef;
  @ViewChild('screenElement', {static: false}) screenElement: ElementRef;
  @ViewChild('remoteScreenElement', {static: false}) remoteScreenElement: ElementRef;
	
  constructor(private router: Router, private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService, private formBuilder: FormBuilder, private resourceService:ResourceService, private socketservice: SocketserviceService, private mediastream: MediastreamService) {
  
  }
  
  ngOnInit(): void {
    this.loading = true;
	console.log('Adapter : ', adapter);
	this.userdata = JSON.parse(sessionStorage.getItem('uspatws'));
	this.myIceServers = JSON.parse(localStorage.getItem('ics'));
	this.doctor = JSON.parse(sessionStorage.getItem('doctor'));
	this.patientName = sessionStorage.getItem('username');
	this.patientId = sessionStorage.getItem('userId');
	this.baseurl = environment.baseUrl+'public/';
	this.initvoteForm();
	this.checkSession();
	this.getTelenodo();
	
    if (this.socketservice) {
	  
	  this.subscription = this.socketservice.getControlPatSocketId(this.userdata).subscribe((userdata: any) => {
        this.serverStatus = true;
		
		//this.userId = this.userdata.userId;
		this.fromUserId = this.userdata.uniqueId;
		this.socketId = this.userdata.sktId;
		
		this.subscription.unsubscribe();
		
		//console.log ('userId :', this.userId);
		console.log ('fromUserId :', this.fromUserId);
		console.log ('socketId :', this.socketId);
      });
	  
      this.socketservice.getDoctors().subscribe((doctors: any) => {
		this.doctors = doctors;
		this.doctors.forEach(doctor => {
			if(this.doctor.id == doctor.docId)
			{
				this.toUserId = doctor.docuniqueId;
				this.toSocketId = doctor.docsocketId;
			}
		});
		console.log ('toUserId :', this.toUserId);
		console.log ('toSocketId :', this.toSocketId);
      });
	  
	  /* this.socketservice.getUsers().subscribe((users: any) => {
		this.users = users;
		this.users.forEach(user => {
			if(this.doctor.id == user.userId)
			{
				this.toUserId = user.uniqueId;
				this.toSocketId = user.socketId;
			}
		});
      }); */
	  
      window.RTCPeerConnection = this.getRTCPeerConnection();
	  window.RTCSessionDescription = this.getRTCSessionDescription();
      window.RTCIceCandidate = this.getRTCIceCandidate();
      this.browser.getUserMedia = this.getAllUserMedia();
	  
	  //initiate peerConnection
	  this.peerConnection = new RTCPeerConnection({ "iceServers": this.myIceServers });
	  console.log ('myIceServers :', this.myIceServers);
      console.log('RTCPeerConnection : ', this.peerConnection);
	  
	  // enable media
	  this.enableMedia();
	  
	  // automatic connect
	  //this.enableCall();
     
      
	  this.peerConnection.onicecandidate = (candidate: RTCIceCandidate) => {
		console.log('ICE Candidate : ', candidate);
		
		// socket sendIceCandidate
        this.socketservice.sendIceCandidate ({
          from : this.fromUserId,
          to : this.toUserId,
          type : candidate.type,
          candidate : candidate.candidate
        });
		
      };
	  
      this.peerConnection.oniceconnectionstatechange = (connection: RTCIceConnectionState) => {
        console.log('ICE Connection : ', connection);
        console.log('ICE Connection State : ', this.peerConnection.iceConnectionState);
      };
	  
      this.peerConnection.ondatachannel = (event: any) => {
        console.log("Data Channel Attached");
        const onChannelReady = () => {
          this.dataChannel = event.channel;
        };
        if (event.channel.readyState !== 'open') {
          event.channel.onopen = onChannelReady;
        } else {
          onChannelReady();
        }
      };
	  
      //this.peerConnection.ontrack = (event: any) => {
      this.peerConnection.ontrack = ({ streams: [stream] }) => {
        
		console.log("Data On Track Stream");
		
		if (this.audioEnable) {
          this.remoteAudio = this.remoteAudioElement.nativeElement;
          console.log('Audio Track Received');
          try {
            //this.remoteAudio.srcObject = event.streams[0];
            this.remoteAudio.srcObject = stream;
          } catch(err) {
            //this.remoteAudio.src = window.URL.createObjectURL(event.streams[0]);
            this.remoteAudio.src = window.URL.createObjectURL(stream);
          }
          setTimeout(() => {
            this.remoteAudio.play();
			this.loading = false;
          }, 500);
        } 
		
		if (this.videoEnable) {
          this.remoteVideo = this.remoteVideoElement.nativeElement;
          console.log('Video Track Received');
          try {
            //this.remoteVideo.srcObject = event.streams[0];
            this.remoteVideo.srcObject = stream;
          } catch(err) {
            //this.remoteVideo.src = window.URL.createObjectURL(event.streams[0]);
            this.remoteVideo.src = window.URL.createObjectURL(stream);
          }
		  setTimeout(() => {
            this.remoteVideo.play();
			this.loading = false;
          }, 500);
        } 
		
      };
	  
	  // socket receiveOffer
      this.socketservice.receiveOffer().subscribe(async (offer: RTCSessionDescription) => {
        
		await this.peerConnection.setRemoteDescription({type: 'offer', sdp: offer.sdp});
        console.log('Offer Received : ', offer);
		this.toUserId = offer['from'];
		
        // socket createAnswer()
		this.peerConnection.createAnswer().then(async (answer: RTCSessionDescription) => {
          console.log('Answer Created : ', answer);
          await this.peerConnection.setLocalDescription(answer);
          this.socketservice.sendAnswer({
            from : this.fromUserId,
            to : this.toUserId,
            type : answer.type,
            sdp : answer.sdp
          });
        });
		
		// confirm new connection
		if( typeof(sessionStorage.getItem('wbrtconnect')) == 'undefined' )
		{
			sessionStorage.setItem('wbrtconnect', '1');
			// confirm connection between users
			if (offer['media'] == 'audio')
				this.callAudioUser();
			
			else if (offer['media'] == 'video')
				this.callVideoUser();
			
			/* if(offer['media'] == 'text') {
				//this.enableText();
				this.callTextUser();
			}
			else if (offer['media'] == 'file') {
				//this.enableFile();
				this.callFileUser();
			} */
			
		}
      });
	  
	  
	  // socket receiveAnswer()
      this.socketservice.receiveAnswer().subscribe(async (answer: RTCSessionDescription) => {
        console.log('Answer Received : ', answer);
        await this.peerConnection.setRemoteDescription({type: 'answer', sdp: answer.sdp});
      });
	  
	  // socket receiveIceCandidate()
      this.socketservice.receiveIceCandidate().subscribe((candidate: RTCIceCandidate) => {
        if (candidate.candidate) {
          console.log('ICE Candidate Received : ', candidate);
          this.peerConnection.addIceCandidate(candidate.candidate);
        }
      });
	  
	  // socket receiveMedia()
      this.socketservice.receiveMedia().subscribe(async (media: any) => {
        console.log('Media Received : ', media);
		if( typeof(sessionStorage.getItem('wbrtcmedia')) == 'undefined' )
		{
			sessionStorage.setItem('wbrtcmedia', '1');
			
			// update media type
				if ( (media['media'] == 'audio') && (!this.audioEnable) )
					this.callAudioUser();
				else if ( (media['media'] == 'video') && (!this.videoEnable) ) 
					this.callVideoUser();
				
				/* if( (media['media'] == 'text') && (!this.textEnable) ) {
					//this.enableText();
					this.callTextUser();
				}
				else if ( (media['media'] == 'file') && (!this.fileEnable) ) {
					//this.enableFile();
					this.callFileUser();
				} */
		}
      });
	  
	  // socket receiveAlert()
      this.socketservice.receiveAlert().subscribe(async (alerts: any) => {
        console.log('Alert Received : ', alerts);
        
		if( typeof(sessionStorage.getItem('wbrtcalert')) == 'undefined' )
		{
			// get infos for this alert
			if(alerts['media'] == 'off') 
				this.stopDialog();
			else 
				this.getAlertUser();
		}
      });
	  
      this.socketservice.receiveFile().subscribe(async (file: any) => {
        console.log('File Received : ', file);
        if (file['type'] == 'file') {
          this.receivedFileName = file['fileName'];
          this.receivedFileSize = file['fileSize'] + ' bytes';
          this.receivedFileType = file['fileType'];
          this.receivedProgressValue = 0;
        } else if (file['type'] == 'file-status') {
          this.receivedProgressValue = file['progressValue'];
        } else if (file['type'] == 'file-complete') {
          this.receivedBlob = new Blob(this.receiveBuffer, { type: this.receivedFileType });
          this.enableDownload = true;
        }
      });
    } else {
      this.serverStatus = false;
    }
  }
  
  ngAfterViewInit(){
    //initiate connection between users
	// this.connect();
  }
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
	this.disconnect();
  }
  
  public initvoteForm() {
    this.voteForm = this.formBuilder.group({
      vote: ['', Validators.required]
    });
  }
  
  public get e() { return this.voteForm.controls; }
  
  public async voteTeleconsultation() {
    if (this.voteForm.invalid) {
      return;
    }
	// get value note of this vote
	this.vote = this.voteForm.value['vote'];
	
	// validate rdv and update
    this.resourceService.getResources('rdv/validate/'+ this.doctor.idrdv +'/'+ this.vote).subscribe((data:any) => {
        this.resetForm(this.voteForm);
        this.closemodal.nativeElement.click();
		
        //send alert infos
		let info:any = 'off';
		this.sendAlert(info);
		
		//disconnect
		this.disconnect();
		
		this.toastr.success('Nous vous remercions pour cette consultation  !','E-Pocrate Medical');
		this.router.navigate(["/patient/dashboard"]);
    }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('Error for update data.', err);
    });
	
  }
  
  public resetForm(form){
    form.reset();
  }
  
  public checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }
  
  public getTelenodo() { 
    this.resourceService.getResource('rdv/all/telenodo', this.patientId).subscribe(data=>{
		this.telenodo = data;
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  }
  
  public getRTCPeerConnection() {
    return window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
  }

  public getRTCSessionDescription() {
    return window.RTCSessionDescription ||
      window.mozRTCSessionDescription ||
      window.webkitRTCSessionDescription;
  }

  public getRTCIceCandidate() {
    return window.RTCIceCandidate ||
      window.mozRTCIceCandidate ||
      window.webkitRTCIceCandidate;
  }

  public getAllUserMedia() {
    return this.browser.getUserMedia ||
      this.browser.webkitGetUserMedia ||
      this.browser.mozGetUserMedia ||
      this.browser.msGetUserMedia;
  }

  public getAllUserMediaScreen() {
    if (this.browser.getDisplayMedia) {
      return this.browser.getDisplayMedia({video: true});
    } else if (this.browser.mediaDevices.getDisplayMedia) {
      return this.browser.mediaDevices.getDisplayMedia({video: true});
    } else {
      return this.browser.mediaDevices.getUserMedia({video: {mediaSource: 'screen'}});
    }
  }
  
  public sendAlert(media) {
	
	if (media == 'off'){
		if( typeof(sessionStorage.getItem('wbrtcalert')) == 'undefined' )
		{
			sessionStorage.setItem('wbrtcalert', '1');
			//send socket new alert
			this.socketservice.sendAlert({
				from : this.fromUserId,
				to : this.toUserId,
				infos : media
			});
		}
	}
	else{
		//send socket new alert
		this.socketservice.sendAlert({
			from : this.fromUserId,
			to : this.toUserId,
			infos : media
		});
	}
  }
  
  public  stopDialog() {
    this.toastr.success('Votre medecin a cloturé la consultation, cliquez sur le bouton ci-dessous pour valider votre téléconsultation.','E-Pocrate Medical');
	event.preventDefault();
    //this.c.confirm('Consultation Terminée !', 'Votre medecin a cloturé la consultation', 'Valider ?').then((confirmed) => {
    this.c.confirm('Votre medecin a cloturé la consultation', 'Cliquez sur le bouton ci-dessous pour valider votre téléconsultation.', 'Valider ?').then((confirmed) => {
      if (confirmed) {
		/* //save the vote quality for this consultation 
		this.voteTeleconsultation(); 
        
		//disconnect
		this.disconnect();
		
		this.toastr.success('Nous vous remercions pour cette consultation  !','E-Pocrate Medical');
		//this.router.navigate(["/patient/view-teleconsultation-patient"]); */
      }
      else{
		this.toastr.warning('Vous pouvez continuer en cliquant sur le bouton de votre choix !');
	  }
    });
  }
  
  
  public  stopDialogUser() {
    event.preventDefault();
    this.c.confirm('Consultation interrompu !', 'Avez-vous terminé votre consultation ?', 'Oui Terminé ?').then((confirmed) => {
      if (confirmed) {
		//save the vote quality for this consultation 
		this.voteTeleconsultation(); 
        
		//send alert infos
		let info:any = 'off';
		this.sendAlert(info);
		
		//disconnect
		this.disconnect();
		
		this.toastr.success('Nous vous remercions pour cette consultation  !','E-Pocrate Medical');
		// this.router.navigate(["/patient/teleconsultation"]);
      }
      else{
		this.toastr.warning('Vous pouvez continuer en cliquant sur le bouton de votre choix !');
	  }
    });
  }
  
  public getAlertUser() {
    event.preventDefault();
    this.c.confirm('Alerte', 'Votre patient a interrompu la communication', 'Veuillez patienter...').then((confirmed) => {
      if (confirmed) {
          this.toastr.success('Merci de votre patience','E-Pocrate Medical');
      }else{
		this.toastr.warning('Terminer la consultation ?');
		this.stopDialogUser();
	  }
	});
  }
  
  public  callTextUser() {
    event.preventDefault();
    this.c.confirm('Text Message', 'Votre medecin demande à vous consulter...', 'Repondre ?').then((confirmed) => {
      if (confirmed) {
		  //confirm connection between users
		  this.enableText();
          this.toastr.success('Connexion réussie !','Connecté');
		  // A INSERER DANS LES PAGES AUTRES QUE LA PRESENTE
		  // this.router.navigate(["/patient/view-teleconsultation-patient"]);
      }else{
		  this.toastr.warning('Demande refusé !');
	  }
	  
	});
  }
  
  public  callFileUser() {
    event.preventDefault();
    this.c.confirm('Attach File', 'Vous avez une demande de fichier en cours...', 'Repondre ?').then((confirmed) => {
      if (confirmed) {
        
		  //confirm connection between users
		  this.enableAudio();
          this.toastr.success('Connexion réussie !','Connecté');
		   // A INSERER DANS LES PAGES AUTRES QUE LA PRESENTE
		  // this.router.navigate(["/patient/view-teleconsultation-patient"]);  
      }else{
		  this.toastr.warning('Demande refusé !');
	  }
	});
  }
  
  public  callAudioUser() {
    event.preventDefault();
    this.c.confirm('Appel Entrant', 'Vous avez un appel audio en cours...', 'Repondre ?').then((confirmed) => {
      if (confirmed) {
		  //confirm connection between users
		  this.enableAudio();
          this.toastr.success('Connexion réussie !','Connecté');
		   // A INSERER DANS LES PAGES AUTRES QUE LA PRESENTE
		  // this.router.navigate(["/patient/view-teleconsultation-patient"]); 
      }else{
		  this.toastr.warning('Appel refusé !');
	  }
	});
  }
  
   public  callVideoUser() {
    event.preventDefault();
    this.c.confirm('Appel Entrant', 'Vous avez un appel video en cours...', 'Repondre ?').then((confirmed) => {
      if (confirmed) {
		  //confirm connection between users
		  this.enableVideo();
          this.toastr.success('Connexion réussie !','Connecté');
		  // A INSERER DANS LES PAGES AUTRES QUE LA PRESENTE
		  // this.router.navigate(["/patient/view-teleconsultation-patient"]);
      }else{
		  this.toastr.warning('Appel refusé !');
	  }
	});
  }
  
  public async enableMedia() {
	
	this.videoEnable = true;
	this.textEnable = true;
	this.fileEnable = true;
	this.audioEnable = false;
	this.callEnable = false;
	this.btnvideoEnable = true;
	this.btntextEnable = true;
	this.btnfileEnable = false;
	this.btnaudioEnable = false;
	this.btncallEnable = false;
	this.btnendcallEnable = false;
	
	$('#video').removeAttr('style');
	$('#text').removeAttr('style');
	$('#attachfile').attr('style', 'display:none');
	$('#audio').attr('style', 'display:none');
	$('#btntextEnable').attr('style', 'display:none');
	$('#btnfileEnable').attr('style', 'display:none');
	$('#btnaudioEnable').attr('style', 'display:none');
	$('#btnvideoEnable').attr('style', 'display:none');
	$('#btncallEnable').attr('style', 'display:none');
	$('#btnendcallEnable').removeAttr('style');
	
	// get video flux and add to track media
	setTimeout(() => {
		this.video = this.videoElement.nativeElement;
		// let constraints = { audio: true, video: { minFrameRate: 60, width: 400, height: 300 } };
		let constraints = { 
			audio: { echoCancellation: true, googEchoCancellation: true, googAutoGainControl: true, googNoiseReduction: true },
			video: { hd:true, minFrameRate: 60, minWidth: 420, minHeight: 236, googNoiseReduction: true } 
		};
		this.browser.mediaDevices.getUserMedia(constraints).then((stream: any) => {
			if(!stream.stop && stream.getTracks) {
			  stream.stop = function(){
				this.getTracks().forEach(function (track: any) {
				  track.stop();
				});
			  };
			}
			this.videoStream = stream;
			this.videoTrack = stream.getVideoTracks();
			this.audioTrack = stream.getAudioTracks();
			
			stream.getTracks().forEach((track: any) => {
			  console.log("Data Add Track Stream");
			  this.peerConnection.addTrack(track, stream);
			});
			
			if (this.videoTrack) {
			  console.log('Using video device: ' + this.videoTrack[0].label);
			}
			if (this.audioTrack) {
			  console.log('Using audio device: ' + this.audioTrack[0].label);
			}
			
			try {
			  this.video.autoplay = true;
			  this.video.muted = true;
			  this.video.srcObject = this.videoStream;
			} catch(err) {
			  this.video.autoplay = true;
			  this.video.muted = true;
			  this.video.src = window.URL.createObjectURL(this.videoStream);
			}
			
			setTimeout(() => {
			  this.video.play();
			  // automatic connect
			  this.enableCall();
			}, 1000);
		});
	}, 1500);
	

	// get audio flux and add to track media
	/* setTimeout(() => {
		  this.audio = this.audioElement.nativeElement;
		  // let constraints = { audio: true };
		  let constraints = { audio: { echoCancellation: true, googEchoCancellation: true, googAutoGainControl: true, googNoiseReduction: true } };
		  this.browser.mediaDevices.getUserMedia(constraints).then((stream: any) => {
			if(!stream.stop && stream.getTracks) {
			  stream.stop = function(){
				this.getTracks().forEach(function (track: any) {
				  track.stop();
				});
			  };
			}
			this.audioStream = stream;
			this.audioTrack = stream.getAudioTracks();
			
			stream.getTracks().forEach((track: any) => {
			  this.peerConnection.addTrack(track, stream);
			});
			
			if (this.audioTrack) {
			  console.log('Using audio device: ' + this.audioTrack[0].label);
			}
			try {
			  this.audio.srcObject = this.audioStream;
			} catch(err) {
			  this.audio.src = window.URL.createObjectURL(this.audioStream);
			}
			
			setTimeout(() => {
			  this.audio.play();
			}, 500);
			
		  });
		}, 1000); */
  }
  
  public enableCall() {
	if (!this.callEnable)
	{
		$('#btntextEnable').removeAttr('style');
		$('#btnfileEnable').removeAttr('style');
		$('#btnaudioEnable').removeAttr('style');
		$('#btnvideoEnable').removeAttr('style');
		this.callEnable = true;
		this.btncallEnable = false;
		this.btnendcallEnable = true;
		this.media = 'video';
		setTimeout(() => {
		  // call
		  this.connect(this.media);
		}, 4000);
	}
	else
	{
		// confirm stop dialog
		this.stopDialogUser();
	
		// send alert for this stop communication
		this.media = 'video'; 
		this.sendAlert(this.media);
	}
  }
  
  public disableCall() {
	// confirm stop dialog
	this.stopDialogUser();
	
	// send alert for this stop communication
	this.media = 'video'; 
	this.sendAlert(this.media);  
  }
  
  
  public enableVideo() {
	if (!this.btnvideoEnable) {
			
		$('#video').removeAttr('style');
		$('#audio').attr('style', 'display:none');
		this.btnvideoEnable = true;
		this.btnaudioEnable = false;
			
		this.media = 'video';
		this.connect(this.media);
		
		/* try {
			this.video.play();
		} catch(e) { }

		try {
			this.stopAudio();
		} catch(e) { } */
		
		//$('#btntextEnable').removeAttr('style');
		//$('#btnfileEnable').removeAttr('style');
		//this.videoEnable = true;
		//this.audioEnable = false;
	}
  }
  
  
  public enableAudio() {
	if (!this.btnaudioEnable) {
			
		$('#video').attr('style', 'display:none');
		$('#audio').removeAttr('style');
		this.btnvideoEnable = false;
		this.btnaudioEnable = true;
			
		this.media = 'audio';
		this.connect(this.media);
		
		/* try {
			this.audio.play();
		} catch(e) { }

		try {
			this.stopVideo();
		} catch(e) { } */
		
		//$('#btntextEnable').removeAttr('style');
		//$('#btnfileEnable').removeAttr('style');
		//this.videoEnable = true;
		//this.audioEnable = false;
		
	}
  }
  
  
  public enableText() {
	if(!this.btntextEnable) 
	{
		
		this.btntextEnable = true;
		this.btnfileEnable = false;
		$('#text').removeAttr('style');
		$('#attachfile').attr('style', 'display:none');
		
		this.media = 'text';
		this.connect(this.media);
		
		//this.textEnable = true;
		//this.fileEnable = false;
		//$('#btntextEnable').removeAttr('style');
		//$('#btnfileEnable').removeAttr('style');
	}
  }
  
  
  public enableFile() {
	if(!this.btnfileEnable) 
	{
		this.btntextEnable = false;
		this.btnfileEnable = true;
		$('#text').attr('style', 'display:none');
		$('#attachfile').removeAttr('style');
		this.media = 'file';
		this.connect(this.media);
		
		//this.textEnable = false;
		//this.fileEnable = true;
	}
  }
	
	
  /* public enableText() {
	if(!this.textEnable)
	{
		try {
		  this.stopAudio();
		} catch(e) { }
		
		$('#attachfile').attr('style', 'display:none');
		$('#audio').attr('style', 'display:none');
		$('#video').attr('style', 'display:none');
		$('#text').removeAttr('style');
		this.textEnable = true;
		this.fileEnable = false;
		this.audioEnable = false;
		this.videoEnable = false;
		
		this.media = 'text';
		this.connect(this.media);
	}
	else if((this.textEnable) && (!this.connected)){
		
		$('#text').removeAttr('style');
		this.media = 'text';
		this.connect(this.media);
	}
	else
	{
		// confirm stop dialog
		this.stopDialogUser();
		
		// disabled values
		$('#text').attr('style', 'display:none');
		this.textEnable = false;
		
		// send alert for this stop communication
		this.media = 'text';
		this.sendAlert(this.media);
	}
  } */

  /* public enableFile() {
    if(!this.fileEnable)
	{
		try {
		  this.stopAudio();
		} catch(e) { }
		try {
		  this.stopVideo();
		} catch(e) { }
		
		$('#text').attr('style', 'display:none');
		$('#audio').attr('style', 'display:none');
		$('#video').attr('style', 'display:none');
		$('#attachfile').removeAttr('style');
		this.textEnable = false;
		this.fileEnable = true;
		this.audioEnable = false;
		this.videoEnable = false;
		
		this.media = 'file';
		this.connect(this.media);
	}
	else
	{
		// confirm stop dialog
		this.stopDialogUser();
		
		// disabled values
		$('#attachfile').attr('style', 'display:none');
		this.fileEnable = false;
		
		// send alert for this stop communication
		this.media = 'file';
		this.sendAlert(this.media);
	}
  } */

  public handleFileInput(files: FileList) {
    if (files[0]) {
      this.file = files[0];
      this.sendFileName = this.file['name'];
      console.log(this.file);
      this.sendProgressMin = 0;
      this.sendProgressMax = this.file.size;
    } else {
      this.sendFileName = 'Choose file';
    }
  }

  public sendFile() {
    let oldSendProgressValue = 0;
    this.socketservice.sendFile({
      from : this.fromUserId,
      to : this.toUserId,
      type: 'file',
      fileName : this.file['name'],
      fileSize : this.file['size'],
      fileType: this.file['type']
    });
    const chunkSize = 16384;
    let offset = 0;
    this.fileReader = new FileReader();
    this.fileReader.onload = (event: any) => {
      this.dataChannel.send(event.target.result);
      offset += event.target.result.byteLength;
      this.sendProgressValue = ((offset*100)/this.sendProgressMax).toFixed(1);
      if (this.sendProgressValue !== oldSendProgressValue) {
        this.socketservice.sendFile({
          from : this.fromUserId,
          to : this.toUserId,
          type: 'file-status',
          progressValue : this.sendProgressValue
        });
        oldSendProgressValue = this.sendProgressValue;
      }
      if (offset < this.file.size) {
        this.readSlice(offset, chunkSize);
      }
      if (this.sendProgressValue == 100.0) {
        this.socketservice.sendFile({
          from : this.fromUserId,
          to : this.toUserId,
          type: 'file-complete'
        });
      }
    }
    this.readSlice(offset, chunkSize);
  }

  public readSlice(offset: any, chunkSize: any) {
    const slice = this.file.slice(offset, offset + chunkSize);
    this.fileReader.readAsArrayBuffer(slice);
  }

  public downloadFile() {
    saveAs(this.receivedBlob, this.receivedFileName);
  }

  /* public enableAudio() {
	if (!this.audioEnable)
	{
		try {
		  this.stopVideo();
		} catch(e) { }
		
		$('#text').attr('style', 'display:none');
		$('#attachfile').attr('style', 'display:none');
		$('#video').attr('style', 'display:none');
		$('#audio').removeAttr('style');
		
		this.textEnable = false;
		this.fileEnable = false;
		this.audioEnable = true;
		this.videoEnable = false;
		
		setTimeout(() => {
		  this.audio = this.audioElement.nativeElement;
		  // let constraints = { audio: true };
		  let constraints = { audio: { echoCancellation: true, googEchoCancellation: true, googAutoGainControl: true, googNoiseReduction: true } };
		  this.browser.mediaDevices.getUserMedia(constraints).then((stream: any) => {
			if(!stream.stop && stream.getTracks) {
			  stream.stop = function(){
				this.getTracks().forEach(function (track: any) {
				  track.stop();
				});
			  };
			}
			this.audioStream = stream;
			this.audioTrack = stream.getAudioTracks();
			if (this.audioTrack) {
			  console.log('Using audio device: ' + this.audioTrack[0].label);
			}
			try {
			  this.audio.srcObject = this.audioStream;
			} catch(err) {
			  this.audio.src = window.URL.createObjectURL(this.audioStream);
			}
			stream.getTracks().forEach((track: any) => {
			  this.peerConnection.addTrack(track, stream);
			});
			setTimeout(() => {
			  this.audio.play();
			}, 500);
			
			this.media = 'audio';
			this.connect(this.media);
		
		  });
		}, 1000);
		
		
	}
	else
	{
		// confirm stop dialog
		this.stopDialogUser();
		
		// disabled values
		try {
		  this.stopAudio();
		} catch(e) { }
		
		$('#audio').attr('style', 'display:none');
		this.audioEnable = false;
		
		// send alert for this stop communication
		this.media = 'audio';
		this.sendAlert(this.media);
	}
  } */

  /* public enableVideo() {
	if (!this.videoEnable)
	{
		try {
		  this.stopAudio();
		} catch(e) { }
		
		$('#text').attr('style', 'display:none');
		$('#attachfile').attr('style', 'display:none');
		$('#audio').attr('style', 'display:none');
		$('#video').removeAttr('style');
		this.textEnable = false;
		this.fileEnable = false;
		this.audioEnable = false;
		this.videoEnable = true;
		setTimeout(() => {
		  this.video = this.videoElement.nativeElement;
		  // let constraints = { audio: true, video: { minFrameRate: 60, width: 400, height: 300 } };
		  let constraints = { 
				audio: { echoCancellation: true, googEchoCancellation: true, googAutoGainControl: true, googNoiseReduction: true },
				video: { hd:true, minFrameRate: 60, minWidth: 420, minHeight: 236, googNoiseReduction: true } 
		  };
		  this.browser.mediaDevices.getUserMedia(constraints).then((stream: any) => {
			if(!stream.stop && stream.getTracks) {
			  stream.stop = function(){
				this.getTracks().forEach(function (track: any) {
				  track.stop();
				});
			  };
			}
			this.videoStream = stream;
			this.videoTrack = stream.getVideoTracks();
			this.audioTrack = stream.getAudioTracks();
			if (this.videoTrack) {
			  console.log('Using video device: ' + this.videoTrack[0].label);
			}
			if (this.audioTrack) {
			  console.log('Using audio device: ' + this.audioTrack[0].label);
			}
			try {
			  this.video.srcObject = this.videoStream;
			} catch(err) {
			  this.video.src = window.URL.createObjectURL(this.videoStream);
			}
			stream.getTracks().forEach((track: any) => {
			  console.log("Data Add Track Stream");
			  this.peerConnection.addTrack(track, stream);
			});
			setTimeout(() => {
			  this.video.play();
			}, 500);
			
			this.media = 'video';
			this.connect(this.media);
			
		  });
		}, 1000);
		
		
	}
	else
	{
		// confirm stop dialog
		this.stopDialogUser();
		
		// disabled values
		try {
		  this.stopVideo();
		} catch(e) { }
		
		$('#video').attr('style', 'display:none');
		this.videoEnable = false;
		
		// send alert for this stop communication
		this.media = 'video';
		this.sendAlert(this.media);
	}
  } */


  public stopAudio() {
    this.audioStream.stop();
  }

  public stopVideo() {
    this.videoStream.stop();
  }

  /* public stopScreen() {
    this.screenStream.stop();
  } */

  public async connect(media) {
	
	if(!this.connected){
		
		//new connection
		if( typeof(sessionStorage.getItem('wbrtconnect')) == 'undefined' )
		{
			sessionStorage.setItem('wbrtconnect', '1');
			this.connected = true;
			
			this.dataChannel = await this.peerConnection.createDataChannel('datachannel');
			
			if (this.fileEnable) {
			  this.dataChannel.binaryType = 'arraybuffer';
			}
			
			this.dataChannel.onerror = (error: any) => {
			  console.log("Data Channel Error:", error);
			};
			
			this.dataChannel.onmessage = (event: any) => {
			  if (this.textEnable) {
				console.log("Got Data Channel Message:", JSON.parse(event.data));
				this.messages.push(JSON.parse(event.data));
			  } else if (this.fileEnable) {
				this.receiveBuffer.push(event.data);
			  }
			};
			
			this.dataChannel.onopen = () => {
			  console.log("Data Channel Opened");
			};
			
			this.dataChannel.onclose = () => {
			  console.log("The Data Channel is Closed");
			};
			
			this.offer = this.peerConnection.createOffer({
			  offerToReceiveAudio: 1,
			  offerToReceiveVideo: 1,
			  voiceActivityDetection: 1
			}).then(async (offer: RTCSessionDescription) => {
			  console.log('Offer Created : ', offer);
			  await this.peerConnection.setLocalDescription(offer);
			  this.socketservice.sendOffer({
				from : this.fromUserId,
				to : this.toUserId,
				media : media,
				type : offer.type,
				sdp : offer.sdp
			  });
			});
		}
		else //confirm connection
		{
			this.connected = true;
			
			this.dataChannel = await this.peerConnection.createDataChannel('datachannel');
			if (this.fileEnable) {
			  this.dataChannel.binaryType = 'arraybuffer';
			}
			this.dataChannel.onerror = (error: any) => {
			  console.log("Data Channel Error:", error);
			};
			this.dataChannel.onmessage = (event: any) => {
			  if (this.textEnable) {
				console.log("Got Data Channel Message:", JSON.parse(event.data));
				this.messages.push(JSON.parse(event.data));
			  } else if (this.fileEnable) {
				this.receiveBuffer.push(event.data);
			  }
			};
			this.dataChannel.onopen = () => {
			  console.log("Data Channel Opened");
			};
			this.dataChannel.onclose = () => {
			  console.log("The Data Channel is Closed");
			};
			this.offer = this.peerConnection.createOffer({
			  offerToReceiveAudio: 1,
			  offerToReceiveVideo: 1,
			  voiceActivityDetection: 1
			}).then(async (offer: RTCSessionDescription) => {
			  console.log('Offer Created : ', offer);
			  await this.peerConnection.setLocalDescription(offer);
			  this.socketservice.sendOffer({
				from : this.fromUserId,
				to : this.toUserId,
				type : offer.type,
				sdp : offer.sdp
			  });
			});
		}
	}else{
		// change media
		if( typeof(sessionStorage.getItem('wbrtcmedia')) == 'undefined' )
		{
			sessionStorage.setItem('wbrtcmedia', '1');
		
			//send socket new media
			this.socketservice.sendMedia({
				from : this.fromUserId,
				to : this.toUserId,
				media : media
			});
		}
	}
  }

  public sendMessage() {
	let currentdate:any = new Date();
    this.dataChannel.send(JSON.stringify({userId: this.fromUserId, data: this.message}));
    this.messages.push(JSON.parse(JSON.stringify({userId: this.fromUserId, data: this.message})));
    this.message = '';
  }

  public disconnect() {
    try {
      this.stopAudio();
    } catch(e) { }
    try {
      this.stopVideo();
    } catch(e) { }
	
   /* try {
      this.stopScreen();
    } catch(e) { } */
	
	$('#text').attr('style', 'display:none');
	$('#audio').attr('style', 'display:none');
	$('#video').attr('style', 'display:none');
	$('#attachfile').attr('style', 'display:none');
	
	this.textEnable = false;
	this.fileEnable = false;
	this.audioEnable = false;
	this.videoEnable = false;
    this.connected = false;
    this.toUserId = '';
    this.enableDownload = false;
    this.sendProgressValue = 0;
    this.receivedProgressValue = 0;
    this.sendFileName = '';
    this.receivedFileName = '';
    this.receivedFileSize = '';
    this.receivedFileType = '';
	
	//destroy sessions
	sessionStorage.removeItem('wbrtconnect');
	sessionStorage.removeItem('wbrtcmedia');
	sessionStorage.removeItem('wbrtcalert');
	localStorage.removeItem('ics');
  }
  
  
  public dialog(item){
	// disconnect current dialog
	this.disconnect();
	//get data socket of this doctor
	this.socketservice.getDoctors().subscribe((doctors: any) => {
		this.doctors = doctors;
		this.doctors.forEach(doctor => {
			if(item.disponibility.doctor.id == doctor.docId)
			{
				this.toUserId = doctor.docuniqueId;
				this.toSocketId = doctor.docsocketId;
				
				// connect new dialog
				// this.connect();
				this.toastr.success('Pour communiquer avec votre medecin, vous devrez cliquez sur le bouton de votre choix.','E-Pocrate Medical');
			}
		});
		console.log ('toUserId :', this.toUserId);
		console.log ('toSocketId :', this.toSocketId);
    });
 }
 
 
 /* public enableScreen() {
    try {
      this.stopAudio();
    } catch(e) { }
    try {
      this.stopVideo();
    } catch(e) { }
    this.textEnable = false;
    this.fileEnable = false;
    this.audioEnable = false;
    this.videoEnable = false;
    this.screenEnable = true;
    setTimeout(() => {
      this.screen = this.screenElement.nativeElement;
      this.getAllUserMediaScreen().then((stream: any) => {
        if(!stream.stop && stream.getTracks) {
          stream.stop = function(){
            this.getTracks().forEach(function (track: any) {
              track.stop();
            });
          };
        }
        this.screenStream = stream;
        this.videoTrack = stream.getVideoTracks();
        if (this.videoTrack) {
          console.log('Using video device: ' + this.videoTrack[0].label);
        }
        try {
          this.screen.srcObject = this.screenStream;
        } catch(err) {
          this.screen.src = window.URL.createObjectURL(this.screenStream);
        }
        stream.getTracks().forEach((track: any) => {
          this.peerConnection.addTrack(track, stream);
        });
        setTimeout(() => {
          this.screen.play();
        }, 500);
      });
    }, 1000);
  } */

}




