import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'e-pocrate';

  constructor(
    private translateService: TranslateService
  ) {
  }
  ngOnInit() {

   
    let lang:any =navigator.language;
    if(lang){
      if (lang != "fr" && lang != "en") {
        this.translateService.setDefaultLang('en');
      }else {
        this.translateService.setDefaultLang(lang);
      }   
    }else{
      this.translateService.setDefaultLang('en');
    }
  
  }

}
