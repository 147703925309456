import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-doctor-show-message',
  templateUrl: './doctor-show-message.component.html',
  styleUrls: ['./doctor-show-message.component.css']
})
export class DoctorShowMessageComponent implements OnInit {

  message_id: any;
  message:any;
  userId: string;
  baseurl: string;
  angForm: FormGroup;
  messages: any;
  read: any;
  submitted: boolean;
  patient_id: any;
  
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private resourceService:ResourceService, private toastr: ToastrService) { 
    this.message={};
  }

  ngOnInit() {
   // this.checkSession();
    this.createForm();
    this.message_id = this.route.snapshot.params.id_message;
    this.patient_id = this.route.snapshot.params.idpat;
    this.read = this.route.snapshot.params.read;
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.getMessage();
  }

  createForm() {
    this.angForm = this.fb.group({
      doctor: ['', Validators.required],
      subject: ['', Validators.required ],
      message: ['', Validators.required ]
    });
  }

  get f() {
    return this.angForm.controls;
  }

  onSubmit() {
    
      let data={
        patient_id: this.patient_id,
        doctor_id: this.userId,
        message: this.angForm.value['message'],
        subject: this.angForm.value['subject']
      } 
     
    this.resourceService.saveResource('message/create_doc',data).subscribe(data=>{ 
      let res:any = data;
      if(res=='Done')
        this.toastr.success('Message envoye', 'Success');
        this.router.navigate(['/doctor/messages']);    
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('err', err);
    })

  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getMessage(){
    this.resourceService.getResources('message/doc/'+this.message_id+'/'+this.userId+'/'+this.read).subscribe(data=>{
      this.messages = data;  this.patient_id = this.messages.patient.id;
    },err=>{
      this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  }

}
