import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.css']
})
export class ValidateEmailComponent implements OnInit {
  email: string;
  message: string;
  url: any;
  

  constructor(private resourceService: ResourceService, private routes: ActivatedRoute , private toastr: ToastrService) { }

  ngOnInit() {
console.log(this.routes.snapshot.paramMap.get('email'))

      this.validateEmail(this.routes.snapshot.paramMap.get('email'));
  
  }

  validateEmail(email:any){
    this.resourceService.getResources_unsafe('validate_email/'+email).subscribe(data=>{
      this.toastr.success('Votre compte a été activé!', 'Success');
        this.message="votre compte a été activé";
        this.url=true;
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
        console.log(Errors(err));

        this.message = "votre compte n'a pas été activé merci de contacter le service client"
    })
  }

}
