import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Menus } from 'src/app/models/menus';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.css']
})
export class AddMenuComponent implements OnInit {

  newMenu: Menus;
  menuForm: FormGroup;
  submitted = false;
  menus: any;

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
    this.newMenu = {} as Menus;
  }

  ngOnInit() {
    this.checkSession();
    this.createForm();
    this.getMenus();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  createForm() {
    this.menuForm = this.fb.group({
      label: ['', Validators.required],
      menu_path: ['', Validators.required],
      menu_parent: ['']
    });
  }

  getMenus(){
    this.resourceService.getResources('allmenu').subscribe(data => {
      this.menus = data;
    } ,err => {
      console.log('erreur', err);
    })
  }

  onSubmit() {
    this.submitted = true;

    if (this.menuForm.invalid ) {
      return;
    } else {

      let formValue = this.menuForm.value;
      this.newMenu.id = null;
      this.newMenu.label = formValue['label'];
      this.newMenu.menu_path = formValue['menu_path'];
      this.newMenu.menu_id = formValue['menu_parent'];

      // Save datas create role in database
      this.resourceService.saveResource('createmenu', this.newMenu).subscribe((data) => {

        console.log('data', data);
        this.menuForm.reset();
        this.toastr.success('This menu as been create successfully!', 'Sucess');
        this.router.navigate(['/admin/confirm/action']);

      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
        console.log(Errors(err));
      });
    }
  }

  get f1() {
    return this.menuForm.controls;
  }

}
