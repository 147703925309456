import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../../settings/must-match.validator';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-password-doctor',
  templateUrl: './edit-password-doctor.component.html',
  styleUrls: ['./edit-password-doctor.component.css']
})
export class EditPasswordDoctorComponent implements OnInit {
  angForm: FormGroup;
  submitted = false;
  formData = new FormData();
  userId: any;
  loading = false;

  constructor(private router: Router, private fb: FormBuilder, private resourceService: ResourceService, private toastr: ToastrService) { }

  ngOnInit() {
   // this.checkSession();
    this.createForm();
    this.userId = sessionStorage.getItem('userId');
  }

 

  createForm() {
    this.angForm = this.fb.group({
      old_password: ['',[Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)] ]
    },
      {
        validator: MustMatch('new_password', 'confirm_password')
      }
      );
  }
  // Validators.pattern('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Z\\d!$%@#£€*?&]{6,}$')
  get f() {
    return this.angForm.controls; 
  }


  onSubmit(){
 
    this.submitted=true;

    if (this.angForm.invalid) {
      return;
    }else{
     this.loading=true;
      let datas = {
        password: sha256(this.angForm.value['old_password']),
        id: this.userId
      }

      this.resourceService.saveResource('doc/checkpassword',datas).subscribe((data:any)=>{
         // fonction qui  modifie le mot de l'utilisateur
        console.log('doc/checkpassword', data, datas);

        if (data=="ok"){
          let datanew = {
            password: sha256(this.angForm.value['confirm_password']),
            id: this.userId
          }
          this.resourceService.updateResource('doc/update', datanew).subscribe( data => {
          this.loading = false;
          this.toastr.success('Votre mot de passse a été modifié!', 'Sucess');
          console.log('pass',data);
        },
          err => {
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
            this.loading = false;
        });
        } else {
                 this.loading = false;
           this.toastr.error('Mot de passe incorrect!', 'Major Error');
        }
     
      },err=>{
          this.loading = false;
          this.toastr.error(Errors(err), 'Major Error');
        console.log("erreur",err);
      });
    }
  }
}
