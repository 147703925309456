import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PatientGuardService {


  constructor(private router:Router ) {
  }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
 
                if(!localStorage.getItem('token') || sessionStorage.getItem("_epocrate_type_")!="_epocrate_type_1" ){
                  this.router.navigate(["/login"]);
                  return false;
                }

                return true;
  }
}
