import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { SocketService } from 'src/app/services/socket.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.css']
})
export class NewMessageComponent implements OnInit,OnDestroy {

  public message: any;
  angForm: FormGroup;
  submitted = false;
  doctor = [];
  userId: any;
  connect: Observable<string[]>;
  currentDoc: string;
  showDoc: any;
  messages: any;
  doctors: any;

  baseUrlImage: any;
  id: any;
  doctor_select: any;
  name: any;
  reponse_message: any;
  notif=false;
  nbre_message=[];
  user_id:number;
  subs: any;
  notif_s: any;
  constructor(private socketService: SocketService,
    private serviceResource: ResourceService,
    private datepipe:DatePipe) {

      this.subs=  this.socketService.getMessage().subscribe((data: any) => {
        this.reponse_message=data.message;
        this.nbre_message=data.nbre_message;
        console.log('ts', data);
      if(this.user_id && parseInt(this.reponse_message.doctor_id)==this.user_id ){
        this.messages.push(data);

        // $('#message').append($('<li><div class="message-data"> <span class="message-data-name"><i class="zmdi zmdi-circle online"></i>'+this.doctor_select.surname+'</span> <span class="message-data-time">'+this.datepipe.transform(this.reponse_message.date_created,"short","UTC")+'</span> </div><div class="message my-message"> <p>' + this.reponse_message.message + '</p></div></li>'));
      }
     
    });

    this.notif_s= this.socketService.getPatientResponseNotif().subscribe((notif:any)=>{
      this.notif=notif.write;
      // console.log('notif',this.notif);
    })

  }

  ngOnDestroy(){
    this.subs.unsubscribe(); 
    this.notif_s.unsubscribe(); 
  }
  ngOnInit() {

    this.baseUrlImage = environment.baseUrlImage;
    $(".list_btn").on('click', function () {
      $("#plist").toggleClass("open");
    });
    this.userId = sessionStorage.getItem('userId');
    this.name = sessionStorage.getItem('username');
    this.getDoctors();
    
  }

  getDoctors() {
    this.serviceResource.getResources("doc/list_doctor/" + this.userId).subscribe((data: any) => {
      console.log('liste des doctors du patient', data);
      this.doctors = data;
    }, err => {
      console.log("erreur",err);
    });

  }



  getMessagesDoctor(id) {
    this.serviceResource.getResources("message/list_doc/" +id).subscribe((data: any) => {
      // console.log('liste des messages du docteur', data);
      this.messages = data;
    }, err => {
      console.log('errr', err);
    });
  }





  selectedDoctor(d) {

    this.doctor_select = d;
    this.user_id=parseInt(d.id) ;
    console.log(d);
    this.getMessagesDoctor(d.id);
    if(this.nbre_message[d.id]){
      this.nbre_message[d.id].nbre=0;
    }
   
  }
  send() {
  if(this.message){
    let  doctor={
      type:"patient",
      id: this.doctor_select.id,
      write:false
    }
    this.socketService.notif(doctor);
  }
    // console.log('testèèèèèèèèèèèèèèèèèèèèèèèèèèèèèèèèèè',this.message);
    let data = {
      doc_id: this.doctor_select.id,
      message: this.message,
      patient_id: this.userId,
      sender: "pat",

    }
    this.socketService.sendMessagePatient(data);
    
    let t = ' <li class="clearfix"><div class="message-data text-right"> <span class="message-data-time">'+this.datepipe.transform(new Date(),"short","UTC")+'</span> &nbsp;  <span class="message-data-name">' + this.name + '</span><i class="zmdi zmdi-circle me"></i> </div><div class="message other-message float-right">' + this.message + '</div></li>';
    $('#message').append($(t));
    this.message = "";
    
  }
  
    

  write(e){
    if(e.target.value.length==1){
      console.log('whrite');
    let  doctor={
        type:"patient",
        id: this.doctor_select.id,
        write:true
      }
      this.socketService.notif(doctor);
    }
    if(e.target.value.length==0){
      // console.log('whrite');
    let  doctor={
        type:"patient",
        id: this.doctor_select.id,
        write:false
      }
      this.socketService.notif(doctor);
    }

    if(e.keyCode==13){
      this.send();
  }
    
  }

}
