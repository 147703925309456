import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import { Errors } from 'src/app/settings/function_errors';
import {Router} from "@angular/router";
import {Doctor} from "src/app/models/doctor";
import { ResourceService } from 'src/app/services/resource.service';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import {ConfirmationDialogService} from 'src/app/ui/notifications/notification.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
declare var swal: any;


@Component({
  selector: 'app-list-doctor',
  templateUrl: './list-doctor.component.html',
  styleUrls: ['./list-doctor.component.css']
})
export class ListDoctorComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newDoctor: Doctor;
  doctorFormEdit: FormGroup;
  doctors: any = [];
  // formData = new FormData();
  fileData: File = null;
  fileDataCv: File = null;
  fileDataLv: File = null;
  nameFile:any;
  nameFileLV:any;
  specialities:any;
  baseurl: string;
  baseUrl: string;
  urls: any;
  url: string = '/doc/all/';
  public error;
  public success;
  pays: any;
  date: any;

  public detailsData =
    {
      id : null,
      name : '',
      surname : '',
      birthdate : '',
      postalcode : '',
      tel : '',
      adress : '',
      email : '',
      town : '',
      country : '',
      num_agreement : '',
      registered : '',
      diploma : '',
      faculty : '',
      country_faculty : '',
      town_faculty : '',
      id_speciality : '',
      code_swift : '',
      iban_code : '',
      account_number : '',
      rib_key : '',
      account_owner : '',
      tariff : '',
      status : '',
      last_connexion : '',
      cover_letter : '',
      cv : '',
      avatar : ''
    };

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newDoctor = {} as Doctor; }

  ngOnInit() {
    this.checkSession();
    this.initFormEdit();
    this.getSpecialities();
    this.baseurl = environment.baseUrl + 'public/';
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + '/public/files/avatars/avatar.png';
    this.pays = paysdata;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  initFormEdit() {
    this.doctorFormEdit = this.formBuilder.group({
      id: [''],
      checkbox: ['', Validators.required],
      name: ['', Validators.required],
      surname: [''],
      birthdate: [''],
      postalcode: [''],
      tel: ['', Validators.required],
      adress: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      town: ['', Validators.required],
      country: ['', Validators.required],
      num_agreement: ['', Validators.required],
      registered: ['', Validators.required],
      diploma: ['', Validators.required],
      faculty: ['', Validators.required],
      country_faculty: ['', Validators.required],
      town_faculty: ['', Validators.required],
      // id_speciality: ['', Validators.required],
      // cv: ['', Validators.required],
      // cover_letter: ['', Validators.required],
      code_swift: [''],
      iban_code: [''],
      account_number: [''],
      rib_key: [''],
      account_owner: [''],
      tariff: ['']
    });
  }

  get f1() { return this.doctorFormEdit.controls; }

  public liste() {
    this.resourceService.getResources('doc/all').subscribe(data => {
      this.doctors = data;

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(doctor){

    this.detailsData = {
      id: doctor.id,
      name: doctor.name,
      surname: doctor.surname,
      birthdate: doctor.birthdate,
      postalcode: doctor.postalcode,
      tel: doctor.tel,
      adress: doctor.adress,
      email: doctor.email,
      town: doctor.town,
      country: doctor.country,
      num_agreement: doctor.num_agreement,
      registered: doctor.registered,
      diploma: doctor.diploma,
      faculty: doctor.faculty,
      country_faculty: doctor.country_faculty,
      town_faculty: doctor.town_faculty,
      id_speciality: doctor.id_speciality,
      code_swift: doctor.code_swift,
      iban_code: doctor.iban_code,
      account_number: doctor.account_number,
      rib_key: doctor.rib_key,
      account_owner: doctor.account_owner,
      tariff: doctor.tariff,
      status: doctor.status,
      last_connexion: doctor.last_connexion,
	    cover_letter: this.baseUrl + 'public' + doctor.cover_letter,
	    cv: this.baseUrl + 'public' + doctor.cv,
	    avatar: this.baseUrl + 'public' + doctor.avatar
      // avatar: doctor.avatar
    };

	/*
    if (doctor.avatar != null) {
      this.urls = this.baseUrl + 'public/files/avatars/' + doctor.avatar;
    }

    if (doctor.cover_letter != null) {
      this.nameFileLV = this.baseUrl + 'public/files/files/' + doctor.cover_letter;
    }

    if (doctor.cv != null) {
      this.nameFile = this.baseUrl + 'public/files/files/' + doctor.cv;
    }
	*/

    console.log(this.detailsData.id);
  }

  public onEdit(doctor) {
    this.doctorFormEdit.patchValue({
      id: doctor.id,
      checkbox: doctor.checkbox,
      name: doctor.name,
      surname: doctor.surname,
      birthdate: doctor.birthdate,
      postalcode: doctor.postalcode,
      tel: doctor.tel,
      adress: doctor.adress,
      email: doctor.email,
      town: doctor.town,
      country: doctor.country,
      num_agreement: doctor.num_agreement,
      registered: doctor.registered,
      diploma: doctor.diploma,
      faculty: doctor.faculty,
      country_faculty: doctor.country_faculty,
      town_faculty: doctor.town_faculty,
      // id_speciality: doctor.id_speciality,
      code_swift: doctor.code_swift,
      iban_code: doctor.iban_code,
      account_number: doctor.account_number,
      rib_key: doctor.rib_key,
      account_owner: doctor.account_owner,
      tariff: doctor.tariff,
      // cover_letter: this.baseUrl + 'public' + doctor.cover_letter,
      // cv: this.baseUrl + 'public' + doctor.cv,
      avatar: this.baseUrl + 'public' + doctor.avatar
      //avatar: 'public' + doctor.avatar,
      // avatar: this.baseUrl + 'public' + doctor.avatar
    });

	/*
    if (doctor.avatar != null) {
      this.urls = this.baseUrl + 'public/files/avatars/' + doctor.avatar;
      this.formData.append('avatar', doctor.avatar);
    }

    if (doctor.cover_letter != null) {
      this.nameFileLV = this.baseUrl + 'public/files/files/' + doctor.cover_letter;
      this.formData.append('cover_letter', doctor.cover_letter);
    }

    if (doctor.cv != null) {
      this.nameFile = this.baseUrl + 'public/files/files/' + doctor.cv;
      this.formData.append('cv', doctor.cv);
    }
	*/
  }

  public updateDoctor(){
    if (this.doctorFormEdit.invalid) {
      return;
    }

	this.date = new Date();
	let formData = new FormData();
    let formValue = this.doctorFormEdit.value;
	// const formValue = this.doctorFormEdit.value;
    formData.append('id', formValue['id']);
    formData.append('name', formValue['name']);
    formData.append('surname', formValue['surname']);
    formData.append('birthdate', formValue['birthdate']);
    formData.append('email', formValue['email']);
    formData.append('tel', formValue['tel']);
    formData.append('adress', formValue['adress']);
    formData.append('postalcode', formValue['postalcode']);
    formData.append('town', formValue['town']);
    formData.append('country', formValue['country']);
    formData.append('registered', formValue['registered']);
    formData.append('num_agreement', formValue['num_agreement']);
    formData.append('faculty', formValue['faculty']);
    formData.append('diploma', formValue['diploma']);
	  formData.append('town_faculty', formValue['town_faculty']);
    formData.append('country_faculty', formValue['country_faculty']);
    // formData.append('specialities', JSON.stringify(formValue['id_speciality']));
	  formData.append('code_swift', formValue['code_swift']);
    formData.append('iban_code', formValue['iban_code']);
    formData.append('account_number', formValue['account_number']);
    formData.append('rib_key', formValue['rib_key']);
    formData.append('account_owner', formValue['account_owner']);
    formData.append('tariff', formValue['tariff']);
    formData.append('date_updated', this.date);
	// formData.append('status', '1');
    // formData.append('connected', '0');
	// formData.append('date_created', this.date);
    // formData.append('last_connexion', this.date);

    if(this.fileData){
      formData.append('avatar', this.fileData );
    }

    // if(this.fileDataCv){
    //     formData.append('cv', this.fileDataCv);
    //   }
    //
    // if(this.fileDataLv){
    //     formData.append('cover_letter', this.fileDataLv);
    //   }

    this.resourceService.updateResource('doctor/update/' + formValue['id'], formData).subscribe(data => {
      this.resetForm(this.doctorFormEdit);
      this.closemodal.nativeElement.click();
      this.liste();
      this.toastr.success('Le doctor a été mis à jour!', 'Sucess');

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
      if(error.status==400){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData );
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {
    }
  }

  // public onSelectCV(events, f: NgForm) {
  //   if (events.target.files && events.target.files[0]) {
  //
  //     this.nameFile = events.target.files[0].name;
  //     this.fileDataCv = <File>events.target.files[0];
  //     // this.formData.append('cv', this.fileDataCv);
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       // this.nameFile = event.target.result;
  //     };
  //     reader.readAsDataURL(events.target.files[0]);
  //   } else {
  //
  //   }
  // }

  // public onSelectLV(events, f: NgForm) {
  //   if (events.target.files && events.target.files[0]) {
  //
  //     this.nameFileLV = events.target.files[0].name;
  //     this.fileDataLv = <File>events.target.files[0];
  //     // this.formData.append('cover_letter', this.fileDataLv);
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       // this.nameFileLV = event.target.result;
  //     };
  //     reader.readAsDataURL(events.target.files[0]);
  //   }
  // }

  public getSpecialities(){

    this.resourceService.getResources('speciality/listall').subscribe(data=>{
      this.specialities = data;

    } ,err=>{
      console.log('erreur',err);
    })
  }

  public resetForm(form){
    form.reset();
  }

  public  deleteDoctor(event: MouseEvent, doctor) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('doc/del', doctor.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('doc/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  activateDoctor(event: MouseEvent, doctor) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to activate this account?', 'Yes, activate it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.activateResource('doc/validate', doctor.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully activated.\'','Activated!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to activate the doctor account, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  suspendDoctor(event: MouseEvent, doctor) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to suspend this account?', 'Yes, activate it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.suspendResource('doc/suspend', doctor.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully suspended.','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to suspend the doctor account, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  // Dialog and method to delete account
  // public showDeleteMessage(event: MouseEvent, doctor) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deleteDoctor(event, doctor);
  //       swal('Deleted!', 'Your data has been successfully deleted.', 'success');
  //     } else {
  //       swal('Cancelled', 'Your data is safe.', 'error');
  //     }
  //   });
  // }




  // Dialog and method to activate account
  // public showActivateMessage(event: MouseEvent, doctor) {
  //   swal({
  //     title: 'Are you sure?',
  //     text: 'Do you want to activate this account?',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, activate it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.activateDoctor(event, doctor);
  //       swal('Activated!', 'Your data has been successfully activated.', 'success');
  //     } else {
  //       swal('Cancelled', 'Your data is not activated.', 'error');
  //     }
  //   });
  // }



  // Dialog and method to suspend account
  // public showSuspendMessage(event: MouseEvent, doctor) {
  //   swal({
  //     title: 'Are you sure?',
  //     text: 'Do you want to suspend this account?',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, suspend it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.suspendDoctor(event, doctor);
  //       swal('Deleted!', 'Your data has been successfully suspended.', 'success');
  //     } else {
  //       swal('Cancelled', 'Your data is safe.', 'error');
  //     }
  //   });
  // }


// Dialog and method to delete all account
//   public showDeleteAllMessage(event: MouseEvent) {
//     swal({
//       title: 'Are you sure?',
//       text: 'Do you want to delete all datas? This action is irreversible!',
//       type: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: "#DD6B55",
//       confirmButtonText: "Yes, delete it!",
//       cancelButtonText: "No, cancel please!",
//       closeOnConfirm: false,
//       closeOnCancel: false
//     }, function (isConfirm) {
//       if (isConfirm) {
//
//         this.deleteAllDoctor(event);
//
//         swal('Deleted!', 'The datas has been successfully deleted.', 'success');
//       } else {
//         swal('Cancelled', 'The datas are safe.', 'error');
//       }
//     });
//   }


  // Dialog and method to archived all account
  /*
  public showArchivedAllMessage(event: MouseEvent) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to archived all datas?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, archive it!",
      closeOnConfirm: false
    }, function () {

      // insert the method to archived all datas
      // this.archiveAllDoctor(event);

      swal('Archived!', 'The datas has been successfully archived.', 'success');
    });
  }
  */
  // public  archiveAllDoctor(event: MouseEvent) {
  //
  //   event.preventDefault();
  //
  //   // insert the url and service to activate account doctor
  //   this.resourceService.updateResource(this.url + '/update', doctor.id).subscribe(data => {
  //
  //     this.doctors = data;
  //
  //   }, error => {
  //
  //     this.toastr.error(Errors(error), 'Echec de suppression, \n');
  //     console.log('erreur', error);
  //     console.log(Errors(error));
  //   });
  // }


}
