import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppComponent } from '../app.component';
import { ArchwizardModule } from 'angular-archwizard';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';

import { PatientDashboardComponent } from './patient/patient-dashboard/patient-dashboard.component';
import { MyAppointmentsComponent } from './patient/my-appointments/my-appointments.component';
import { MedicalFileComponent } from './patient/medical-file/medical-file.component';
import { SearchDoctorComponent } from './patient/search-doctor/search-doctor.component';
import { ViewDoctorComponent } from './patient/view-doctor/view-doctor.component';
import { AddPaymentModeComponent } from './patient/add-payment-mode/add-payment-mode.component';
import { EditPaymentModeComponent } from './patient/edit-payment-mode/edit-payment-mode.component';
import { ViewPaymentModeComponent } from './patient/view-payment-mode/view-payment-mode.component';

import { TeleconsultationPatientComponent } from './patient/teleconsultation-patient/teleconsultation-patient.component';
import { MyAccountPatientComponent } from './patient/my-account-patient/my-account-patient.component';
import { EditPasswordPatientComponent } from './patient/edit-password-patient/edit-password-patient.component';
import { EditProfilePatientComponent } from './patient/edit-profile-patient/edit-profile-patient.component';


import { DoctorDashboardComponent } from './medecin/doctor-dashboard/doctor-dashboard.component';
import { ScheduleComponent } from './medecin/schedule/schedule.component';
import { PatientsComponent } from './medecin/patients/patients.component';
import { ViewPatientComponent } from './medecin/view-patient/view-patient.component';
import { ListConsultationComponent } from './medecin/list-consultation/list-consultation.component';
import { ViewConsultationComponent } from './medecin/view-consultation/view-consultation.component';
import { EditProfileDoctorComponent } from './medecin/edit-profile-doctor/edit-profile-doctor.component';
import { EditPasswordDoctorComponent } from './medecin/edit-password-doctor/edit-password-doctor.component';
import { MyAccountDoctorComponent } from './medecin/my-account-doctor/my-account-doctor.component';
import { TeleconsultationDoctorComponent } from './medecin/teleconsultation-doctor/teleconsultation-doctor.component';
import { DoctorpaymentmodeComponent } from './medecin/doctorpaymentmode/doctorpaymentmode.component';

import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { EditProfileAdminComponent } from './admin/edit-profile-admin/edit-profile-admin.component';
import { EditPasswordAdminComponent } from './admin/edit-password-admin/edit-password-admin.component';
import { MyAccountAdminComponent } from './admin/my-account-admin/my-account-admin.component';

import { AddDoctorComponent } from './admin/add-doctor/add-doctor.component';
import { ListDoctorComponent } from './admin/list-doctor/list-doctor.component';

import { AddPatientComponent } from './admin/add-patient/add-patient.component';
import { ListPatientComponent } from './admin/list-patient/list-patient.component';

import { AddDepartmentComponent } from './admin/add-department/add-department.component';
import { ListDepartmentComponent } from './admin/list-department/list-department.component';

import { AddRoleComponent } from './admin/add-role/add-role.component';
import { ListRoleComponent } from './admin/list-role/list-role.component';

import { AddPharmacyComponent } from './admin/add-pharmacy/add-pharmacy.component';
import { ListPharmacyComponent } from './admin/list-pharmacy/list-pharmacy.component';

import { AddUserComponent } from './admin/add-user/add-user.component';
import { ListUserComponent } from './admin/list-user/list-user.component';

import { HomeComponent } from './site/home/home.component';
import { SignInComponent } from './site/sign-in/sign-in.component';

import { EProUserComponent } from './site/e-pro-user/e-pro-user.component';
import { ForgetPasswordComponent } from './site/forget-password/forget-password.component';
import { ContactComponent } from './site/contact/contact.component';
import { AboutUsComponent } from './site/about-us/about-us.component';
import { OurMissionComponent } from './site/our-mission/our-mission.component';
import { OurPartnersComponent } from './site/our-partners/our-partners.component';
import { LegalMentionsComponent } from './site/legal-mentions/legal-mentions.component';

import { MessagesComponent } from './general/messages/messages.component';

// import { LoginComponent } from './site/login/login.component';
import { SignUpPatientComponent } from './site/sign-up-patient/sign-up-patient.component';
import { SignUpMedecinComponent } from './site/sign-up-medecin/sign-up-medecin.component';
import { ConfirmComponent } from './site/confirm/confirm.component';
import { ConfirmationDialogService} from '../ui/notifications/notification.service';
import { NotificationsComponent } from '../ui/notifications/notifications.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TruncatePipesModule } from 'angular-truncate-pipes';
import { ResourceService } from '../services/resource.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { ValidateEmailComponent } from './site/validate-email/validate-email.component';
import { VideoPatientComponent } from './patient/video-patient/video-patient.component';

import { DataTablesModule } from 'angular-datatables';

import { AgmCoreModule } from '@agm/core';
import { PatientMessagesComponent } from './patient/patient-messages/patient-messages.component';
import { DatetimepickerService } from '../services/datetimepicker.service';
import { ReservationDoctorComponent } from './patient/reservation-doctor/reservation-doctor.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { VideoDoctorComponent } from './medecin/video-doctor/video-doctor.component';
import { PatientMessageViewComponent } from './patient/patient-message-view/patient-message-view.component';
import { NgxPaginationModule } from 'ngx-pagination';

registerLocaleData(localeFr);
registerLocaleData(localeEs);
import { ShowMessageComponent } from './patient/patient-messages/show-message/show-message.component';
import { NewMessageComponent } from './patient/patient-messages/new-message/new-message.component';
import { InvoicePatientComponent } from './patient/invoice-patient/invoice-patient.component';
import { DoctorMessageComponent } from './medecin/doctor-message/doctor-message.component';
import { DoctorNewMessageComponent } from './medecin/doctor-message/doctor-new-message/doctor-new-message.component';
import { DoctorShowMessageComponent } from './medecin/doctor-message/doctor-show-message/doctor-show-message.component';
import { InvoiceService } from '../services/invoice.service';
import { ListTransactionComponent } from './admin/list-transaction/list-transaction.component';
import { DoctorMessageViewComponent } from './medecin/doctor-message-view/doctor-message-view.component';
import { ListRdvComponent } from './admin/list-rdv/list-rdv.component';
import { AddMenuComponent } from './admin/add-menu/add-menu.component';
import { ListMenuComponent } from './admin/list-menu/list-menu.component';
import { PatientGuardService } from '../services/patient-guard.service';
import { AdminGuardService } from '../services/admin-guard.service';
import { DoctorGuardService } from '../services/doctor-guard.service';
import { SupportMessageComponent } from './medecin/doctor-message/support-message/support-message.component';
import { SupportMessgePatientComponent } from './patient/patient-messages/support-messge-patient/support-messge-patient.component';
import { ConfirmActionComponent } from './admin/confirm-action/confirm-action.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchDoctorGeneralComponent } from './general/search-doctor-general/search-doctor-general.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgxLoadingModule } from 'ngx-loading';


// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SocketserviceService } from '../services/socketservice.service';
import { MediastreamService } from '../services/mediastream.service';

//import { MomentModule } from 'ngx-moment';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SocketService } from '../services/socket.service';
import { DetailAppointmentComponent } from './patient/detail-appointment/detail-appointment.component';
import { ConfirmPaymentComponent } from './patient/confirm-payment/confirm-payment.component';
import { ViewTeleconsultationDoctorComponent } from './medecin/view-teleconsultation-doctor/view-teleconsultation-doctor.component';
import { ViewTeleconsultationPatientComponent } from './patient/view-teleconsultation-patient/view-teleconsultation-patient.component';
import { SignInPatientComponent } from './site/sign-in-patient/sign-in-patient.component';
import { SignInMedecinComponent } from './site/sign-in-medecin/sign-in-medecin.component';
// const config: SocketIoConfig = { url:environment.socketUrl, options: {} };
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  declarations: [
    AppComponent,
    //LoginComponent,
    PatientDashboardComponent,
    MyAppointmentsComponent,
    MedicalFileComponent,
    TeleconsultationPatientComponent,
    MyAccountPatientComponent,
    EditPasswordPatientComponent,
    EditProfilePatientComponent,

    AddPaymentModeComponent,
    EditPaymentModeComponent,
    ViewPaymentModeComponent,

    DoctorDashboardComponent,
    ScheduleComponent,
    SearchDoctorComponent,
    ViewDoctorComponent,
    EditProfileDoctorComponent,
    EditPasswordDoctorComponent,
    MyAccountDoctorComponent,
    TeleconsultationDoctorComponent,
    ViewConsultationComponent,
    ListConsultationComponent,
    ViewPatientComponent,

    PatientsComponent,
    MessagesComponent,

    AdminDashboardComponent,
    AddDoctorComponent,
    ListDoctorComponent,
    AddPatientComponent,
    ListPatientComponent,
    DoctorpaymentmodeComponent,

    AddDepartmentComponent,
    ListDepartmentComponent,

    EditProfileAdminComponent,
    EditPasswordAdminComponent,
    MyAccountAdminComponent,

    AddRoleComponent,
    ListRoleComponent,
    AddPharmacyComponent,
    ListPharmacyComponent,

    AddUserComponent,
    ListUserComponent,

    HomeComponent,
    ContactComponent,
    AboutUsComponent,
    LegalMentionsComponent,

    OurMissionComponent,
    OurPartnersComponent,
    EProUserComponent,
    ForgetPasswordComponent,
    SignInComponent,
    SignUpPatientComponent,
    SignUpMedecinComponent,
    ConfirmComponent,
    NotificationsComponent,
    ValidateEmailComponent,
    VideoPatientComponent,
    PatientMessagesComponent,
    VideoDoctorComponent,
    PatientMessageViewComponent,
    ReservationDoctorComponent,
    // DoctorVideoComponent,
    VideoDoctorComponent,
    ShowMessageComponent,
    NewMessageComponent,
    InvoicePatientComponent,
    DoctorMessageComponent,
    DoctorNewMessageComponent,
    DoctorShowMessageComponent,
    ListTransactionComponent,
    DoctorMessageViewComponent,
    ListRdvComponent,
    AddMenuComponent,
    ListMenuComponent,
    SupportMessageComponent,
    SupportMessgePatientComponent,
    SupportMessageComponent,
    ConfirmActionComponent,
    SearchDoctorGeneralComponent,
    DetailAppointmentComponent,
    ConfirmPaymentComponent,
    ViewTeleconsultationDoctorComponent,
    ViewTeleconsultationPatientComponent,
    SignInPatientComponent,
    SignInMedecinComponent
],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ArchwizardModule,
    NgbModule,
    TruncatePipesModule,
    BrowserAnimationsModule,
	NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot({timeOut: 6000}),
    DataTablesModule,
  //  MomentModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDMcpS6XuqtUoWfNhB_CuhnGmKVfdjBV20",
      libraries: ["places"]
    }),
    // NgxDatetimeRangePickerModule.forRoot()
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    Ng2FlatpickrModule,
    NgxPaginationModule,
    NgSelectModule,

    HttpClientModule,
    Ng2ImgMaxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // SocketIoModule.forRoot(config)


  ],

  providers: [MediastreamService, SocketserviceService, DatePipe, SocketService, ConfirmationDialogService, ResourceService, DatetimepickerService,InvoiceService,PatientGuardService,DoctorGuardService,AdminGuardService],
  entryComponents: [NotificationsComponent, TeleconsultationPatientComponent],
})
export class PagesModule {
}
