import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {ResourceService} from "../../../services/resource.service";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(private resourceService: ResourceService, private router:Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.checkSession();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }
}
