import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/socket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-medecin',
  templateUrl: './header-medecin.component.html',
  styleUrls: ['./header-medecin.component.css']
})
export class HeaderMedecinComponent implements OnInit {

  userId: any;
  public currentlang:any = '';

  constructor(private translateService: TranslateService, private resourceService: ResourceService, private router: Router,private socketService :SocketService) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
	
	let lang:any = navigator.language;
    if(lang){
      if (lang != "fr" && lang != "en") {
        this.translateService.setDefaultLang('en');
		this.currentlang = 'en';
      }else {
        this.translateService.setDefaultLang(lang);
		this.currentlang = lang;
      }   
    }else{
      this.translateService.setDefaultLang('en');
	  this.currentlang = 'en';
    }
  }
  
  switchLanguage(language: string) {
    this.translateService.use(language);
	this.currentlang = language;
  }

  logout(){
    this.resourceService.getResource('doc/logout', this.userId).subscribe(data => {
   

      let users={
        id:this.userId, 
        type:"doctor"
      }
      this.socketService.logoutSocket(JSON.stringify(users) );
         localStorage.clear();
          sessionStorage.clear();
      this.router.navigate(['/doctor/sign-in']);
    } );
  }

}
