import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { timer } from 'rxjs';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-account-patient',
  templateUrl: './my-account-patient.component.html',
  styleUrls: ['./my-account-patient.component.css']
})
export class MyAccountPatientComponent implements OnInit, OnDestroy {

  public source = timer(1000, 2000);
  timer: any;
  userId: any;
  doctor: string;
  speciality: any;
  userDisplayName: string;
  avatar: string;
  baseurl: any;
  patient: any;
  rdvs: any;

 constructor(private router: Router, private resourceService: ResourceService, private toastr: ToastrService, private c: ConfirmationDialogService) { }


  ngOnInit() {
    
    this.getPatient();
    this.getlistrdv();
    this.userId = sessionStorage.getItem('userId');
    this.userDisplayName = sessionStorage.getItem('username');
    this.avatar = sessionStorage.getItem('avatar');
    this.baseurl = environment.baseUrl+'public/';
  }

  ngOnDestroy() {
 
  }



  getAppel() { 
    let tele:any
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    if(tele && tele.id){
      sessionStorage.setItem('roomname',tele.roomname);
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    }else
      return 'None';
    
  }

  getPatient(){
    this.resourceService.getResource('patient', +this.userId).subscribe(data=>{
      this.patient=data;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  } 
  
  getlistrdv(){
    this.resourceService.getResource('rdv/alltogo', +this.userId).subscribe(data=>{
      this.rdvs=data;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  }

}
