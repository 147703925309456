import { Injectable } from '@angular/core';
import { Invoice } from '../models/invoice';
import jsPDF from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
public documentDefinition:any;
  constructor(private translate:TranslateService) { }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  async downloadBill(invoice:any ){
this.documentDefinition = { 
    
      content: [
        // Header
        {
            columns: [
                {         
                      image:await this.getBase64ImageFromURL("../../assets/images/logo.jpg"),
                      /* image:await this.getBase64ImageFromURL("src/assets/images/logo.jpg"), */
            
                        width: 150
                },
                    
                [
                    {
                        text: this.translate.instant('bill1'), 
                        style: 'invoiceTitle',
                        width: '*'
                    },
                    {
                      stack: [
                           {
                               columns: [
                                    {
                                        text: this.translate.instant('bill')+' #', 
                                        style:'invoiceSubTitle',
                                        width: '*'
                                        
                                    }, 
                                    {
                                        text: invoice.id,
                                        style:'invoiceSubValue',
                                        width: 100
                                        
                                    }
                                    ]
                           },
                           {
                               columns: [
                                   {
                                       text:this.translate.instant('date'),
                                       style:'invoiceSubTitle',
                                       width: '*'
                                   }, 
                                   {
                                       text:invoice.date_created,
                                       style:'invoiceSubValue',
                                       width: 100
                                   }
                                   ]
                           },
                           {
                               columns: [
                                   {
                                       text:'Due Date',
                                       style:'invoiceSubTitle',
                                       width: '*'
                                   }, 
                                   {
                                       text:invoice.date_created,
                                       style:'invoiceSubValue',
                                       width: 100
                                   }
                                   ]
                           },
                       ]
                    }
                ],
            ],
        },
        // Billing Headers
        {
            columns: [
                {
                    text:  this.translate.instant('bill_from'),
                    style:'invoiceBillingTitle',
                    
                },
                {
                    text:  this.translate.get('bill_to'),
                    style:'invoiceBillingTitle',
                    
                },
            ]
        },
        // Billing Details
        {
            columns: [
                {
                    text: ' E-pocrate',
                    style: 'invoiceBillingDetails'
                },
                {
                    text: invoice.name+' ,'+ invoice.surname ,
                    style: 'invoiceBillingDetails'
                },
            ]
        },
        // Billing Address Title
        {
            columns: [
                {
                    text:  this.translate.instant('addr'),
                    style: 'invoiceBillingAddressTitle'
                },
                {
                    text:  this.translate.instant('addr'),
                    style: 'invoiceBillingAddressTitle'
                },
            ]
        },
        // Billing Address
        {
            columns: [
                {
                    text: 'douala,Cameroun',
                    style: 'invoiceBillingAddress'
                },
                {
                    text: invoice.adress,
                    style: 'invoiceBillingAddress'
                },
            ]
        },

          // Billing tel
          {
            columns: [
                {
                    text: this.translate.instant('phone')+':',
                    style: 'invoiceBillingAddress'
                },
                {
                    text: this.translate.instant('phone')+': '+ invoice.tel,
                    style: 'invoiceBillingAddress'
                },
            ]
        },

            // Billing email
            {
              columns: [
                  {
                      text:  this.translate.instant('email')+' :',
                      style: 'invoiceBillingAddress'
                  },
                  {
                      text:  this.translate.instant('email')+': '+ invoice.email,
                      style: 'invoiceBillingAddress'
                  },
              ]
          },
          // Line breaks
        '\n\n',
        // Items
          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: [ 'auto', "auto", 'auto', "auto",80  ],
      
              body: [
                // Table Header
                [ 
                    {
                        text:this.translate.instant('doctor'),
                        style: 'itemsHeader'
                    }, 
                    {
                        text: this.translate.instant('typ_of_consul'),
                        style: [ 'itemsHeader', 'center']
                    }, 
                    {
                        text: this.translate.instant('type_of_payment'),
                        style: [ 'itemsHeader', 'center']
                    }, 
                    {
                        text: this.translate.instant('time_consultaion'),
                        style: [ 'itemsHeader', 'center']
                    }, 

                    {
                      text:  this.translate.instant('amount'),
                      style: [ 'itemsHeader', 'center']
                  },
                  
                ],
                // Items
                // Item 1
                [ 
                  {
                    text: invoice.doctor,
                    style:'itemSubTitle'
                    
                }, 
                    {
                        text:invoice.type_consultation,
                        style:'itemSubTitle'
                    }, 
                    {
                        text:invoice.type_payement,
                        style:'itemSubTitle'
                    }, 
                    {
                        text:invoice.date_consultation,
                        style:'itemSubTitle'
                    },  

                    {
                      text:invoice.amount +'XAF',
                      style:'itemSubTitle'
                  },
                ],
            
                // END Items
              ]
            }, // table
          //  layout: 'lightHorizontalLines'
          },
        // Signature
        {
            columns: [
                {
                    text:'',
                },
                {
                    stack: [
                        { 
                            text: '_________________________________',
                            style:'signaturePlaceholder'
                        },
                        { 
                            text: 'Your Name',
                            style:'signatureName'
                            
                        },
                        { 
                            text: 'Your job title',
                            style:'signatureJobTitle'
                            
                        }
                        ],
                   width: 180
                },
            ]
        },
          { 
              text:  this.translate.instant('motif_consultaion'),
              style:'notesTitle'
          },
          { 
              text: invoice.motif,
              style:'notesText'
          }
    ],
    styles: {
    
      // Invoice Title
    invoiceTitle: {
      fontSize: 22,
      bold: true,
      alignment:'right',
      margin:[0,0,0,15]
    },
    // Invoice Details
    invoiceSubTitle: {
      fontSize: 12,
      alignment:'right'
    },
    invoiceSubValue: {
      fontSize: 12,
      alignment:'right'
    },
    // Billing Headers
    invoiceBillingTitle: {
      fontSize: 14,
      bold: true,
      alignment:'left',
      margin:[0,20,0,5],
    },
    // Billing Details
    invoiceBillingDetails: {
      alignment:'left'

    },
    invoiceBillingAddressTitle: {
        margin: [0,7,0,3],
        bold: true
    },
    invoiceBillingAddress: {
        
    },
    // Items Header
    itemsHeader: {
        margin: [0,5,0,5],
        bold: true
    },
    // Item Title
    itemTitle: {
        bold: true,
    },
    itemSubTitle: {
            italics: true,
            fontSize: 11
    },
    itemTotal: {
        margin: [0,5,0,5],
        bold: true,
        alignment: 'center',
    },

    // Items Footer (Subtotal, Total, Tax, etc)
    itemsFooterSubTitle: {
        margin: [0,5,0,5],
        bold: true,
        alignment:'right',
    },
    itemsFooterSubValue: {
        margin: [0,5,0,5],
        bold: true,
        alignment:'center',
    },
    itemsFooterTotalTitle: {
        margin: [0,5,0,5],
        bold: true,
        alignment:'right',
    },
    itemsFooterTotalValue: {
        margin: [0,5,0,5],
        bold: true,
        alignment:'center',
    },
    signaturePlaceholder: {
        margin: [0,70,0,0],   
    },
    signatureName: {
        bold: true,
        alignment:'center',
    },
    signatureJobTitle: {
        italics: true,
        fontSize: 10,
        alignment:'center',
    },
    notesTitle: {
      fontSize: 10,
      bold: true,  
      margin: [0,50,0,3],
    },
    notesText: {
      fontSize: 10
    },
    center: {
        alignment:'center',
    },
  },
  defaultStyle: {
    columnGap: 20,
  }
       };
       return pdfMake.createPdf(this.documentDefinition).open();
  }

}
