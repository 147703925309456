import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Subject } from 'rxjs';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Invoice } from 'src/app/models/invoice';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.css']
})
export class ConfirmPaymentComponent implements OnInit {
	
  invoice:any;
  payment:any;
  userId:any;
  idpay:any;

  constructor(
		private router: Router,
		private route: ActivatedRoute,
		private resourceService:ResourceService,
        private invoiceService:InvoiceService,
        public datepipe:DatePipe,
        private translate:TranslateService) 
		{
			this.invoice = {} as Invoice;
		}

  ngOnInit() {
	this.userId = sessionStorage.getItem('userId');
	this.idpay = this.route.snapshot.params.id;
	this.getPayment(this.idpay, this.userId);
  }
  
  
  public getPayment(id, uId) {
    this.resourceService.getResources('invoice/'+id+'/'+uId).subscribe((data:any)=>{
      console.log('payment', data);
      this.payment = data;
    },err=>{
        console.log('err',err);
    })
  }
  
 
  public downloadBill(inv) {
	  this.invoice.amount=inv.total;
      this.invoice.date_consultation= this.datepipe.transform(inv.rdv.date_hours,'dd/MM/yyyy h:mm') ;
      this.invoice.date_created= this.datepipe.transform(inv.date_created,'dd/MM/yyyy') ;
      this.invoice.doctor= 'Dr '+ inv.rdv.disponibility.doctor.name + ' ' + inv.rdv.disponibility.doctor.surname;
      this.invoice.email=inv.patient.email;
      this.invoice.id=inv.id + '/' + inv.transaction_number;
      this.invoice.motif=inv.description?inv.description:this.translate.instant('not_motif');
      this.invoice.name=inv.patient.lastname;
      this.invoice.surname=inv.patient.username;
      this.invoice.tel=inv.patient.phone;
      this.invoice.type_consultation=inv.rdv.type;
      this.invoice.type_payement='Stripe';
      this.invoice.adress=inv.patient.adresse
      this.invoiceService.downloadBill(this.invoice);
  }

}
