import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Pharmacy } from 'src/app/models/pharmacy';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from 'ngx-toastr';
import paysdata from 'src/app/pays.json';

@Component({
  selector: 'app-add-pharmacy',
  templateUrl: './add-pharmacy.component.html',
  styleUrls: ['./add-pharmacy.component.css']
})
export class AddPharmacyComponent implements OnInit {

  newPharmacy:Pharmacy;
  pharmacyForm: FormGroup;
  submitted = false;
  pays: any;

  constructor(private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
    this.newPharmacy={} as Pharmacy;
  }

  ngOnInit() {
    this.checkSession();
    this.pays = paysdata;
    this.createForm();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  createForm() {
    this.pharmacyForm = this.fb.group({
      name: ['', Validators.required],
      adress: ['', Validators.required],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country_town: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.pharmacyForm.invalid ) {
      return;
    } else {

      let formValue = this.pharmacyForm.value;
      this.newPharmacy.id = null;
      this.newPharmacy.name = formValue['name'];
      this.newPharmacy.adress = formValue['adress'];
      this.newPharmacy.tel = formValue['tel'];
      this.newPharmacy.email = formValue['email'];
      this.newPharmacy.country_town = formValue['country_town'];
      this.newPharmacy.date_created = new Date();

      // Save datas create role in database
      this.resourceService.saveResource('pharmacie/create', this.newPharmacy).subscribe((data) => {

        console.log('data',data);
        this.pharmacyForm.reset();
        this.toastr.success('La pharmacie a été crée!', 'Sucess');
        this.router.navigate(['/admin/confirm/action']);

      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
        console.log(Errors(err));
      });
    }
  }

  get f1() {
    // console.log(this.pharmacyForm.controls);
    return this.pharmacyForm.controls;
  }

}
