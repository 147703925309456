import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-consultation',
  templateUrl: './list-consultation.component.html',
  styleUrls: ['./list-consultation.component.css']
})
export class ListConsultationComponent implements OnInit {
  
  userId: any;
  consultations: any;
  baseurl: string;
  baseUrl: string;
  dtTrigger: Subject<any> = new Subject();

  constructor(private router: Router, private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService, private resourceService:ResourceService) { }

  ngOnInit() {
   // this.checkSession();
    this.userId = sessionStorage.getItem('userId');
    this.baseUrl = environment.baseUrl;
    this.baseurl = this.baseUrl+'public';
  }

  ngAfterViewInit(){
    this.getconsultations();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getconsultations() { 
    this.resourceService.getResource('rdv/alldoctogo',this.userId).subscribe(data=>{
      this.consultations = data; 
      this.dtTrigger.next();
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  }

  deleteitem(item) {
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer cette ligne... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('rdv/delete',item.id).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }

}
