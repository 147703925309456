import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  angForm: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder,private router: Router, private toastr: ToastrService, private resourceService:ResourceService) { }

  ngOnInit() {
    this.createForm();

  }

  createForm() {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  get f() {
   
    return this.angForm.controls;
  }

  onSubmit() {

    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }else{
     
      this.resourceService.saveResource_unsafe('sendcontactmail',this.angForm.value).subscribe(data=>{
        let res:any = data;
        if(res=="Done")
          this.toastr.success('Envoye!','Succes');
        else
           this.toastr.error('Echec envoi!', 'Major Error');

      },err=>{
          this.toastr.error(Errors(err), 'Major Error');
          console.log('erreur',err);
      });
    }
  }

}
