import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketserviceService {
	
  public socket: any;

  constructor() {
    this.socket = io(environment.socketUrl, {
	  secure:true,
	  reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 1,
      transports: ['websocket'], // default is ['polling', 'websocket']
      rejectUnauthorized: false
    });
    this.onInit();
  }
  

  public onInit() {
    this.socket.on('connect', () => {
      console.log('Connected to Server');
    });
    this.socket.on('connect_timeout', (timeout: any) => {
      console.log('Connection Timeout with : ', timeout);
    });
    this.socket.on('connect_error', (error: any) => {
      console.log('Connection Error : ', error);
    });
    this.socket.on('disconnect', (reason: any) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually by socket.connect()
        console.log('The disconnection was initiated by the server, server disconnected');
      } else {
        // else the socket will automatically try to reconnect
        console.log('Server Disconnected : ', reason);
      }
    });
    this.socket.on('reconnect', (attemptNumber: any) => {
      console.log('Socket Server Successfully Reconnected with attempt : ', attemptNumber);
    });
    this.socket.on('reconnect_attempt', (attemptNumber: any) => {
      console.log('Reconnect Attempt : ', attemptNumber);
    });
    this.socket.on('reconnecting', (attemptNumber: any) => {
      console.log('Attempting to Reconnect : ', attemptNumber);
    });
    this.socket.on('reconnect_error', (error: any) => {
      console.log('Reconnection Error : ', error);
    });
    this.socket.on('reconnect_failed', () => {
      console.log('Reconnection Failed');
    });
    this.socket.on('ping', () => {
      console.log('ping packet is written out to the server');
    });
    this.socket.on('pong', (latency: any) => {
      console.log('pong is received from the server in : ', latency);
    });
	
	/* this.socket.on('time', function(timeString) {
	  el = document.getElementById('server-time')
	  el.innerHTML = 'Server time: ' + timeString;
	}); */
  }
  
  /* public sendUserId = (userid: any) => {
    this.socket.emit('userid', userid);
	return Observable.create((observer: any) => {
      this.socket.on('userdata', (userdata: any) => {
		observer.next(userdata);
      });
    });
  } */
  
  
  public sendDocId = (docid: any) => {
    this.socket.emit('docid', docid);
	return Observable.create((observer: any) => {
      this.socket.on('docid', (userdata: any) => {
		observer.next(userdata);
      });
    });
  }
  
  public sendPatId = (patid: any) => {
    this.socket.emit('patid', patid);
	return Observable.create((observer: any) => {
      this.socket.on('patid', (userdata: any) => {
		observer.next(userdata);
      });
    });
  }
  
  public getControlPatSocketId = (userdata: any) => {
	this.socket.emit('controlpat', userdata);
    return Observable.create((observer: any) => {
      this.socket.on('controlpat', (userdata: any) => {
		observer.next(userdata);
      });
    });
  }
  
  public getControlDocSocketId = (userdata: any) => {
	this.socket.emit('controldoc', userdata);
    return Observable.create((observer: any) => {
      this.socket.on('controldoc', (userdata: any) => {
		observer.next(userdata);
      });
    });
  }

  public getUsers = () => {
    this.socket.emit('users');
    return Observable.create((observer: any) => {
      this.socket.on('users', (users: any) => {
        observer.next(users);
      });
    });
  }
  
  public getDoctors = () => {
    this.socket.emit('doctors');
    return Observable.create((observer: any) => {
      this.socket.on('doctors', (doctors: any) => {
        observer.next(doctors);
      });
    });
  }
  
  public getPatients = () => {
    this.socket.emit('patients');
    return Observable.create((observer: any) => {
      this.socket.on('patients', (patients: any) => {
        observer.next(patients);
      });
    });
  }
  
  public getmyIceServers = () => {
    this.socket.emit('myiceservers');
    return Observable.create((observer: any) => {
      this.socket.on('myiceservers', (myiceservers: any) => {
        observer.next(myiceservers);
      });
    });
  }
  
  /* public sendmyIceServers = (myiceserver: any) => {
    this.socket.emit('myiceserver', myiceserver);
  }

  public receivemyIceServers = () => {
    return Observable.create((observer: any) => {
      this.socket.on('myiceserver', (myiceserver: any) => {
        observer.next(myiceserver);
      });
    });
  } */
  
  public sendDataCallDoc = (datacalldoc: any) => {
    this.socket.emit('datacalldoc', datacalldoc);
  }

  public receiveDataCallDoc = () => {
    return Observable.create((observer: any) => {
      this.socket.on('datacalldoc', (datacalldoc: any) => {
        observer.next(datacalldoc);
      });
    });
  }
  
  public sendDataCallPat = (datacallpat: any) => {
    this.socket.emit('datacallpat', datacallpat);
  }

  public receiveDataCallPat = () => {
    return Observable.create((observer: any) => {
      this.socket.on('datacallpat', (datacallpat: any) => {
        observer.next(datacallpat);
      });
    });
  }
  
  public sendOffer = (offer: any) => {
    this.socket.emit('offer', offer);
  }

  public receiveOffer = () => {
    return Observable.create((observer: any) => {
      this.socket.on('offer', (offer: any) => {
        observer.next(offer);
      });
    });
  }
  
  public sendMedia = (media: any) => {
    this.socket.emit('media', media);
  }

  public receiveMedia = () => {
    return Observable.create((observer: any) => {
      this.socket.on('media', (media: any) => {
        observer.next(media);
      });
    });
  }
  
  public sendAlert = (alerts: any) => {
    this.socket.emit('alert', alerts);
  }

  public receiveAlert = () => {
    return Observable.create((observer: any) => {
      this.socket.on('alert', (alerts: any) => {
        observer.next(alerts);
      });
    });
  }

  public sendAnswer = (answer: any) => {
    this.socket.emit('answer', answer);
  }

  public receiveAnswer = () => {
    return Observable.create((observer: any) => {
      this.socket.on('answer', (answer: any) => {
        observer.next(answer);
      });
    });
  }

  public sendIceCandidate = (candidate: any) => {
    this.socket.emit('icecandidate', candidate);
  }

  public receiveIceCandidate = () => {
    return Observable.create((observer: any) => {
      this.socket.on('icecandidate', (candidate: any) => {
        observer.next(candidate);
      });
    });
  }

  public sendFile = (file: any) => {
    this.socket.emit('file', file);
  }

  public receiveFile = () => {
    return Observable.create((observer: any) => {
      this.socket.on('file', (file: any) => {
        observer.next(file);
      });
    });
  }
  
  public disconnect = () => {
    this.socket.emit('disconnect');
  }
  
}
