import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-appointment',
  templateUrl: './detail-appointment.component.html',
  styleUrls: ['./detail-appointment.component.css']
})
export class DetailAppointmentComponent implements OnInit {
  appoint: any;

  constructor(private resourceService:ResourceService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getAppoinment(this.route.snapshot.params.id);
  }

  getAppoinment(id){

    this.resourceService.getResources("rdv/patient/"+sessionStorage.getItem('userId')+"/"+id).subscribe((data:any) =>{
      console.log('patient',data);
      this.appoint=data;
      
 
    },err=>{
        console.log('err',err);
    })

  }
}
