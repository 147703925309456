import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
// import  'src/assets/bundles/mainscripts.bundle.js';

@Component({
  selector: 'app-e-pro-user',
  templateUrl: './e-pro-user.component.html',
  styleUrls: ['./e-pro-user.component.css']
})
export class EProUserComponent implements OnInit {

  angForm: FormGroup;
  submitted = false;
  baseurl: string;
  loading = false;
  // user: any = [];

  constructor(private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.createForm();
    this.baseurl = environment.baseUrl;
  }

  createForm() {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required ]
    });
  }

  get f() {
    return this.angForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.angForm.invalid) {
      return;
    }
    let data = {
      password: sha256(this.angForm.value['password']),
      username: this.angForm.value['email']
    }
	
	this.loading = true;
	
    this.resourceService.getsaveResource('connectadmin',data).subscribe(data=>{
      let user: any = [];
      user = data;
      this.loading = false;
      if (user == 'not connected'){
        this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
      }else if(!user){
        this.toastr.error('mot de passe ou email invalide!', 'Connection Fail!');
      }else{
		// Setting token access and session variables
		localStorage.setItem('token', user.access_token);
        user = user.user;
        sessionStorage.setItem('isConnect', 'true');
        sessionStorage.setItem('userId',  user.id);
        sessionStorage.setItem('username',  user.username + ' ' + user.lastname);
        sessionStorage.setItem('avatar',  user.avatar);
        sessionStorage.setItem('email',  user.email);
        sessionStorage.setItem('phone',  user.tel);
        sessionStorage.setItem('role',  user.roles);
        sessionStorage.setItem('type',  user.type);
        sessionStorage.setItem('adress',  user.adress);
        sessionStorage.setItem('town',  user.town);
        sessionStorage.setItem('country',  user.country);
        this.toastr.success('Vous êtes Connecté !', 'Success User');
        this.router.navigate(['/admin/dashboard']);
      }
    },err=>{
      this.toastr.error(Errors(err), "Connection Fail! : Impossible d'etablir une connexion. Veuillez réessayer après quelques secondes ou contacter le service support.");
      console.log('err', err);
    });
  }

}
