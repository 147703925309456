import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { ResourceService } from './resource.service';
@Injectable({
  providedIn: 'root'
})
export class SocketService {
 
  private BASE_URL = environment.socketUrl;
  private socket:any;
  private dataSuject = new Subject<any>();
  constructor(private serviceResoure:ResourceService) {
    this.socket = io(this.BASE_URL); 
   
   }

  
  // connexion de l'utilisateur
  connectSocket(user): void { 
    this.socket.emit("connect-user",JSON.stringify(user));  
  }

// deconnexion de l'utilisateur
logoutSocket(user):void{
  this.socket.emit("logout",user);
}





  // le patient resoit le message du doctor

  public getMessageDoctors = () => {
    return Observable.create((observer) => {
        this.socket.on('response-doctor', (message) => {
            observer.next(message);
        });
    });
  }
  // le docteur resoit le message du patient

 public getMessagePatients = () => {
  return Observable.create((observer) => {
      this.socket.on('response-patient', (message) => {
          observer.next(message);
      });
  });
}



 // le patient envoi un message a un docteur
  sendMessagePatient(data){
    this.socket.emit("message-patient", JSON.stringify(data) );
  }
    // le docteur envoi un message a un patient
    sendMessageDoctor(data){
      this.socket.emit("message-doctor", JSON.stringify(data) );
    }


 





    notif(data) {
      this.socket.emit("notif", JSON.stringify(data) );
    }

    // public getResponseNotif = () => {
    //   return Observable.create((observer) => {
    //       this.socket.on('response-notif', (res) => {
    //           observer.next(res);
    //       });
    //   });
    // }

    getPatientResponseNotif=()  => {
      return Observable.create((observer) => {
          this.socket.on('patient-response-notif', (res) => {
              observer.next(res);
          });
      });
    }

    getDoctorResponseNotif=()  => {
      return Observable.create((observer) => {
          this.socket.on('doctor-response-notif', (res) => {
              observer.next(res);
          });
      });
    }

   


    changeSocket(user){
      this.socket.emit("change-socket", JSON.stringify(user));
    }


    sendMessage( message:any,nbre_message:any){
      this.dataSuject.next({message:message,nbre_message:nbre_message});
    }
    
    getMessage(): Observable<any>{
      return this.dataSuject.asObservable();
    }


    // teleconsultation 
    sendTokenTel(data){
      this.socket.emit("token-telconsultion", JSON.stringify(data));
    }
  

  getTokenTel = () => {
    return Observable.create((observer) => {
      this.socket.on('token-telconsultion-response', (res) => {
        observer.next(res);
      });
    });
  }
    
}
