import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
import {DataTableDirective} from 'angular-datatables';
// declare var $: any;

@Component({
  selector: 'app-list-transaction',
  templateUrl: './list-transaction.component.html',
  styleUrls: ['./list-transaction.component.css']
})
export class ListTransactionComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  dtTrigger: Subject <any> = new Subject();
  dtsTrigger: Subject <any> = new Subject();

  locale: string = 'fr';
  DoctorPaymentSearchForm: FormGroup;
  submitted = false;

  public error;
  public success;
  pays: any;
  payments: any = [];
  payments_doctor: any = [];
  datasearch: any = [];
  doctors: any = [];
  total_amount: any;

  baseUrl: string;
  urls: any;
  url: string = 'payments/all';

  fileData: File = null;
  nameFile:any;

  date_start: any;
  date_end: any;
  doctor_id: any;

  public detailsData =
    {
      id : null,
      transaction_number : '',
      description : '',
      total : '',
      details : '',
      status : '',
      date_created : '',

      patient_id: '',
      patient_name : '',
      doctor_name : '',

      rdv_id : '',
      label : '',
      rdv_description : '',
      type : '',
      notes : '',
      doc_valid : '',
      pat_valid : '',

    };


  constructor(private formBuilder: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService, private c: ConfirmationDialogService) {

    this.DoctorPaymentSearchForm = this.formBuilder.group({
      date_start: new Date(),
      date_end: new Date(),
      doctor_id: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkSession();
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + '/public/files/avatars/avatar.png';
    this.pays = paysdata;
    this.getDoctors();
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
    this.dtsTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  getDoctors(){

    this.resourceService.getResources('doc/all').subscribe(data=>{
      this.doctors = data;
    } ,err=>{
      console.log('erreur',err);
    })
  }

  get f() {
    return this.DoctorPaymentSearchForm.controls;
  }

  public liste() {
    this.resourceService.getResources(this.url).subscribe(data => {

      this.payments = data;
      // this.total_amount = data;
	  console.log(this.payments);
      $('#allpayments').removeAttr('style');
      $('#allpaymentsdoctor').attr('style', 'display:none');

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

      console.log('la taille du tableau est de ' + this.payments.length);

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(payment){

    this.detailsData = {
      id: payment.id,
      transaction_number: payment.transaction_number,
      description: payment.description,
      details: payment.details,
      total: payment.total,
      status: payment.status,
      date_created: payment.date_created,

      patient_id: payment.patient_id,
      patient_name: payment.patient.username + ' ' + payment.patient.lastname,
      doctor_name: payment.rdvs.disponibilities.doctor.name + ' ' + payment.rdvs.disponibilities.doctor.surname,

      rdv_id: payment.rdv_id,
      label: payment.rdvs.label,
      rdv_description: payment.rdvs.description,
      type: payment.rdvs.type,
      notes: payment.rdvs.notes,
      doc_valid: payment.rdvs.doc_valid,
      pat_valid: payment.rdvs.pat_valid

    };

    // if (doctor.cv != null) {
    //   this.nameFile = this.baseUrl + 'public/files/files/' + doctor.cv;
    // }

    console.log(this.detailsData.id);
  }


  public SearchDoctorPayment() {

    this.submitted = true;

    if (this.DoctorPaymentSearchForm.invalid) {
      return;
    }

    this.date_end = this.DoctorPaymentSearchForm.value['date_end'];
    this.date_start = this.DoctorPaymentSearchForm.value['date_start'];
    this.doctor_id = this.DoctorPaymentSearchForm.value['doctor_id'];

    this.resourceService.getResources('payments/alldoc/' + this.date_start + '/' + this.date_end + '/' + this.doctor_id).subscribe(data => {

        this.payments_doctor = data;
      // this.total_amount = data;
      $('#allpayments').attr('style', 'display:none');
      $('#allpaymentsdoctor').removeAttr('style');

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtsTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtsTrigger.next();
      }

      console.log(this.payments_doctor);
      console.log('la taille du tableau est de ' + this.payments_doctor.length);

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }


  public deletePayment(event: MouseEvent, payment) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('/payment/delete', payment.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/payment/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

}
