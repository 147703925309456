import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import paysdata from 'src/app/pays.json';
import { ToastrService } from 'ngx-toastr';
// import { timer } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { Title } from '@angular/platform-browser';


declare var $ :any;

@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.css']
})

export class PatientDashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  specialities: any;
  pays: any;
  doctors: any;
  p: number = 1;
  total: number;
  // source = timer(1000, 2000);
  timer: any;
  doctor: any;
  speciality: any;
  userId: any;


  constructor(private title:Title, private resourceService:ResourceService, private c: ConfirmationDialogService, private toastr: ToastrService, private router:Router) {
    this.title.setTitle('Dashbord');
  }

  ngOnInit() {
    this.pays = paysdata;
   // this.checkSession();
    // this.getTimer();
    this.userId = sessionStorage.getItem('userId');
  }



  ngAfterViewInit(){

  }

  ngOnDestroy() {
    // this.timer.unsubscribe();
  }



  // getTimer(){

  //   this.timer = this.source.subscribe(val => {
  //     if (this.getAppel() != 'None') {
  //       this.timer.unsubscribe();
  //       this.c.confirm('Appel entrant', 'Téléconsutaton').then(confirmed => {

  //         console.log('appel accepter', confirmed);
  //         if (confirmed == true) {
  //           this.router.navigate(["/patient/video-patient"]).then(data => {
  //             this.timer.unsubscribe();
  //           });
  //         } else if (confirmed == false) {
  //           this.router.navigate(["/patient/video-patient"]).then(data => {
  //             this.timer.unsubscribe();
  //           });
  //         }
  //       });
  //     }
  //   });
  // }

  // getAppel() {
  //   let tele:any
  //   this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
  //     tele = data;
  //   });

  //   if(tele && tele.id){
  //     sessionStorage.setItem('roomname',tele.roomname);
  //     this.doctor= tele.doctors.surname+' '+tele.doctors.name;
  //     this.speciality= tele.specialities.name;
  //     return tele.roomname;
  //   }else
  //     return 'None';
  // }

}
