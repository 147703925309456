import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { Patient } from 'src/app/models/patient';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgSelectConfig } from '@ng-select/ng-select';
import paysdata from 'src/app/pays.json';
import { sha256 } from 'js-sha256';

@Component({
  selector: 'app-edit-profile-patient',
  templateUrl: './edit-profile-patient.component.html',
  styleUrls: ['./edit-profile-patient.component.css']
})
export class EditProfilePatientComponent implements OnInit {

  patientForm: FormGroup;
  submitted = false;

  fileData: File = null;
  patient: any;
  timer: any;
  doctor: string;
  speciality: any;
  userId: any;
  baseUrl: string;
  urls: any;
  loading = false;
  pays: any;

  constructor(private config: NgSelectConfig,private c: ConfirmationDialogService, private fb: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService) {
    this.patient={} as Patient;
    this.config.appendTo = 'body';
  }

  ngOnInit() {
    this.pays = paysdata;
    this.userId = sessionStorage.getItem('userId');
    this.baseUrl = environment.baseUrlImage;
  
    this.createForm();
    this.getPatient();
  }

  createForm() {

    this.patientForm = this.fb.group({
      country: ['', Validators.required],
      town: ['', Validators.required],
      postalcode: [''],
      adresse: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      lastname:['', Validators.required],
      date_of_birth:['']
    });
  }

  onSelectFile(events) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = events.target.files[0];
    
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    }
  }

  get f() {
    return this.patientForm.controls;
  }

  onSubmit(){
  
    this.submitted = true;
    if (this.patientForm.invalid) {
      return;
    }else{
     let  formData = new FormData();
      let formValue = this.patientForm.value;
      this.loading = true;
   formData.append('adresse', formValue['adresse']);
      formData.append('country', formValue['country']);
      formData.append('town', formValue['town']);
      formData.append('postalcode', formValue['postalcode']);
      formData.append('username', formValue['username']);
      formData.append('lastname', formValue['lastname']);
     formData.append('date_of_birth', formValue['date_of_birth']);
     if(this.fileData){
       let newName = sha256(this.fileData.name)+'.'+this.fileData.name.split('.').pop();
       formData.append('avatar', this.fileData, newName);
     }
    

      
      this.resourceService.saveResource('patient/update/'+this.userId,formData).subscribe(data=>{
    
        this.toastr.success('Votre compte a été modifié!', 'Sucess');
        this.getPatient();
      },err=>{
          this.toastr.error(Errors(err), 'Major Error');
          console.log('erreur',err);
          this.loading = false;
      },()=>{
          this.loading = false;
      });

    }
  }

  getPatient(){
    this.resourceService.getResource('patient', this.userId).subscribe(data=>{ 
    this.patient=data; 
      this.resourceService.sendData(this.patient);
      this.urls = this.baseUrl + this.patient.avatar;

    },err=>{
          console.log('erreur',err);
    });
  }

}
