import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { timer, Subject } from 'rxjs';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import  'src/assets/bundles/mainscripts.bundle.js';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarView, CalendarEventAction } from 'angular-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Shedule } from 'src/app/models/schedule';
import { Doctor } from 'src/app/models/doctor';
import { formatDate } from '@angular/common';

const colors: any = {
 
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
declare var $: any;

@Component({
  selector: 'app-view-doctor',
  templateUrl: './view-doctor.component.html',
  styleUrls: ['./view-doctor.component.css']
})
export class ViewDoctorComponent implements OnInit, OnDestroy {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  // date picker options
  
  DAY = 86400000;
  locale: string = 'fr';

  events: CalendarEvent[] = [];

  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  disponibility: Shedule[] = [];
  id: any;

  
  doctor: any;
  speciality: any;
  userId: any;
  baseurl: string;
  doctor_id: any;
  schedule: any;
  pc2: RTCPeerConnection;

  constructor(private modal: NgbModal, private router: Router, private route: ActivatedRoute, private c: ConfirmationDialogService, private toastr: ToastrService, private resourceService:ResourceService) {
    this.schedule = {} as Shedule;
  }

  ngOnInit() {
    this.doctor = {} as Doctor;
    this.doctor_id = this.route.snapshot.params.id;
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl + 'public/';
    this.getDoctor();
 
  //  this.checkSession();
    this.getDisponibilities();
  }

  ngOnDestroy() {
 
  }  

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

 
  getAppel() {
    let tele:any="";
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    if(tele && tele.id){
      sessionStorage.setItem('roomname',tele.roomname);
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    } else
      return 'None';
  }

  getDisponibilities() {
    this.resourceService.getResources('dispo/doclistall/bypatient/'+this.doctor_id).subscribe(data => {
      let disponibilities:any=data;
      for (let index = 0; index < disponibilities.length; index++) {
        this.events = [
          this.mapOject(disponibilities[index]),
          ...this.events
        ]
      }
    }, err => {
    });
  }

  mapOject(event) {
    let start; let end;

      start=  new Date(event.start_time);
      end= new Date(event.end_time);

    return {
      id: event.id,
      type: event.type,
      title: event.type+' '+formatDate(start, ': dd/MM/yyyy H:mm', 'en-US')+' '+formatDate(end, '--> dd/MM/yyyy H:mm', 'en-US'),
      start: new Date(start),
      end: new Date(end),
      color: colors.blue,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      }
    }
  }

  getDoctor() {
    this.resourceService.getResource('doc/find',this.doctor_id).subscribe(data=>{
      this.doctor = data;
      console.log('doctor',this.doctor);
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('err', err);
    });
  }

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  confirm(event) {
    this.router.navigate(["/patient/reservation/doctor/"+this.doctor_id+"/"+event.id])
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

 


 

 
}
