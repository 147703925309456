import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import {Patient} from "src/app/models/patient";
import {Router} from "@angular/router";
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import {ConfirmationDialogService} from 'src/app/ui/notifications/notification.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
declare var swal: any;

@Component({
  selector: 'app-list-patient',
  templateUrl: './list-patient.component.html',
  styleUrls: ['./list-patient.component.css']
})
export class ListPatientComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newPatient: Patient;
  patientFormEdit: FormGroup;
  patients: any = [];
  // formData = new FormData();
  fileData: File = null;
  baseUrl: string;
  urls: any;
  // url: string = '/patient';
  public error;
  public success;
  pays: any;
  date: any;

  public detailsData = {
    id : null,
    username : '',
    lastname : '',
    date_of_birth : '',
    postalcode : '',
    phone : '',
    adress : '',
    email : '',
    town : '',
    country : '',
    status : '',
    last_connexion : '',
    avatar : ''
  };
  baseurl: string;

  //card_number?:string,

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newPatient = {} as Patient; }

  ngOnInit() {

    this.initFormEdit();
    this.baseurl = environment.baseUrl+'public/';
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + 'public/files/avatars/avatar.png';
    this.pays = paysdata;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }


  initFormEdit() {
    this.patientFormEdit = this.formBuilder.group({
      id: [''],
      checkbox: ['', Validators.required],
      username: ['', Validators.required],
      lastname: [''],
      date_of_birth: ['', Validators.required],
      postalcode: [''],
      phone: ['', Validators.required],
      adress: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      town: ['', Validators.required],
      country: ['', Validators.required]
    });
  }

  get f1() { return this.patientFormEdit.controls; }

  public liste() {
    this.resourceService.getResources('patient/list/all').subscribe(data => {

      this.patients = data;

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

    }, error => {
      if(error.status == 500 || error.status == 503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onView(patient) {

    this.detailsData = {
      id: patient.id,
      username: patient.username,
      lastname: patient.lastname,
      date_of_birth: patient.date_of_birth,
      postalcode: patient.postalcode,
      phone: patient.phone,
      adress: patient.adresse,
      email: patient.email,
      town: patient.town,
      country: patient.country,
      status : patient.status,
      last_connexion: patient.last_connexion,
	    avatar: this.baseUrl + 'public' + patient.avatar
      // avatar: patient.avatar
    };

    // if (patient.avatar != null) {
    //   this.urls = this.baseUrl + 'public/files/avatars/' + patient.avatar;
    // }
  }

  public onEdit(patient) {
    this.patientFormEdit.patchValue({
      id: patient.id,
      checkbox: patient.checkbox,
      username: patient.username,
      lastname: patient.lastname,
      date_of_birth: patient.date_of_birth,
      postalcode: patient.postalcode,
      phone: patient.phone,
      adress: patient.adresse,
      email: patient.email,
      town: patient.town,
      country: patient.country,
	    avatar: this.baseUrl + 'public' + patient.avatar
	    // avatar: 'public' + patient.avatar
      // avatar: this.baseUrl + 'public' + patient.avatar
    });

    // if (patient.avatar != null) {
    //  this.urls = this.baseUrl + 'files/avatars/' + patient.avatar;
    //   this.formData.append('avatar', patient.avatar);
    // }
  }

  public updatePatient(){
    if (this.patientFormEdit.invalid) {
      return;
    }

	this.date = new Date();
    let formData = new FormData();
    let formValue = this.patientFormEdit.value;
	  // const formValue = this.patientFormEdit.value;
    formData.append('id', formValue['id']);
    formData.append('username', formValue['username']);
    formData.append('lastname', formValue['lastname']);
    formData.append('date_of_birth', formValue['date_of_birth']);
    formData.append('email', formValue['email']);
    formData.append('phone', formValue['phone']);
    formData.append('adresse', formValue['adress']);
    formData.append('postalcode', formValue['postalcode']);
    formData.append('town', formValue['town']);
    formData.append('country', formValue['country']);
	  formData.append('date_updated', this.date);
    // formData.append('status', '0');
    // formData.append('connected', '0');
    // formData.append('date_created', this.date);
    // formData.append('last_connexion', this.date);

    if(this.fileData){
		  formData.append('avatar', this.fileData);
    }

    this.resourceService.updateResource('pat/update/' + formValue['id'], formData).subscribe(data => {
      this.resetForm(this.patientFormEdit);
      this.closemodal.nativeElement.click();
      this.liste();
      this.toastr.success('Le patient a été mis à jour!', 'Sucess');

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
      if(error.status==400){

        this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData );
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {
    }
  }


  public resetForm(form){
    form.reset();
  }


  public  deletePatient(event: MouseEvent, patient) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('patient/del', patient.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/patient/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  // public showCancelMessage(event: MouseEvent, patient) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deletePatient(event,patient);
  //       swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "Your data is safe.", "error");
  //     }
  //   });
  // }



  // public showDeleteAllMessage(event: MouseEvent) {
  //   swal({
  //     title: 'Are you sure?',
  //     text: 'Do you want to delete all datas? This action is irreversible!',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#DD6B55',
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, cancel please!',
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function(isConfirm) {
  //     if (isConfirm) {
  //
  //       //insert the method to delete all datas
  //       //this.deletePatient(event,patient);
  //
  //       swal('Deleted!', 'The datas has been successfully deleted.', 'success');
  //     } else {
  //       swal('Cancelled', 'The datas are safe.', 'error');
  //     }
  //   });
  // }

}
