import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-message',
  templateUrl: './support-message.component.html',
  styleUrls: ['./support-message.component.css']
})
export class SupportMessageComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  

  }





 


}
