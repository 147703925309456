import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.css']
})
export class SidebarAdminComponent implements OnInit {

  avatar: string;
  userDisplayName = '';
  userId = '';
  email = '';
  phone = '';
  type = '';
  roles = '';
  adress = '';
  town = '';
  country = '';
  baseurl: string;

  constructor() { }

  ngOnInit() {
    this.userDisplayName = sessionStorage.getItem('username');
    this.userId = sessionStorage.getItem('userId');
    this.email = sessionStorage.getItem('email');
    this.phone = sessionStorage.getItem('phone');
    this.type = sessionStorage.getItem('type');
    this.roles = sessionStorage.getItem('roles');
    this.adress = sessionStorage.getItem('adress');
    this.town = sessionStorage.getItem('town');
    this.country = sessionStorage.getItem('country');
    this.avatar = sessionStorage.getItem('avatar');
    this.baseurl = environment.baseUrl+'public/';
  }
}
