import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-doctor-message-view',
  templateUrl: './doctor-message-view.component.html',
  styleUrls: ['./doctor-message-view.component.css']
})
export class DoctorMessageViewComponent implements OnInit {

  doctor: string;
  speciality: any;
  userId: any;
  message: any;
  baseurl: string;

  angForm: FormGroup;
  doctor_id: any;
  messages_context: string;
  msg: any;
  total: any;
  p: number;
  selectedAll: any;
  checkArray: any;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private c: ConfirmationDialogService, private toastr: ToastrService, private resourceService:ResourceService) { 
  }

  ngOnInit() {
  //  this.checkSession();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.messages_sent(1,"doc");

  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  // appeler cette methode dans le ngOnInit
  messages_sent(page:number,type){
    this.messages_context = 'Messages Envoyes';
    this.resourceService.getResources('messagedoc/'+this.userId+'/'+page+'/'+type).subscribe(data=>{
      let datas:any = data;
      this.msg=datas.rows; 
      this.total = datas.count;
      this.p=page;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');

    })
  }

  checked(key){
    this.resourceService.getResources('message/search/'+this.userId+'/'+key).subscribe(data=>{
      let datas:any = data;
      this.msg=datas;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  }
  
  selectAll() {
    for (var i = 0; i < this.msg.length; i++) {
      this.msg[i].selected = this.selectedAll;
    }
  }
  
  checkIfAllSelected() {
    this.selectedAll = this.msg.every(function(item:any) {
        return item.selected == true;
      })
  }

  deleteMessage(){
    let msgs=[];
    for (var i = 0; i < this.msg.length; i++) {
      if(this.msg[i].selected){
        msgs = [this.msg[i].id,...msgs];
      }
    }
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer ces lignes... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.getsaveResource('messagedoc/delete',msgs).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }
 

}
