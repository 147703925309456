import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { Doctor } from 'src/app/models/doctor';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar-medecin',
  templateUrl: './sidebar-medecin.component.html',
  styleUrls: ['./sidebar-medecin.component.css']
})
export class SidebarMedecinComponent implements OnInit,OnDestroy,AfterViewInit {


  userId :any;
 
  baseUrl= environment.baseUrlImage;
  public url:any;
  doctor:any;
  subs:Subscription
  urls: string;

  constructor( private serviceResource: ResourceService, private translateService: TranslateService) { 
    this.doctor={} as Doctor;
    this.userId = sessionStorage.getItem('userId');
    this.subs=this.serviceResource.getData().subscribe(data=>{
      this.doctor=data;

    });
  }

  ngOnInit() {  
    
 
  }
  ngAfterViewInit(){
    this.getDoctor();
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  getDoctor() {
    this.serviceResource.getResource('doc/findhimself', this.userId).subscribe(data => {
      this.doctor = data;
    }, err => {
      console.log('erreur', err);
    });
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
  }
}
