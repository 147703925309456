import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';
import { Errors } from 'src/app/settings/function_errors';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-patient-message-view',
  templateUrl: './patient-message-view.component.html',
  styleUrls: ['./patient-message-view.component.css']
})
export class PatientMessageViewComponent implements OnInit, OnDestroy {

  timer: any;
  public source = timer(1000, 2000);
  doctor: string;
  speciality: any;
  userId: any;
  message: any;
  baseurl: string;

  angForm: FormGroup;
  doctor_id: any;
  messages_context: string;
  msg: any;
  total: any;
  p: number;
  selectedAll: any;
  checkArray: any;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private c: ConfirmationDialogService, private toastr: ToastrService, private resourceService:ResourceService) { 
  }

  ngOnInit() {
   // this.checkSession();
    this.getTimer();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.messages_sent(1,"pat");

  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getTimer(){
    this.timer = this.source.subscribe(val => {
      if (this.getAppel() != 'None') {
        this.timer.unsubscribe();
        this.c.confirm('Appel entrant', 'Téléconsutaton').then(confirmed => {

          console.log('appel accepter', confirmed);
          if (confirmed == true) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          } else if (confirmed == false) {
            this.router.navigate(["/patient/video-patient"]).then(data => {
              this.timer.unsubscribe();
            });
          }
        });
      }
    });
  }

  getAppel() { 
    let tele:any
    this.resourceService.getResource('patient/findTeleconsultation',this.userId).subscribe(data=>{
      tele = data;
    });
    if(tele && tele.id){
      sessionStorage.setItem('roomname',tele.roomname);
      this.doctor= tele.doctors.surname+' '+tele.doctors.name;
      this.speciality= tele.specialities.name;
      return tele.roomname;
    }else
      return 'None';
    
  }

  // appeler cette methode dans le ngOnInit
  messages_sent(page:number,type){
    this.messages_context = 'Messages Envoyes';
    this.resourceService.getResources('message/'+this.userId+'/'+page+'/'+type).subscribe(data=>{
      let datas:any = data;
      this.msg=datas.rows;
      this.total = datas.count;
      this.p=page;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');

    })
  }

  checked(key){
    this.resourceService.getResources('message/search/'+this.userId+'/'+key).subscribe(data=>{
      let datas:any = data;
      this.msg=datas;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  }
  
  selectAll() {
    for (var i = 0; i < this.msg.length; i++) {
      this.msg[i].selected = this.selectedAll;
    }
  }
  
  checkIfAllSelected() {
    this.selectedAll = this.msg.every(function(item:any) {
        return item.selected == true;
      })
  }

  deleteMessage(){
    let msgs=[];
    for (var i = 0; i < this.msg.length; i++) {
      if(this.msg[i].selected){
        msgs = [this.msg[i].id,...msgs];
      }
    }
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer ces lignes... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.getsaveResource('message/delete',msgs).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }
 
}
