import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
import {DataTableDirective} from 'angular-datatables';

@Component({
  selector: 'app-list-rdv',
  templateUrl: './list-rdv.component.html',
  styleUrls: ['./list-rdv.component.css']
})
export class ListRdvComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();
  dtsTrigger: Subject <any> = new Subject();

  locale: string = 'fr';

  DoctorRdvListForm: FormGroup;
  submitted = false;

  public error;
  public success;
  pays: any;
  rdvs: any = [];
  rdvs_doc: any = [];
  datasearch: any = [];
  doctors: any = [];
  total_amount: any;

  baseUrl: string;
  urls: any;
  url: string = 'rdv/all';

  fileData: File = null;
  nameFile:any;

  date_start: any;
  date_end: any;
  doctor_id: any;

  public detailsData =
    {
      id: null,
      label: '',
      description: '',
      type: '',
      notes: '',
      doc_valid: '',
      pat_valid: '',
      date_hours: '',
      date_created: '',
      date_updated: '',
      patient_id: '',
      patient_name: '',
      beneficials_id: '',
      beneficials_name: '',
      doctor_id: '',
      doctor_name: '',
      // transaction_number: '',
      // total: '',
      // status: ''
    };

  constructor(private formBuilder: FormBuilder, private resourceService: ResourceService, private router: Router, private toastr: ToastrService, private c: ConfirmationDialogService) {

    this.DoctorRdvListForm = this.formBuilder.group({
      date_start: new Date(),
      date_end: new Date(),
      doctor_id: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.checkSession();
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + '/public/files/avatars/avatar.png';
    this.pays = paysdata;
    this.getDoctors();
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
    this.dtsTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  getDoctors(){

    this.resourceService.getResources('doc/all').subscribe(data=>{
      this.doctors = data;
    } ,err=>{
      console.log('erreur',err);
    })
  }

  get f() {
    return this.DoctorRdvListForm.controls;
  }

  public liste() {
    this.resourceService.getResources(this.url).subscribe(data => {
	  this.rdvs = data;
	  console.log(this.rdvs);
      $('#allrdvs').removeAttr('style');
      $('#allrdvsdoctor').attr('style', 'display:none');

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }


  public onView(rdv){

    this.detailsData = {
      id: rdv.id,
      label: rdv.label,
      description: rdv.description,
      type: rdv.type,
      notes: rdv.notes,
      doc_valid: rdv.doc_valid,
      pat_valid: rdv.pat_valid,
      date_hours: rdv.date_hours,
      date_created: rdv.date_created,
      date_updated: rdv.date_updated,
      patient_id: rdv.patient.id,
      patient_name: rdv.patient.username + ' ' + rdv.patient.lastname,
      beneficials_id: rdv.beneficial.id,
      beneficials_name: rdv.beneficial.name + ' ' + rdv.beneficial.surname,
      doctor_id: rdv.disponibility.doctor.id,
      doctor_name: rdv.disponibility.doctor.name + ' ' + rdv.disponibility.doctor.surname,
      // transaction_number: rdv.payments.transaction_number,
      // total: rdv.payments.total,
      // status: rdv.payments.status
    };

    console.log(this.detailsData.id);
  }

  public SearchDoctorRdv() {

    this.submitted = true;

    if (this.DoctorRdvListForm.invalid) {
      return;
    }
	
    this.date_end = this.DoctorRdvListForm.value['date_end'];
    this.date_start = this.DoctorRdvListForm.value['date_start'];
    this.doctor_id = this.DoctorRdvListForm.value['doctor_id'];
	
	alert(this.doctor_id);
	
    this.resourceService.getResources('rdv/doc/' + this.date_start + '/' + this.date_end + '/' + this.doctor_id).subscribe(data => {
      this.rdvs_doc = data;
	  console.log(this.rdvs_doc);
      // this.total_amount = data;
      $('#allrdvs').attr('style', 'display:none');
      $('#allrdvsdoctor').removeAttr('style');

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtsTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtsTrigger.next();
      }

      console.log('la taille du tableau est de ' + this.rdvs_doc.length);

    }, error => {
      if(error.status==500 || error.status==503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur',error);
        console.log(Errors(error));
      }
    });
  }

  public  deleteRDV(event: MouseEvent, rdv) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('/rdv/delete', rdv.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/rdv/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

}
