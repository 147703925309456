import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Department } from 'src/app/models/department';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css']
})
export class AddDepartmentComponent implements OnInit {

  newDepartment:Department;
  departmentForm: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
    this.newDepartment={} as Department;
  }

  ngOnInit() {
    this.checkSession();
    this.createForm();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  createForm() {
    this.departmentForm = this.fb.group({
      name: ['', Validators.required],
      description: ['']
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.departmentForm.invalid ) {
      return;
    } else {

      let formValue = this.departmentForm.value;
      this.newDepartment.id = null;
      this.newDepartment.name = formValue['name'];
      this.newDepartment.description = formValue['description'];

      // Save datas create role in database
      this.resourceService.saveResource('speciality/create', this.newDepartment).subscribe((data) => {

        console.log('data', data);
        this.departmentForm.reset();
        this.toastr.success('La spécialité a été crée!', 'Sucess');
        this.router.navigate(['/admin/confirm/action']);

      }, err => {
        console.log('erreur');
        this.toastr.error(Errors(err), err.status);
        console.log('erreur',err);
        console.log(Errors(err));
      });

    }
  }

  get f1() {
    // console.log(this.departmentForm.controls);
    return this.departmentForm.controls;
  }

}
