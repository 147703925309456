import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { timer, Subject, Subscription } from 'rxjs';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { SocketserviceService } from 'src/app/services/socketservice.service';
import { SocketService } from 'src/app/services/socket.service';
import { NgxLoadingModule } from 'ngx-loading';

@Component({
  selector: 'app-teleconsultation-doctor',
  templateUrl: './teleconsultation-doctor.component.html',
  styleUrls: ['./teleconsultation-doctor.component.css']
})
export class TeleconsultationDoctorComponent implements OnInit, AfterViewInit, OnDestroy {
  
  public subscription: Subscription;
  public userId: any;
  public tele:any;
  public baseurl: string;
  public date: any;
  public dtTrigger: Subject<any> = new Subject();
  public patient: any = [];
  public roomname: any;
  public myIceServers: any = '';
  public responsedata: any = '';
  public telec: any = '';
  public loading = false;

  constructor(private router: Router, private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService, private resourceService:ResourceService, private socketservice: SocketserviceService, private socketServive:SocketService) {

    this.config.backdrop = 'static';
    this.config.keyboard = false;
	
	this.subscription = this.socketservice.receiveDataCallPat().subscribe(async (responsedata: any) => {
        this.responsedata = responsedata;
		this.responseCall (this.responsedata);
    });
  }

  ngOnInit() {
  //  this.checkSession();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
  }

  ngAfterViewInit(){
    this.getRdvs();
  }
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }
  
  public responseCall(response)
  {
	console.log ('response', response);
	
	this.patient = JSON.parse(sessionStorage.getItem('patient'));
	
	if (response.status == 1) {
		// this.loading = false;
		this.toastr.success("Votre Patient " + this.patient.name + " a accepté l'appel. Vous allez être rediriger dans un instant...", "Appel Accepté !");
		this.router.navigate(["/doctor/view-teleconsultation-doctor"]);	
	}
	else if (response.status == 0) {
		this.loading = false;
		this.toastr.warning("Votre Patient " + this.patient.name + " a rejeté l'appel. Veuillez réessayer...", "Appel Rejeté !");
	} 
	else {
		this.loading = false;
		this.toastr.warning("Votre Patient " + this.patient.name + " n'a pas décrochez. Veuillez réessayer...", "Appel Manqué !");	
	}	
  }

  getRdvs() {
    this.resourceService.getResource('rdv/alldoc/tele',this.userId).subscribe(data=>{
      this.tele = data;
      this.dtTrigger.next();
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    });
  }

  viewteleconsultation(item) {

	let curdate:any = new Date();
	let posdate = new Date(item.date_hours);
	var duration = curdate.valueOf() - posdate.valueOf();

	this.myIceServers = '';

	// check if exist this teleconsultation in database
	this.resourceService.getResources('patient/findTelec/' + item.patient.id + '/' + this.userId).subscribe(data => {
		this.telec = data;

		if (this.telec == null)
		{
				if(duration < 1)
				{
					if (item.patient.connected == '1')
					{
						// get socket myIceServers
						this.socketservice.getmyIceServers().subscribe((myiceservers: any) => {
							this.myIceServers = myiceservers;

							console.log('iceserver :', this.myIceServers);
						
							// set myIceServers in localStorage
							localStorage.setItem('ics', JSON.stringify(this.myIceServers));
						
						let datas = {
							iceserver: JSON.stringify(this.myIceServers),
							idrdv: item.id,
							doctor_id: this.userId,
							patient_id: item.patient.id,
							date: new Date(),
							status:0
						}

						this.patient = {
							idrdv: item.id,
							id: item.patient.id,
							name: item.patient.username + ' ' + item.patient.lastname,
							avatar: item.patient.avatar
						}

						sessionStorage.setItem('patient', JSON.stringify(this.patient));
						
						//send datas call teleconsultation
						this.socketservice.sendDataCallDoc(datas);
						
						//save data call teleconsultation
						this.resourceService.saveResource('patient/writeTeleconsultation', datas).subscribe(data=>{
							
							this.toastr.success("Appel en cours. Veuillez patienter...", "Tentative de connexion au serveur !");
							// this.toastr.success("Votre Patient " + item.patient.username + " " + item.patient.lastname + " est déjà disponible. Veuillez démarrer la téléconsultation.", "Disponible");
							// this.router.navigate(["/doctor/view-teleconsultation-doctor"]);
						
							// loading control timeout
							this.loading = true;
							/* setTimeout(function() {
							  $('.loader').fadeOut();
							}, 1000); */
							
						},err=>{
							this.toastr.error(Errors(err), 'Major Error');
							console.log('erreur',err);
						});
					  });
					}
					else {
						this.toastr.warning("Votre Patient " + item.patient.username + " " + item.patient.lastname + " n'est pas connecté à l'instant. Veuillez réessayer plus tard.", "Indisponible !");
					}
				}
				else {
					this.toastr.warning("L'échéance de votre téléconsultation est depassée. Veuillez contacter le service support.", 'Expiration');
				}
			
		}
		else {
				if(duration < 1)
				{
					if (item.patient.connected == '1')
					{
						// set myIceServers in localStorage
						localStorage.setItem('ics', this.telec.iceserver);
						
						let datas = {
							iceserver: this.telec.iceserver,
							idrdv: item.id,
							doctor_id: this.userId,
							patient_id: item.patient.id,
							date: new Date(),
							status:0
						}
						
						this.patient = {
							idrdv: item.id,
							id: item.patient.id,
							name: item.patient.username + ' ' + item.patient.lastname,
							avatar: item.patient.avatar
						}
						
						sessionStorage.setItem('patient', JSON.stringify(this.patient));
						
						//send datas call teleconsultation
						this.socketservice.sendDataCallDoc(datas);
						this.toastr.success("Appel en cours. Veuillez patienter...", "Tentative de connexion au serveur !");
						// this.toastr.success("Votre Patient " + item.patient.username + " " + item.patient.lastname + " est déjà disponible. Veuillez démarrer la téléconsultation.", "Disponible");
						// this.router.navigate(["/doctor/view-teleconsultation-doctor"]);
						
						// loading control timeout
						this.loading = true;
						/* setTimeout(function() {
							$('.loader').fadeOut();
						}, 1000); */
					}
					else {
						this.toastr.warning("Votre Patient " + item.patient.username + " " + item.patient.lastname + " n'est pas connecté à l'instant. Veuillez réessayer plus tard.", "Indisponible !");
					}
				}
				else {
					this.toastr.warning("L'échéance de votre téléconsultation est depassée. Veuillez contacter le service support.", 'Expiration');
				}
			}
		}, err => {
			this.toastr.error(Errors(err), 'Major Error');
			console.log('erreur',err);
		});
	
  }
  
  // set myIceServers in localStorage
				/* localStorage.setItem('ics', JSON.stringify(this.myIceServers));

				if(duration < 1)
				{
					if (item.patient.connected == '1')
					{
						let data = {
							iceserver: JSON.stringify(this.myIceServers),
							doctor_id: this.userId,
							patient_id: item.patient.id,
							date: new Date(),
							status:0
						}

						this.patient = {
							id: item.patient.id,
							name: item.patient.username + ' ' + item.patient.lastname,
							avatar: item.patient.avatar
						}

						sessionStorage.setItem('patient', JSON.stringify(this.patient));

						this.resourceService.saveResource('patient/writeTeleconsultation', data).subscribe(data=>{
							this.toastr.success("Votre Patient " + item.patient.username + " " + item.patient.lastname + " est déjà disponible. Veuillez démarrer la téléconsultation.", "Disponible");
							this.router.navigate(["/doctor/view-teleconsultation-doctor"]);
						},err=>{
							this.toastr.error(Errors(err), 'Major Error');
							console.log('erreur',err);
						});
					}
					else {
						this.toastr.warning("Votre Patient " + item.patient.username + " " + item.patient.lastname + " n'est pas connecté à l'instant. Veuillez réessayer plus tard.", "Indisponible !");
					}
				}
				else {
					this.toastr.warning("L'échéance de votre téléconsultation est depassée. Veuillez contacter le service support.", 'Expiration');
				} */


  initiateMeeting(id_pat,date){
    let curdate:any = new Date();
    let posdate = new Date(date);
    var duration = curdate.valueOf() - posdate.valueOf();
    if(duration<1){
        let data = {
          roomname: 'Teleconsultation'+sha256(this.userId),
          doctor_id: this.userId,
          patient_id: id_pat,
          date: new Date(),
          status:0
        }
        let datas={
          token: 'Teleconsultation' + sha256(this.userId),
          doctor_id: this.userId,
          patient_id: id_pat,
          type:"doctor"
        }
      this.socketServive.sendTokenTel(datas);

        sessionStorage.setItem('roomname','Teleconsultation'+sha256(this.userId));
      this.router.navigate(["/doctor/video-doctor"]);
        // this.resourceService.saveResource('patient/writeTeleconsultation',data).subscribe(data=>{
        //   this.toastr.success('Teleconsultation lance', 'Sucess');

        // },err=>{
        //     this.toastr.error(Errors(err), 'Major Error');
        //     console.log('erreur',err);
        // });
    }else{
      this.toastr.error('Echeance deja depassee', 'Major Error');
    }
  }

}
