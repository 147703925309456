import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatetimepickerService {
  times: any;
  dayaHours: any;
  minuit = 1440;
  cabinet_teleconsultation=15;
  step=15;
  constructor() { }
  // fonction qui permet de convertier l'heure en chaine de caractere "HH:mm"
  public convertMinutesToTimeFormat(mins) {
    let h: string | number = Math.floor(mins / 60);
    let m: string | number = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}:00`;
  }
  // fonction qui pemert convertir l'heure au format anglais
  formatTime(time) {
    const H = +time.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? " AM" : " PM";
    return h + time.substr(2, 3) + ampm;
  }

  howManyMinutesPassed(time) {
    const [hour, minutes] = time.split(":").map(value => parseInt(value, 10));
    return hour * 60 + minutes;
  }

  // fonction qui liste les heures sur un interval donne
  getHours(step, yourTime) {
    if (!step) {
      return [];
    }
    const slotArray = [];
    const startMinutes = yourTime ? this.howManyMinutesPassed(yourTime) : 0;
    console.log('startMinutes', startMinutes);
    const parsedSlotSize = parseInt(step.toString(), 10);
    console.log('parsedSlotSize', parsedSlotSize);

    for (let i = startMinutes; i <= 24 * 60; i += parsedSlotSize) {
      let t = this.convertMinutesToTimeFormat(i);
      if (i == this.minuit) {
        t = "00:00:00";
      }
      slotArray.push({
        time: t,
        minutes: i
      });
    }



    return [...slotArray];
  }

  // convertir la date au format yyyy-MM-dd
  convertiDate(date:any){
    let sm = date.getMonth();
    let sy = date.getFullYear();
    let sd = date.getDate();
    return new Date(sy, sm, sd);
  }
// fonction qui compare deux dates
compareTwoDates(date1:any,date2:any ){
  // date selectionnee
  let sm = date1.getMonth();
  let sy = date1.getFullYear();
  let sd = date1.getDate();
  let dateSlect = new Date(sy, sm, sd);

  // date courant
  let cd = date2.getDate();
  let cm = date2.getMonth();
  let cy = date2.getFullYear();
  let dateCurent = new Date(cy, cm, cd);
  return dateSlect.getTime() - dateCurent.getTime();
}

// 

// cet fonction prend en parametre la date selectionner par l'utilisateur
  calculHour(date: any) {
    // si la date choisie et la date actuelle egale a 0 on ajoute 2 ou 3 heures d'ecart sur l'heure
    console.log('deux date', this.compareTwoDates(date, new Date));
    if ( this.compareTwoDates(date,new Date) == 0) {
      let min: any = new Date().getMinutes();
      let hr: any = new Date().getHours();
      console.log(hr);
      if (min == 0) {
        hr = hr + 2;
      } else {
        hr = hr + 3;
      }
      min = "00";
      this.times = hr.toString() + ':' + min.toString();
    } else {
      this.times = "00:"+this.step;
    }
  return  this.getHours(this.step, this.times);
   

  }
}
