import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ResourceService } from 'src/app/services/resource.service';
import { Doctor } from 'src/app/models/doctor';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
@Component({
  selector: 'app-doctorpaymentmode',
  templateUrl: './doctorpaymentmode.component.html',
  styleUrls: ['./doctorpaymentmode.component.css']
})
export class DoctorpaymentmodeComponent implements OnInit {
  Form: any;
  submitted: any;
  loading = false;
  dtTrigger: Subject<any> = new Subject();
  doctor:any;
  userId: any;
  constructor(private fb: FormBuilder, 
              private resoureService:ResourceService,
              private toastr: ToastrService) {
                this.doctor={} as Doctor;
               }

  ngOnInit() {
    this.createForm();
    this.userId = sessionStorage.getItem("userId");
    this.getDoctor();

  }
  createForm() {
    this.Form = this.fb.group({
      cover_swift: ['', Validators.required],
      iban_code: ['', Validators.required],
      account_number: ['', Validators.required],
      rib_key: ['', Validators.required],
      account_owner: ['', Validators.required],
    });
  }

  get f() {
    return this.Form.controls;
  }

  Onsubmit() {
    this.submitted = true;

    if (this.Form.invalid) {
      console.log(this.Form.controls);
      return;
    }
    this.loading = true;
    let formValue = this.Form.value;
    let formData = new FormData();
    formData.append('iban_code', formValue['iban_code']);
    formData.append('cover_swift', formValue['cover_swift']);
    formData.append('rib_key', formValue['rib_key']);
    formData.append('account_number', formValue['account_number']);
    formData.append('account_owner', formValue['account_owner']);
    // updateResource_safely
    this.resoureService.updateResource('doc/update/' + this.userId, formData).subscribe(
      data=>{
          this.loading=false;
        this.toastr.success('Modification effectuée', 'Sucess');
      },
      err=>{
        this.loading = false;
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
      }
    )

    

  }

  getDoctor() {
    this.resoureService.getResource('doc/findhimself', this.userId).subscribe(data => {
      this.doctor = data;
      console.log('doctor',this.doctor);
      this.Form.controls['iban_code'].setValue(this.doctor.iban_code);
      this.Form.controls['cover_swift'].setValue(this.doctor.cover_swift);
      this.Form.controls['rib_key'].setValue(this.doctor.rib_key);
      this.Form.controls['account_number'].setValue(this.doctor.account_number);
      this.Form.controls['account_owner'].setValue(this.doctor.account_owner);
    }, err => {
      console.log('erreur', err);
    });
  }

}
