import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Menus } from 'src/app/models/menus';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import {ConfirmationDialogService} from 'src/app/ui/notifications/notification.service';
declare var swal: any;

@Component({
  selector: 'app-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.css']
})
export class ListMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newMenu: Menus;
  menuFormEdit: FormGroup;
  menus: any = [];
  baseUrl: string;
  url: string = '/allmenu';
  public error;
  public success;

  public detailsData =
    {
      id : null,
      label : '',
      menu_path : '',
      menu_parent : ''
    };

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router: Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newMenu = {} as Menus; }

  ngOnInit() {
    this.checkSession();
    this.initFormEdit();
    this.baseUrl = environment.baseUrl;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  initFormEdit() {
    this.menuFormEdit = this.formBuilder.group({
      id: '',
      label: ['', Validators.required],
      menu_path: ['', Validators.required],
      menu_parent: ['']
    });
  }

  get f1() { return this.menuFormEdit.controls; }

  public liste() {
    this.resourceService.getResources('allmenu').subscribe(data => {

      this.menus = data;

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

    }, error => {
      if(error.status == 500 || error.status == 503){

        this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
        console.log('erreur', error);
        console.log(Errors(error));
      }
    });
  }

  public onView(menu){

    this.detailsData =
      {
        id : menu.id,
        label : menu.label,
        menu_path : menu.menu_path,
        menu_parent : menu.menu_id
      };

    console.log(this.detailsData.id);
  }

  public onEdit(menu) {
    this.menuFormEdit.patchValue({
      id : menu.id,
      label :  menu.label,
      menu_path : menu.menu_path,
      menu_parent : menu.menu_id
    });
  }

  public updateMenu(){
    if (this.menuFormEdit.invalid) {
      return;
    }
    const formValue = this.menuFormEdit.value;
    this.newMenu.id = formValue['id'];
    this.newMenu.label = formValue['label'];
    this.newMenu.menu_path = formValue['menu_path'];
    this.newMenu.menu_id = formValue['menu_parent'];

    this.resourceService.updateResource('updatemenu', this.newMenu).subscribe(data => {
        this.resetForm(this.menuFormEdit);
        this.closemodal.nativeElement.click();
        this.liste();
        this.toastr.success('This menu has been update successfully!', 'Sucess');

      }, error => {
        if(error.status == 500 || error.status == 503){

          this.toastr.error(Errors(error), 'Can\'t update, \n');
          console.log('erreur', error);
          console.log(Errors(error));
        }
        if(error.status == 400){

          this.toastr.error(Errors(error), 'Can\'t update, \n');
          console.log('erreur', error);
          console.log(Errors(error));
        }
      });
  }


  public resetForm(form){
    form.reset();
  }


  public  deleteMenu(event: MouseEvent, menu) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('deletemenu', menu.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/deletemenu/all').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  // public showCancelMessage(event: MouseEvent, menu) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deleteMenu(event, menu);
  //       swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "Your data is safe.", "error");
  //     }
  //   });
  // }

  // public showDeleteAllMessage(event: MouseEvent) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to delete all datas? This action is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deleteAllMenus(event);
  //       swal("Deleted!", "The datas has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "The datas are safe.", "error");
  //     }
  //   });
  // }

}

