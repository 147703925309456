import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import paysdata from 'src/app/pays.json';
import {NgSelectConfig} from '@ng-select/ng-select';


@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.css']
})

export class AddDoctorComponent implements OnInit {

  doctorForm: FormGroup;
  submitted = false;
  urls: any = "assets/images/xs/user.png";
  fileData: File = null;
  fileDataCv: File = null;
  fileDataLv: File = null;
  pays: any;
  nameFile:any;
  nameFileLV:any;
  specialities:any;
  baseUrl: string;
  date: any;

  constructor(private config: NgSelectConfig, private fb: FormBuilder, private resourceService:ResourceService, private router:Router, private toastr: ToastrService) {
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
  }

  ngOnInit() {
    this.checkSession();
    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
    this.urls = this.baseUrl + 'public/files/avatars/avatar.png';
    this.createForm();
	  this.getSpecialities();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"]);
    }
  }

  createForm() {

    this.doctorForm = this.fb.group({
      checkbox: ['', Validators.required],
      name: ['', Validators.required],
      surname: [''],
      birthdate: [''],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      adress: ['', Validators.required],
      postalcode: [''],
      town: ['', Validators.required],
      country: [null, Validators.required],
	    registered: ['', Validators.required],
      num_agreement: ['', Validators.required],
      faculty: ['', Validators.required],
      diploma: ['', Validators.required],
      id_speciality: ['', Validators.required],
	    town_faculty: ['', Validators.required],
      country_faculty: ['', Validators.required],
      cv: ['', Validators.required],
      cover_letter: ['', Validators.required],
    });
  }

  checkemail(value){
    this.resourceService.getResources_unsafe('doc/checkemail/'+value).subscribe((data) => {
      if(data == "Done")
        this.toastr.error('Cette adresse e-mail existe deja', 'Error');
    });
  }

  get f1() {
    return this.doctorForm.controls;
  }


  onSubmit() {

    this.submitted = true;

    if (this.doctorForm.invalid  || !this.doctorForm.value['checkbox']) {
      return;
    }else{
      this.date = new Date();
      let formData = new FormData();
      let formValue = this.doctorForm.value;
      formData.append('name', formValue['name']);
      formData.append('surname', formValue['surname']);
      formData.append('birthdate', formValue['birthdate']);
      formData.append('email', formValue['email']);
      formData.append('tel', formValue['tel']);
      formData.append('adress', formValue['adress']);
      formData.append('postalcode', formValue['postalcode']);
      formData.append('town', formValue['town']);
      formData.append('country', formValue['country']);
      formData.append('registered', formValue['registered']);
      formData.append('num_agreement', formValue['num_agreement']);
      formData.append('faculty', formValue['faculty']);
      formData.append('diploma', formValue['diploma']);
	    formData.append('town_faculty', formValue['town_faculty']);
      formData.append('country_faculty', formValue['country_faculty']);
      formData.append('specialities', JSON.stringify(formValue['id_speciality']));
      formData.append('status', '1');
      formData.append('connected', '0');
      formData.append('date_created', this.date);
      // formData.append('date_updated', this.date);
      // formData.append('last_connexion', this.date);

      if(this.fileData){
        formData.append('avatar', this.fileData );
      }

      if(this.fileDataCv){
          formData.append('cv', this.fileDataCv);
        }

      if(this.fileDataLv){
          formData.append('cover_letter', this.fileDataLv);
        }

      // Save datas create user in database
      this.resourceService.saveResource('doctor/create', formData).subscribe((data) => {

        let res:any = data;
        if (res == "Erreur") {
          this.toastr.error('Votre compte ne peut etre crée! E-mail deja existant!', 'Error');
        }else{
          this.doctorForm.reset();
          this.toastr.success('Votre compte a été crée!', 'Sucess');
          this.router.navigate(['/admin/confirm/action']);
        }
      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
        console.log(Errors(err));
      });
    }
  }


  onSelectFile(events, f: NgForm) {

    if (events.target.files && events.target.files[0]) {
      this.fileData = <File>events.target.files[0];
      // this.formData.append('avatar', this.fileData);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urls = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {

    }
  }


  onSelectCV(events, f: NgForm) {
    if (events.target.files && events.target.files[0]) {

      this.nameFile = events.target.files[0].name;
      this.fileDataCv = <File>events.target.files[0];
      // this.formData.append('cv', this.fileDataCv);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // this.nameFile = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    } else {

    }
  }

  onSelectLV(events, f: NgForm) {
    if (events.target.files && events.target.files[0]) {

      this.nameFileLV = events.target.files[0].name;
      this.fileDataLv = <File>events.target.files[0];
      // this.formData.append('cover_letter', this.fileDataLv);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // this.nameFileLV = event.target.result;
      };
      reader.readAsDataURL(events.target.files[0]);
    }
  }

  getSpecialities(){

    this.resourceService.getResources('speciality/listall').subscribe(data=>{
      this.specialities = data;
    } ,err=>{
      console.log('erreur',err);
    })
  }

}
