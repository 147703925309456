import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-patient',
  templateUrl: './view-patient.component.html',
  styleUrls: ['./view-patient.component.css']
})
export class ViewPatientComponent implements OnInit {
  id: any;
  patient: Object;
  baseurl: string;
  p: number = 1;
  files: any;
  total: any;
  userId: string;

  constructor(private router: Router, private route: ActivatedRoute, private resourceService: ResourceService, private toastr: ToastrService) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.getFilePatient(this.p);
    this.getPatient();
  }

 

  getPatient(){

    this.resourceService.getResource('patient/bydoc',this.id).subscribe(data=>{
          this.patient=data; 
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
    })

  }

  // appeler cette methode dans le ngOnInit
  getFilePatient(page:number){
    this.resourceService.getResources('patient/filesbydoc/'+this.id+'/'+this.userId+'/'+page).subscribe(data=>{
      let datas:any = data; console.log(datas);
      this.files=datas.rows;
      this.total = datas.count;
      this.p=page;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');

    })
  }


}
