import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import paysdata from 'src/app/pays.json';
import { environment } from 'src/environments/environment';
import { Doctor } from 'src/app/models/doctor';
@Component({
  selector: 'app-search-doctor-general',
  templateUrl: './search-doctor-general.component.html',
  styleUrls: ['./search-doctor-general.component.css']
})
export class SearchDoctorGeneralComponent implements OnInit {
  specialities: any;
  pays: any;
  doctors: any=[];
  baseurl: string;
  p: number = 1;
  total: number;
  timer: any;
  speciality: any;
  userId: any;
  selectedCountry:any;
  selectedSpeciality: any;
  constructor(private config: NgSelectConfig,private resourceService: ResourceService, ) { 
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
  }

  ngOnInit() {
    this.pays = paysdata;
    this.baseurl = environment.baseUrlImage;
    this.getDoctors(1,'all','all');
    this.getSpecialities();
  }

  onChange() {
    console.log('value speciality', this.selectedSpeciality);
   
    this.getDoctors(this.p, this.selectedSpeciality, 'all');
    if (this.selectedSpeciality==null){
        this.getDoctors(1, 'all', 'all');
      }
  
   
  }
  onChangeCountry(){

    this.getDoctors(this.p, 'all', this.selectedCountry);
    if (this.selectedCountry == null) {
      this.getDoctors(1, 'all', 'all');
    }
  }

  onChangeName(e) {
    if (e.target.value==''){
      this.getDoctors(1, 'all', 'all');
    }else{
      this.getDoctor(e.target.value);
    }
  }

  getSpecialities() {
    this.resourceService.getResources('speciality/listall').subscribe(data => {
      this.specialities = data;
    }, err => {
      console.log('erreur', err);
    })
  }

  getDoctors(page: number, speciality: string, pays: string) {
    this.resourceService.getResources('doc/all/' + page + '/' + speciality + '/' + pays).subscribe(data => {
      let datas: any = data;
      this.doctors = datas.rows;
      this.total = datas.count;
      this.p = page;
      console.log('ovovov',this.doctors);
    }, err => {
      console.log('erreur', err);
    });
  }

  getDoctor(name: string) {
    this.resourceService.getResources('doc/findbyname/' + name).subscribe(data => {
      let tab: any = []; tab.push(data);
      this.doctors = tab;
      this.total = 1;

      console.log('erreur', data);

    }, err => {
      console.log('erreur', err);
    })
  }

  pageChanged(event) {
    this.getDoctors(event, 'all', 'all');

    // setTimeout(function () {
    //   console.log('hide');
    //   this.showElement = false;
    // }, 2000);
  }


}
