import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Pharmacy } from 'src/app/models/pharmacy';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import paysdata from 'src/app/pays.json';
declare var swal: any;

@Component({
  selector: 'app-list-pharmacy',
  templateUrl: './list-pharmacy.component.html',
  styleUrls: ['./list-pharmacy.component.css']
})
export class ListPharmacyComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newPharmacy:Pharmacy;
  pharmacyFormEdit: FormGroup;
  pharmacys: any = [];
  baseUrl: string;
  url: string = 'pharmacie/all';
  public error;
  public success;
  pays: any;

  public detailsData =
    {
      id : null,
      name : '',
      adress : '',
      tel : '',
      email : '',
      country_town : ''
    };

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router:Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newPharmacy={} as Pharmacy; }

  ngOnInit() {
    this.checkSession();
    this.initFormEdit();
    this.pays = paysdata;
    this.baseUrl = environment.baseUrl;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  initFormEdit() {
    this.pharmacyFormEdit = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      adress: ['', Validators.required],
      tel: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country_town: ['', Validators.required]
    });
  }

  get e() { return this.pharmacyFormEdit.controls; }

  public liste() {
    this.resourceService.getResources(this.url).subscribe(data => {

      this.pharmacys = data;

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

      }, error => {
        if(error.status==500 || error.status==503){

          this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      });
  }

  public onView(pharmacy){

    this.detailsData =
      {
        id : pharmacy.id,
        name : pharmacy.name,
        adress : pharmacy.adress,
        tel : pharmacy.tel,
        email : pharmacy.email,
        country_town : pharmacy.country_town
      };

    console.log(this.detailsData.id);
  }

  public onEdit(pharmacy) {
    this.pharmacyFormEdit.patchValue({
      id : pharmacy.id,
      name : pharmacy.name,
      adress : pharmacy.adress,
      tel : pharmacy.tel,
      email : pharmacy.email,
      country_town : pharmacy.country_town
    });
  }

  public updatePharmacy(){
    if (this.pharmacyFormEdit.invalid) {
      return;
    }

    const formValue = this.pharmacyFormEdit.value;
    this.newPharmacy.id = formValue['id'];
    this.newPharmacy.name = formValue['name'];
    this.newPharmacy.adress = formValue['adress'];
    this.newPharmacy.tel = formValue['tel'];
    this.newPharmacy.email = formValue['email'];
    this.newPharmacy.country_town = formValue['country_town'];

    this.resourceService.updateResource('pharmacie/update', this.newPharmacy).subscribe(data => {
        this.resetForm(this.pharmacyFormEdit);
        this.closemodal.nativeElement.click();
        this.liste();
        this.toastr.success('Le departement a été mis à jour!', 'Sucess');

      }, error => {
        if(error.status==500 || error.status==503){

          this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
        if(error.status==400){

          this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      });
  }


  public resetForm(form){
    form.reset();
  }


  public  deletePharmacy(event: MouseEvent, pharmacy) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('pharmacie/del', pharmacy.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/pharmacie/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  // public showConfirmMessage(event: MouseEvent, pharmacy) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     closeOnConfirm: false
  //   }, function () {
  //     this.deletePharmacy(event,pharmacy);
  //     swal("Deleted!", "Your data has been successfully deleted.", "success");
  //   });
  // }


  // public showCancelMessage(event: MouseEvent, pharmacy) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deletePharmacy(event,pharmacy);
  //       swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "Your data is safe.", "error");
  //     }
  //   });
  // }


  // public showDeleteAllMessage(event: MouseEvent) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to delete all datas? This action is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       //insert the method to delete all datas
  //       //this.deletePharmacy(event,pharmacy);
  //       swal("Deleted!", "The datas has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "The datas are safe.", "error");
  //     }
  //   });
  // }

}
