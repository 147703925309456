import { Component, OnInit} from '@angular/core';
import '../../../../vendor/jitsi/external_api.js';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-video-doctor',
  templateUrl: './video-doctor.component.html',
  styleUrls: ['./video-doctor.component.css']
})

export class VideoDoctorComponent implements OnInit {


  value: string;
  title = 'app';
  domain:string = "meet.jit.si";
  options: any;
  api: any;
/// --------------------------------variable-----------------


constructor(){}
  
  ngOnInit() {
    this.value = sessionStorage.getItem('roomname');
  }

  ngAfterViewInit(): void {
    
    this.options = {
      roomName: this.value,
      width: 700,
      height: 700,
      parentNode: document.querySelector('#meet')
    }

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);
  }

 
}
