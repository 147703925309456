import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderPatientComponent } from './patient/header-patient/header-patient.component';
import { SidebarPatientComponent } from './patient/sidebar-patient/sidebar-patient.component';
import { ChatLauncherPatientComponent } from './patient/chat-launcher-patient/chat-launcher-patient.component';
import { LayoutPatientComponent } from './patient/layout-patient/layout-patient.component';

import { HeaderMedecinComponent } from './medecin/header-medecin/header-medecin.component';
import { SidebarMedecinComponent } from './medecin/sidebar-medecin/sidebar-medecin.component';
import { ChatLauncherMedecinComponent } from './medecin/chat-launcher-medecin/chat-launcher-medecin.component';
import { LayoutMedecinComponent } from './medecin/layout-medecin/layout-medecin.component';

import { HeaderAdminComponent } from './admin/header-admin/header-admin.component';
import { SidebarAdminComponent } from './admin/sidebar-admin/sidebar-admin.component';
import { ChatLauncherAdminComponent } from './admin/chat-launcher-admin/chat-launcher-admin.component';
import { LayoutAdminComponent } from './admin/layout-admin/layout-admin.component';

import { HeaderSiteComponent } from './site/header-site/header-site.component';
import { FooterSiteComponent } from './site/footer-site/footer-site.component';
import { LayoutSiteComponent } from './site/layout-site/layout-site.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
// import { HeaderComponent } from './global/header/header.component';
// import { FooterComponent } from './global/footer/footer.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  declarations: [
    // HeaderComponent,
    // FooterComponent,
    LayoutMedecinComponent,
    SidebarMedecinComponent,
    HeaderMedecinComponent,
    ChatLauncherMedecinComponent,
    LayoutPatientComponent,
    SidebarPatientComponent,
    HeaderPatientComponent,
    ChatLauncherPatientComponent,
    LayoutAdminComponent,
    HeaderAdminComponent,
    SidebarAdminComponent,
    ChatLauncherAdminComponent,
    LayoutSiteComponent,
    HeaderSiteComponent,
    FooterSiteComponent
],

  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],

})
export class LayoutModule {
}
