import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Message } from 'src/app/models/message';
import { environment } from 'src/environments/environment';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-doctor-message',
  templateUrl: './doctor-message.component.html',
  styleUrls: ['./doctor-message.component.css']
})
export class DoctorMessageComponent implements OnInit {

  doctor: string;
  speciality: any;
  userId: any;
  messages: any;
  baseurl: string;
  message:any;
  msg:any;
  angForm: FormGroup;
  checkArray:FormArray;
  p: number = 1;
  total: number;
  selectedAll: any;
  messages_context: string;

  constructor( private router: Router, private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService, private resourceService:ResourceService, private fb: FormBuilder) {

    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.message={} as Message;
    this.angForm = this.fb.group({
      checkArray: this.fb.array([])
    })

  }

  ngOnInit() {
   // this.checkSession();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.getMessages();
    this.getmessagesDoctor(1,"pat");
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getMessages() { 
    this.resourceService.getResource('message/list_doc',this.userId).subscribe(data=>{
      this.msg = data;
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    }); 
  }

  // appeler cette methode dans le ngOnInit
  getmessagesDoctor(page:number,type){
    this.messages_context = 'Messages Recus';
    this.resourceService.getResources('messagedoc/'+this.userId+'/'+page+'/'+type).subscribe(data=>{
      let datas:any = data;
      this.msg=datas.rows;
      this.total = datas.count;
      this.p=page;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');

    })
  }

  checked(key){
    this.resourceService.getResources('message/searchdoc/'+this.userId+'/'+key).subscribe(data=>{
      let datas:any = data;
      this.msg=datas;
    },err=>{
      console.log('erreur',err);
        this.toastr.error(Errors(err), 'Major Error');
    })
  }
  
  selectAll() {
    for (var i = 0; i < this.msg.length; i++) {
      this.msg[i].selected = this.selectedAll;
    }
  }
  
  checkIfAllSelected() {
    this.selectedAll = this.msg.every(function(item:any) {
        return item.selected == true;
      })
  }

  deleteMessage(){
    let msgs=[];
    for (var i = 0; i < this.msg.length; i++) {
      if(this.msg[i].selected){
        msgs = [this.msg[i].id,...msgs];
      }
    }
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer ces lignes... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.getsaveResource('messagedoc/delete',msgs).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }

}
