import { Component, OnInit } from '@angular/core';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { timer } from 'rxjs';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-consultation',
  templateUrl: './view-consultation.component.html',
  styleUrls: ['./view-consultation.component.css']
})
export class ViewConsultationComponent implements OnInit {

  userId: any;
  consultations:any;
  baseurl: string;
  
  constructor(private router: Router,private c: ConfirmationDialogService, private config: NgbModalConfig, private toastr: ToastrService,private resourceService:ResourceService) {

    this.config.backdrop = 'static';
    this.config.keyboard = false;
  }

  ngOnInit() {
   // this.checkSession();
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrl+'public/';
    this.getRdvs();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/login"])
    }
  }

  getRdvs() { 
    this.resourceService.getResource('rdv/alldoc',this.userId).subscribe(data=>{
      this.consultations = data;
    },err=>{
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur',err);
    }); 
  }

  deleteitem(item) {
    this.c.confirm('Confirmer la Suppression', 'Voulez vous supprimer cette ligne... ?').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('rdv/delete',item.id).subscribe(data=>{
          this.toastr.success('Supprime!','Succes');
        },err=>{
            this.toastr.error(Errors(err), 'Major Error');
            console.log('erreur',err);
        });
      }
    });
  }

}
