import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/services/resource.service';
import { Errors } from 'src/app/settings/function_errors';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  loading = false;
  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private resourceService: ResourceService) { }


  angForm: FormGroup;
  submitted = false;
  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.angForm = this.fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
      email:['']
    });
  }
  get f() {

    return this.angForm.controls;
  }

  onSubmit() {

    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    } else {
      this.loading=true;
      this.angForm.get('email').setValue(sessionStorage.getItem('email'));
      this.resourceService.saveResource('patient/sendsupportmail', this.angForm.value).subscribe(data => {
        let res: any = data;
        if (res == "Done"){
          this.toastr.success('Message envoyé nous allons vous repondre dans votre boite!', 'Succes');
          this.angForm.reset();
          this.submitted=false;
          this.loading = false;
        }
        else
          this.toastr.error('Echec envoi!', 'Major Error');
        this.loading = false;
      }, err => {
        this.toastr.error(Errors(err), 'Major Error');
        console.log('erreur', err);
          this.loading = false;
      });
    }
  }
}
