import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';

@Component({
  selector: 'app-support-messge-patient',
  templateUrl: './support-messge-patient.component.html',
  styleUrls: ['./support-messge-patient.component.css']
})
export class SupportMessgePatientComponent implements OnInit {
  userId: string;

  constructor(private serviceResoure:ResourceService) { }

  ngOnInit() {
   
  }

  

}
