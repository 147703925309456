import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Shedule } from 'src/app/models/schedule';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { DatetimepickerService } from 'src/app/services/datetimepicker.service';
import { Title } from '@angular/platform-browser';
// import { French} from 'flatpickr/dist/l10n/fr';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};




declare var $: any;
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit,AfterViewInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  @ViewChild('startPicker', { static: true }) public pickerStart;
  @ViewChild('endPicker', { static: true }) public pickerEnd;
  model;
  loading = false;
  types = [
    {
      name: "domicile",
      label: "domicile"
    },
    {
      name: "cabinet",
      label: "cabinet"
    },
    {
      name: "teleconsultation",
      label: "téléconsultation",
    }
  ];

  dayaHours: any;

  dayaHours1 = [];
  dayaHours2 = [];
  times: any;
  heureDebut = '';
  heureFin = '';
  // date picker options
  DAY = 86400000;
  locale: string = 'fr';
  startOptions: FlatpickrOptions = {

    dateFormat: 'd.m.Y',
    minDate: new Date(),

  };



  form: FormGroup;
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  disponibility: Shedule[] = [];
  schedule: any;
  userId: string;
  submitted = false;
  dateSelect: any;
  disponibilities: any = [];
  refresh: Subject<any> = new Subject();

  constructor(private toastr: ToastrService,
    private router: Router,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private datePicker: DatetimepickerService,
    private config: NgbDatepickerConfig,
       private title:Title) {
         this.title.setTitle('Planning')
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };
    //config.maxDate = { year: 2099, month: 12, day: 31 };
    config.outsideDays = 'hidden';

    this.schedule = {} as Shedule;

    this.form = this.formBuilder.group({
      start: [null, Validators.required],
      type: [null, Validators.required],
      hd: [null, Validators.required],
      hf: [null, Validators.required],
    });
    // si l'heure de debut change
    this.form.controls.hd.valueChanges.subscribe((changes: any) => {
      // on renitialise l'heure de fin
      this.form.controls['hf'].setValue(null);
    
      this.dayaHours1 = [];
      console.log(changes);
      // si l'heure choisie est minuit  on affecte a heure de fin interval step
      if (changes == this.datePicker.minuit) {
        this.dayaHours1.push({
          time: this.datePicker.convertMinutesToTimeFormat(this.datePicker.step),
          minutes: this.datePicker.step
        });
        this.dateSelect + this.DAY;
        // on passe la date initiale a la date suivant (plus 1 jour)
        console.log('date a jour', new Date(this.dateSelect + this.DAY));
        // this.form.controls['start'].setValue(new Date(this.dateSelect + this.DAY));

      } else {
        // sinon  on recupere l'heure choisie on ajoute le step
        this.dayaHours1.push({
          time: this.datePicker.convertMinutesToTimeFormat(changes + this.datePicker.step),
          minutes: changes + this.datePicker.step
        });
      }


    });

  }


  onChange(e){
      if(e.name=="domicile"){
        this.datePicker.step=60;
      }else {
        this.datePicker.step=15;
      }
  }
  onDateSelected() {
    // date selectionner par utilisateur
    this.dateSelect = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.verifyIfDateExistTab(this.dateSelect);
   
    this.form.controls['hd'].setValue(null);
    this.form.controls['hf'].setValue(null);
    // on calcule la liste des heures en fonction d'une date
    this.dayaHours = this.datePicker.calculHour(this.dateSelect);
    // on enleve les heures deja programmee en fonction d'une date dans la liste des heures non programmee
    if (this.dayaHours) {
      this.dayaHours = this.dayaHours.filter(({ minutes: m1 }) => !this.dayaHours2.some((
        { minutes: m2 }) => m2 === m1));
    }
    // console.log('liste des heure de la date selectionner', this.dayaHours);
    // console.log('liste des heure de la date selectionner moins les heure de la bd', this.dayaHours);
  
 
  
  }


  get f() {
    return this.form.controls;
  }

  add() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let date_select_start: Date = this.datePicker.convertiDate(this.dateSelect);
    let date_select_end: Date = this.datePicker.convertiDate(this.dateSelect);
    let end = new Date(date_select_end.setMinutes(parseInt(this.form.value['hf'])));
    let start = new Date(date_select_start.setMinutes(this.form.value['hd']));

    this.disponibility = [
      ...this.disponibility,
      {
        date_select: this.datePicker.convertiDate(this.dateSelect),
        end_time: this.datePicker.convertMinutesToTimeFormat(this.form.value['hf']),
        start_time: this.datePicker.convertMinutesToTimeFormat(this.form.value['hd']),
        type: this.form.value['type'],
        date_created: new Date(),
        date_updated: new Date(),
        doctor_id: this.userId,
        time_end: this.form.value['hf'],
        time_start: this.form.value['hd'],
        final_start_time: start,
        final_end_time: end,
        nbr_minutes:this.form.value['hd']
      }
    ];
 

    // save bd
    this.resourceService.saveResource('dispo/create', this.disponibility).subscribe(data => {
      this.toastr.success('Enregistré!', 'Success ');
    
      this.disponibility = [];
  
      this.loading = false;
      this.getDisponibilities();
    }, err => {
        this.loading = false;
      this.toastr.error(Errors(err), 'Major Error');
      console.log('err', err);
    });

    console.log('dipo', this.disponibility);
    this.form.controls['hd'].setValue(null);
    this.form.controls['hf'].setValue(null);
    this.form.controls['type'].setValue(null);
    this.form.controls['start'].setValue(null);
    date_select_start = null;
    date_select_end = null;
    this.submitted = false;
    this.loading=true;
    this.refresh.next();
  }




  getDisponibilities() {
    this.resourceService.getResources('dispo/doclistall/' + this.userId).subscribe(data => {
      this.disponibilities = data;
      console.log('icicici', this.disponibilities);
      let db = "db";
     this.events = [];
      for (let index = 0; index < this.disponibilities.length; index++) {
        this.events = [
          this.mapOject(this.disponibilities[index], db),
          ...this.events
        ]
      }
      this.refresh.next();
    }, err => {
    });
  }

  // onSubmit() {
  //   this.loading=true;
  //   console.log(this.disponibility);
  //   this.resourceService.saveResource('dispo/create', this.disponibility).subscribe(data => {
  //     this.toastr.success('Enregistré!', 'Success ');
  //     this.getDisponibilities();
  //     this.disponibility = [];
  
  //     this.loading = false;
  //   }, err => {
  //       this.loading = false;
  //     this.toastr.error(Errors(err), 'Major Error');
  //     console.log('err', err);
  //   })
  // }

  deleteDisponibility(start, end) {
    this.resourceService.getResources('dispo/delete/' + start + '/' + end).subscribe(data => {
      this.toastr.success('Supprimé!', 'Success!');
    }, err => {
      this.toastr.error(Errors(err), 'Major Error');
      console.log('err', err);
    });

  }

  mapOject(event, from) {
    let start; let end;
    if (from == "list") {
      start = new Date(event.start_time);
      end = new Date(event.end_time);
    } else {
      start = new Date(event.start_time);
      end = new Date(event.end_time);
    }
    return {
      title: event.type,
      start: new Date(start),
      end: new Date(end),
      color: colors.red,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      actions: this.actions
    }
  }

  removefromtab(event: any) {
    let e = event;
    this.disponibility = this.disponibility.filter(event => event !== e);
  }

  deleteEvent(eventToDelete) {
    this.deleteDisponibility(eventToDelete.start, eventToDelete.end);
    this.events = this.events.filter(iEvent => iEvent !== eventToDelete);
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }


  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnInit() {
    // this.checkSession();
    this.userId = sessionStorage.getItem('userId');
    // this.userId = "1";
    this.getDisponibilities();

  }
  ngAfterViewInit(){
   
  }
  

  verifyIfDateExistTab(date) {
     // convertiDate
    // on parcour les disponibilite venant de la bd on compare chaque dispo avec la date selectionnee si 
    // c est egale a 0 on recupere cette dispo et on enleve ses heure san la liste des heure de debut
    this.dayaHours2 = [];
    let min:number=0;
    this.disponibilities.forEach(element => {
        if(this.datePicker.compareTwoDates(date, this.datePicker.convertiDate(new Date(element.start_time)))==0 ){
          min=0;
          // si le type est domicile on recupere l'heure de debut on ajouter 15min fois pour trouver les heure indisponible ex (15h heure de debut les heure indisponible son 15h00, 15h15,15h30,15h,15h45)
          if(element.type=="domicile"){
       
            for (let index = 0; index <4; index++) {
              this.dayaHours2.push({
                minutes:element.nbr_minutes+min
              });
              min=min+15;
              console.log('tet',index,'min',min,this.dayaHours2);
            }
          } else if(element.type=="teleconsultation" || element.type=="cabinet" ){
            for (let index = 0; index <1; index++) {
              this.dayaHours2.push({
                minutes:element.nbr_minutes+min
              });
              min=min+15;
            
            }
          }
            
        }
    });

  }


}
