import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Role } from 'src/app/models/role';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
declare var swal: any;

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.css']
})

export class ListRoleComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newRole:Role;
  roleFormEdit: FormGroup;
  roles: any = [];
  public error;
  public success;

  public detailsData =
    {
      id : null,
      name : '',
      description : '',
      value : ''
    };
  baseUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private router:Router,
    private toastr: ToastrService,
    private c: ConfirmationDialogService
  ) { this.newRole={} as Role; }

  ngOnInit() {
    this.checkSession();
    this.baseUrl = environment.baseUrl;
    this.initFormEdit();
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  initFormEdit() {
    this.roleFormEdit = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      description: [''],
      value: ['', Validators.required]
    });
  }

  get e() { return this.roleFormEdit.controls; }

  public liste() {

    this.resourceService.getResources('role/all').subscribe(data => {

        this.roles = data;

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }

      }, error => {
        if(error.status==500 || error.status==503){

          this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      });
  }

  public onView(role){

    this.detailsData =
      {
        id : role.id,
        name : role.name,
        description : role.description,
        value : role.value
      };

    console.log(this.detailsData.id);
  }

  public onEdit(role) {
    this.roleFormEdit.patchValue({
      id: role.id,
      name: role.name,
      description: role.description,
      value : role.value
    });
  }

  public updateRole(){
    if (this.roleFormEdit.invalid) {
      return;
    }

    const formValue = this.roleFormEdit.value;
    this.newRole.id = formValue['id'];
    this.newRole.name = formValue['name'];
    this.newRole.description = formValue['description'];
    this.newRole.value = formValue['value'];
    this.newRole.updated = new Date();
    this.resourceService.updateResource('role/update/' + formValue['id'], this.newRole).subscribe(data => {
        // this.roleFormEdit.reset();
        this.resetForm(this.roleFormEdit);
        this.closemodal.nativeElement.click();
        this.liste();
        this.toastr.success('Le role a été mis à jour!', 'Sucess');

      }, error => {
        if(error.status==500 || error.status==503){

          this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
        if(error.status==400){

          this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      });
  }


  public resetForm(form){
    form.reset();
  }


  public deleteRole(event: MouseEvent, role) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('role/del', role.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/role/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  // public showCancelMessage(event: MouseEvent, role) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deleteRole(event, role);
  //       swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "Your data is safe.", "error");
  //     }
  //   });
  // }


  // public showConfirmMessage(event: MouseEvent, role) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     closeOnConfirm: false
  //   }, function(){
  //     swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     this.deleteRole(event, role);
  //   });
  // }


  // public showDeleteAllMessage(event: MouseEvent) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to delete all datas? This action is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //
  //       //insert the method to delete all datas
  //       //this.deleteRole(event,role);
  //
  //       swal("Deleted!", "The datas has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "The datas are safe.", "error");
  //     }
  //   });
  // }

}
