import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare  var $:any;

@Component({
  selector: 'app-header-site',
  templateUrl: './header-site.component.html',
  styleUrls: ['./header-site.component.css']
})
export class HeaderSiteComponent implements OnInit {

  public currentlang:any = '';
	
  constructor(private translateService: TranslateService) { 
    this.hMenu();
   }
 

  ngOnInit() {
	  
	let lang:any = navigator.language;
    if(lang){
      if (lang != "fr" && lang != "en") {
        this.translateService.setDefaultLang('en');
		this.currentlang = 'en';
      }else {
        this.translateService.setDefaultLang(lang);
		this.currentlang = lang;
      }   
    }else{
      this.translateService.setDefaultLang('en');
	  this.currentlang = 'en';
    }
	
  }
  
  hMenu(){
    /*global $ */
    $(document).ready(function() {

      "use strict";

      //Checks if li has sub (ul) and adds class for toggle icon - just an UI
      $('.menu > ul > li:has(ul)').addClass('menu-dropdown-icon');
      $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');

      //If width is more than 943px dropdowns are displayed on hover
      $(".menu > ul > li").hover(function(e) {
        if ($(window).width() > 943) {

          $(this).children("ul").stop(true, false).fadeToggle(150);
          e.preventDefault();
        }
      });

      //If width is less or equal to 943px dropdowns are displayed on click (thanks Aman Jain from stackoverflow)
      $(".menu > ul > li").click(function() {

        if ($(window).width() <= 943) {

          $(this).children("ul").fadeToggle(150);
        }
      });

      //when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story (thanks mwl from stackoverflow)
      //$(".h-bars").click(function(e) {
      /*
      $(".navbar-toggler").click(function(e) {
        $("html").toggleClass("nav-open");
        $(".menu > ul").toggleClass('show-on-mobile');
        e.preventDefault();
      });
      */
    });

  }

  navbarToggler(){
    $("html").toggleClass("nav-open");
    $(".menu > ul").toggleClass('show-on-mobile');
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
	this.currentlang = language;
  }
}

