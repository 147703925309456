import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DoctorGuardService {

  constructor(private router:Router ) {
  }
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {

   

    if(!localStorage.getItem('token') || sessionStorage.getItem("_epocrate_type_") != "_epocrate_type_"){
      this.router.navigate(["/login"]);
      return false;
    }

    return true;
}
}
