import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { NotificationsComponent } from './notifications/notifications.component';




@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

    declarations: [
       
        // NotificationsComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,

    ],

})
export class UiModule {
}
