import {Component, AfterViewInit, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Department } from 'src/app/models/department';
import { ResourceService } from 'src/app/services/resource.service';
import { Router } from '@angular/router';
import { Errors } from 'src/app/settings/function_errors';
import {ToastrService} from "ngx-toastr";
import { environment } from 'src/environments/environment';
import {ConfirmationDialogService} from 'src/app/ui/notifications/notification.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
declare var swal: any;


@Component({
  selector: 'app-list-department',
  templateUrl: './list-department.component.html',
  styleUrls: ['./list-department.component.css']
})
export class ListDepartmentComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closemodal', {static: true}) closemodal ;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false;
  dtTrigger: Subject <any> = new Subject();

  newDepartment: Department;
  departmentFormEdit: FormGroup;
  departments: any = [];
  deps: any = [];
  baseUrl: string;
  url: string = 'speciality/listall';
  public error;
  public success;

  public detailsData =
    {
      id : null,
      name : '',
      description : ''
    };

  constructor(private formBuilder: FormBuilder, private resourceService: ResourceService, private router:Router, private toastr: ToastrService, private c: ConfirmationDialogService) {
    this.newDepartment = {} as Department;
  }

  ngOnInit() {
    this.checkSession();
    this.initFormEdit();
    this.baseUrl = environment.baseUrl;
  }

  ngAfterViewInit(){
    this.liste();
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  checkSession(){
    if(!localStorage.getItem('token')){
      this.router.navigate(["/admpro/user"])
    }
  }

  initFormEdit() {
    this.departmentFormEdit = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      description: ['']
    });
  }

  get e() { return this.departmentFormEdit.controls; }

  public liste() {

    this.resourceService.getResources(this.url).subscribe(data => {

        this.departments = data;

        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }

      }, error => {
        if(error.status==500 || error.status==503){

          this.toastr.error(Errors(error), 'erreur de chargement \n une erreur est survenue au niveau du serveur, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      });
  }

  public onView(department){

    this.detailsData =
      {
        id : department.id,
        name : department.name,
        description : department.description
      };

    console.log(this.detailsData.id);
  }

  public onEdit(department) {
    this.departmentFormEdit.patchValue({
      id: department.id,
      name: department.name,
      description: department.description,
    });
  }

  public updateDepartment(){
    if (this.departmentFormEdit.invalid) {
      return;
    }

    const formValue = this.departmentFormEdit.value;
    this.newDepartment.id = formValue['id'];
    this.newDepartment.name = formValue['name'];
    this.newDepartment.description = formValue['description'];

    this.resourceService.updateResource('speciality/update/' + formValue['id'], this.newDepartment).subscribe(data => {
        this.resetForm(this.departmentFormEdit);
        this.closemodal.nativeElement.click();
        this.liste();
        this.toastr.success('Le departement a été mis à jour!', 'Sucess');
      }, error => {
        if(error.status==500 || error.status==503){

          this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
        if(error.status==400){

          this.toastr.error(Errors(error), 'impossible d\'effectuer la mise à jour, \n');
          console.log('erreur',error);
          console.log(Errors(error));
        }
      });
  }


  public  deleteDepartment(event: MouseEvent, department) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'The deletion of your data is irreversible!', 'Yes, delete it!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteResource('speciality/del', department.id).subscribe(data => {
          this.liste();
          this.toastr.success('Your data has been successfully deleted!','Deleted!');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the data, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }


  public  deleteAll(event: MouseEvent) {
    event.preventDefault();
    this.c.confirm('Are you sure?', 'Do you want to delete all datas? This action is irreversible!', 'Yes, delete!').then((confirmed) => {
      if (confirmed) {
        this.resourceService.deleteallResource('/speciality/deleteall').subscribe(data => {
          this.liste();
          this.toastr.success('The datas has been successfully deleted!','Success');
        }, err => {
          this.toastr.error(Errors(err), 'Fail to delete the datas, \n');
          console.log('erreur', err);
          console.log(Errors(err));
        });
      }
    });
  }

  public resetForm(form){
    form.reset();
  }


  // public showConfirmMessage(event: MouseEvent, department) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     closeOnConfirm: false
  //   }, function () {
  //     this.deleteDepartment(event,department);
  //     swal("Deleted!", "Your data has been successfully deleted.", "success");
  //   });
  // }


  // public showCancelMessage(event: MouseEvent, department) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "The deletion of your data is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //       this.deleteDepartment(event,department);
  //       swal("Deleted!", "Your data has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "Your data is safe.", "error");
  //     }
  //   });
  // }


  // public showDeleteAllMessage(event: MouseEvent) {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to delete all datas? This action is irreversible!",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel please!",
  //     closeOnConfirm: false,
  //     closeOnCancel: false
  //   }, function (isConfirm) {
  //     if (isConfirm) {
  //
  //       //insert the method to delete all datas
  //       //this.deleteDepartment(event,department);
  //
  //       swal("Deleted!", "The datas has been successfully deleted.", "success");
  //     } else {
  //       swal("Cancelled", "The datas are safe.", "error");
  //     }
  //   });
  // }


}
