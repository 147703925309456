import { Injectable } from '@angular/core';


import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationsComponent } from './notifications.component';

@Injectable()
export class ConfirmationDialogService {
    title: string;
    buttonTitle = "OK";
    type: "error";

    protected modalRef: NgbModalRef;
    
    constructor(private modalService: NgbModal) { }

    public confirm(
        title: string,
        message: string,
        btnOkText: string = 'OK',
        btnCancelText: string = 'Cancel',
        btnHide:boolean=true,
       
        dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
        const modalRef = this.modalService.open(NotificationsComponent, { size: dialogSize });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText?btnOkText:'OK';
        modalRef.componentInstance.btnCancelText = btnCancelText?btnCancelText:'Cancel';
        modalRef.componentInstance.btnHide = btnHide;
        return modalRef.result;
    }



    public show(title: string, message: string) {
        this.title = title;
        this.modalRef = this.modalService.open(
            message
        );
    }

    hide() {
        if (this.modalRef) {
            this.modalRef.close();
        }
    }
}