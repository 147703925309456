import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { Errors } from 'src/app/settings/function_errors';
import {  Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/ui/notifications/notification.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-appointments',
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.css']
})
export class MyAppointmentsComponent implements OnInit, OnDestroy ,AfterViewInit{

  speciality: any;
  doctor: string;
  userId: any;
  baseurl: string;
  myTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  msg: any;

  
  constructor(private router: Router, 
              private resourceService: ResourceService, 
              private toastr: ToastrService, 
              private c: ConfirmationDialogService,
              private translate:TranslateService) { }

   appointments:any;
  public appointmentstocome:any = [];

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  
    this.userId = sessionStorage.getItem('userId');
    this.baseurl = environment.baseUrlImage;
    

  }
  ngAfterViewInit(){
    this.listeappointments();
  }

  ngOnDestroy() {

    this.myTrigger.unsubscribe();
  }

 


  

  listeappointments(){
    this.resourceService.getResource('rdv/all',this.userId).subscribe(data => {
      this.appointments = data; 
      console.log('app',this.appointments);
      this.myTrigger.next();
    },err=>{
      this.toastr.error(Errors(err), 'Major Error');
      console.log('err', err);
    });
  }

  validate(item){
    // this.c.confirm(this.translate.instant("header_consul"), this.translate.instant("valid_consultation"),true).then((confirmed) => {
    //   if (confirmed) {
    //     this.resourceService.getResource('rdv/validate',item.id).subscribe(data=>{
    //       this.toastr.success('Valide!','Succes');
    //     },err=>{
    //         this.toastr.error(Errors(err), 'Major Error');
    //         console.log('erreur',err);
    //     });
    //   }
    // });

    this.router.navigate(['/patient/view/appointment/',item.id]);
  }

  // on annuler une consulation avant une heure du rendez-vous
  annulerItem(item){
    let date_disponibility = new Date(item.disponibility.start_time);
    let  curent_date= new Date();
    
    let date_annule=date_disponibility.setMinutes(-60);

   let  d1=new Date(date_annule)
    if(d1.getTime()<curent_date.getTime()){

      this.c.confirm(this.translate.instant("header_annule_consul"),this.translate.instant("annule_consul"),'','',false).then((confirmed) => {});
    }else{
      
      this.c.confirm(this.translate.instant("header_annule_consultation"),this.translate.instant("annule_consultation")).then((confirmed) => {
        if (confirmed) {
          this.resourceService.getResource('rdv/cancelled',item.id).subscribe(data=>{
            this.toastr.success(this.translate.instant("cancel"),'Succes');
          },err=>{
              this.toastr.error(Errors(err), 'Major Error');
              console.log('erreur',err);
          });
        }
      });
    }
   

  }

  getStatut(date){
    const current= new Date().getTime();
          const item=new Date(date).getTime();
  
    if(current<item){
      return "notstart";
    }else if (current>item){
      return "end";
    }


  }

  passedAppointments(item) {
    let date_end = item.disponibility.start_time
  }
}